import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { patternValidator } from '@app/validation/validators/pattern.validator';
import { requiredValidator } from '@app/validation/validators/required.validator';
import { DisclosureFormService } from '../../services/disclosure-form.service';

export const ssnAgreementTagName = 'rise-ssn-agreement';

@Component({
  selector: ssnAgreementTagName,
  templateUrl: './ssn-agreement.component.html',
  styleUrls: ['./ssn-agreement.component.scss'],
})
export class SsnAgreementComponent implements AfterViewInit {
  public fieldName: string;
  public showForm = false;
  private last4Ssn: string;
  private formControl: FormControl;

  constructor(
    private el: ElementRef,
    private disclosureFormService: DisclosureFormService,
    private applicationDataService: ApplicationDataService
  ) {
    this.last4Ssn = this.applicationDataService
      .get()
      .applicant.identity.socialSecurityNumber.slice(-4);
    this.fieldName = this.el.nativeElement.getAttribute('fieldName');
    this.formControl = new FormControl(null, [
      requiredValidator('Required'),
      patternValidator(
        new RegExp(this.last4Ssn, 'g'),
        'Must match last four of SSN'
      ),
    ]);
  }

  public ngAfterViewInit(): void {
    this.disclosureFormService.addFormControl(this.fieldName, this.formControl);
    this.showForm = true;
  }

  public get form(): FormGroup {
    return this.disclosureFormService.form;
  }
}
