<div class="dialog-header">
  </div>
  <div mat-dialog-title id="bankConnectionErrorTitle" class="dialog-header">
    <p>Error Occurred!</p>
  </div>
  <mat-dialog-content id="bankConnectionErrorContent" class="mat-typography">
    <p>
        An error occurred while attempting to complete the connection to Plaid. 
        Your credentials have been removed for your security. 
        Please click return to re-attempt your bank login.
    </p>
    <button id="bankConnectionErrorReturnBtn" (click)="onReturnClick()">Return</button>
  </mat-dialog-content>