/* istanbul ignore file */
import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationApi } from '@app/acquisition/application/application.api';
import { ApplicationData } from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import {
  AppConfigService,
  AzureAppConfingFlags,
} from '@app/appconfig/appconfig.service';
import { Environment } from '@app/environment/environment.module';
import * as LogRocket from 'logrocket';
import { Subscription } from 'rxjs';
import { NeuroIdService } from '../neuro-id/neuro-id.service';
import {
  SessionStorageObjects,
  SessionStorageService,
} from '../session-storage-service/session-storage.service';

@Injectable()
export class LogRocketService implements OnDestroy {
  private fieldSubscription: Subscription;
  private initialSubscription: Subscription;

  constructor(
    private environment: Environment,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private neuroIdService: NeuroIdService,
    private appConfigService: AppConfigService,
    private sessionStorage: SessionStorageService
  ) {}

  public initialize(): void {
    LogRocket.init(this.environment.logRocket.applicationId, {
      rootHostname: this.environment.logRocket.rootDomain,
      network: {
        requestSanitizer: (request: any) => {
          request.body = null;
          return request;
        },
        responseSanitizer: (response: any) => {
          response.body = null;
          return response;
        },
      },
    });
  }

  public addSearchableFields(): void {
    this.fieldSubscription =
      this.appConfigService.azureAppFlagSubject.subscribe(
        (flagset: Map<string, boolean>) => {
          if (flagset.get(AzureAppConfingFlags.logrocketEnabled)) {
            this.applicationApi.get().subscribe((appData: ApplicationData) => {
              const tealiumVars = this.sessionStorage.getSessionStorageObject(
                SessionStorageObjects.tealiumVariables
              );
              this.neuroIdService.sendUserId(appData.id);
              this.neuroIdService.setVariable(
                'funnel',
                appData.applicationFlow
              );
              LogRocket.identify(appData.id, {
                name: `${appData.form.applicant.identity.firstName} ${appData.form.applicant.identity.lastName}`,
                firstName: appData.form.applicant.identity.firstName,
                lastName: appData.form.applicant.identity.lastName,
                mobilePhone: appData.form.applicant.phones[0].number,
                email: appData.form.applicant.emails[0].address,
                sequenceApplicationId: appData.sequenceApplicationId,
                tealiumId: tealiumVars.visitorid,
              });
              this.applicationDataService.merge({
                id: appData.id,
                expiresAt: appData.expiresAt,
                sequenceApplicationId: appData.sequenceApplicationId,
              });
            });
          }
        }
      );
  }
  public async addAbTestingData(testName: string, segmentName: string)
  {
    LogRocket.track(testName, {
      segmentName: segmentName
    });
  }
  public ngOnDestroy(): void {
    if (this.fieldSubscription) {
      this.fieldSubscription.unsubscribe();
    }
    if (this.initialSubscription) {
      this.initialSubscription.unsubscribe();
    }
  }
}
