<div class="dialog-header">
  <img
    id="closeImage"
    alt="close"
    class="close-img"
    src="assets/images/button_close.svg"
    (click)="onCancelClick()"
  />
</div>
<div mat-dialog-title class="dialog-header">
  <p id="caBrokerTitle">State Specific Question</p>
</div>
<mat-dialog-content id="caBrokerContent" class="mat-typography">
  <div [formGroup]="form">
    <div class="checkbox-wrapper-div">
      <mat-checkbox
        id="brokerAgreementChkbx"
        formControlName="caBrokerChkbx"
      ></mat-checkbox>
      <div>
        By checking this box you acknowledge, represent and warrant that no
        person has performed any act as a broker in connection with the making
        of this Loan.
      </div>
    </div>
    <mat-error>
      <rise-validation-messages
        controlName="caBrokerChkbx"
      ></rise-validation-messages>
    </mat-error>
  </div>
  <div class="broker-buttons-div">
    <button
      class="broker-button broker-button-agree"
      id="agreeBtn"
      mat-flat-button
      type="submit"
      (click)="submit()"
    >
      Agree and continue
    </button>
    <button
      mat-flat-button
      class="broker-button broker-button-cancel"
      id="cancelBtn"
      type="submit"
      (click)="onCancelClick()"
    >
      Cancel
    </button>
  </div>
</mat-dialog-content>
