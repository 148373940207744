import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ThirdPartyDisclaimerModalComponent } from '@app/acquisition/apply/thirdparty-disclaimer/thirdparty-disclaimer-modal/thirdparty-disclaimer-modal.component';
import { Subscription } from 'rxjs';
import {
  DisclosureAgreementItem,
  DynamicDisclosureModalInputType,
  ParsedDisclosureAgreementData,
} from '../../../disclosure.models';
import { DisclosureFormService } from '../../services/disclosure-form.service';
import { DisclosureRenderService } from '../../services/disclosure-render.service';

export interface dynamicLinks {
  linkId: string;
  linkTarget: string;
}
@Component({
  selector: 'rise-disclosure-modal',
  templateUrl: './disclosure-modal.component.html',
  styleUrls: ['./disclosure-modal.component.scss'],
})
export class DisclosureModalComponent implements OnDestroy, AfterViewInit {
  public body = '<h1>Loading content</h1>';
  public htmlBody = '<h1>Loading content</h1>';
  public title = '';
  public data = [];
  public continueCancelBtns = true;
  public scrollToSign = false;
  public showSavePrintBtn = false;
  public modalDataCopy: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private modalData: DynamicDisclosureModalInputType,
    private disclosureFormService: DisclosureFormService,
    private dialogRef: MatDialogRef<DisclosureModalComponent>,
    private disclosureRenderService: DisclosureRenderService,
    private thirdpartyDisclaimerDialog: MatDialog
  ) {
    this.disclosureFormService.resetForm();
    this.title = this.modalData.title;
    this.body = this.disclosureRenderService.convertBodyHtml(
      this.modalData.body
    );
    this.modalDataCopy = this.modalData.body;

    this.modalDataCopy = this.modalDataCopy
      .replace(
        '<a href="http://www.jamsadr.com/">',
        '<a id="jamsAdrLinkId" href="javascript:void(0);">'
      )
      .replace(
        '<a href="https://www.annualcreditreport.com" target="_blank" rel="noopener">',
        '<a id="annualCreditReportId" href="javascript:void(0);">'
      );

    this.htmlBody = this.disclosureRenderService.convertBodyHtml(
      this.modalDataCopy
    );

    this.data = modalData.data;
    if (this.data !== undefined) {
      this.patchFormValue();
    }
    this.continueCancelBtns =
      this.modalData.continueCancelBtns !== undefined
        ? this.modalData.continueCancelBtns
        : true;
    this.scrollToSign =
      this.modalData.scrollToSign !== undefined
        ? this.modalData.scrollToSign
        : false;

    this.showSavePrintBtn = this.modalData.savePrintBtn;
  }
  public ngAfterViewInit(): void {
    const dynamicLinks: Array<dynamicLinks> = [
      {
        linkId: 'annualCreditReportId',
        linkTarget: 'https://www.annualcreditreport.com',
      },
      {
        linkId: 'jamsAdrLinkId',
        linkTarget: 'http://www.jamsadr.com/',
      },
    ];

    dynamicLinks.forEach((link: dynamicLinks) => {
      this.attachAnchorListeners(link.linkId, link.linkTarget);
    });
  }

  public attachAnchorListeners(linkId: string, linkTarget: string): void {
    if (document.getElementById(linkId) != null) {
      document.getElementById(linkId).addEventListener('click', () => {
        this.onClickLoadThirdPartyDisclaimer(linkTarget);
      });
    }
  }
  public onClickLoadThirdPartyDisclaimer(hyperlink: string): void {
    this.thirdpartyDisclaimerDialog.open(ThirdPartyDisclaimerModalComponent, {
      panelClass: 'rise-modal-medium',
      restoreFocus: false,
      data: hyperlink,
    });
  }
  public ngOnDestroy(): void {
    this.disclosureFormService.resetForm();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public handleAgreeClick(): void {
    if (this.checkForm()) {
      const disclosures = this.getDisclosureAgreementList();
      const formData = {
        disclosures: disclosures,
        valid: disclosures.length > 0 ? true : false,
      };
      this.dialogRef.close(formData);
    }
  }

  public patchFormValue(): void {
    (async () => {
      while (Object.keys(this.disclosureFormService.form.controls).length < 1) {
        await new Promise((resolve: any) => setTimeout(resolve, 500));
      }
      Object.keys(this.disclosureFormService.form.controls).forEach(
        (key: string) => {
          const pre = this.data.filter((x: any) => {
            return x.key === key;
          });
          this.disclosureFormService.form
            .get(key)
            .patchValue(
              pre[0].signature ? pre[0].signature : pre[0].consentGiven
            );
        }
      );
    })();
  }

  public printDisclosure(): void {
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(
      `<html><head></head><body onload="window.print()">${this.body}</body></html>`
    );
    printWindow.document.close();
  }

  private checkForm(): boolean {
    const invalidElements = document.querySelectorAll(
      '#diclosureModalContent input.ng-invalid, #diclosureModalContent mat-checkbox.ng-invalid'
    );

    Object.keys(this.disclosureFormService.form.controls).forEach(
      (key: string) => {
        this.disclosureFormService.form.get(key).markAsDirty();
        this.disclosureFormService.form.get(key).markAsTouched();
      }
    );

    if (invalidElements.length > 0) {
      const elm = invalidElements[0] as HTMLElement;
      elm.scrollIntoView({
        block: 'center',
      });
      elm.focus();
      return false;
    }
    return true;
  }

  private getDisclosureAgreementList(): DisclosureAgreementItem[] {
    const disclosureAgreementList: DisclosureAgreementItem[] = [];
    Object.keys(this.disclosureFormService.form.controls).forEach(
      (key: string) => {
        const control = this.disclosureFormService.form.get(key);
        const agreed = !(control.value === null);
        let signature = '';
        if (agreed && control.value !== true) {
          signature = control.value;
        }
        if (signature === '') {
          disclosureAgreementList.push({
            key: key,
            consentGiven: agreed,
          });
        } else {
          disclosureAgreementList.push({
            key: key,
            consentGiven: agreed,
            signature: signature,
          });
        }
      }
    );

    return disclosureAgreementList;
  }
}
