import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ValidationModule } from '@app/validation/validation.module';
import { BrandComponent } from '@brand/brand.component';
import { DynamicHTMLModule } from '../../dynamic-html/dynamic-html/dynamic-html.module';
import {
  CheckboxAgreementComponent,
  checkboxAgreementTagName,
} from './components/checkbox-agreement/checkbox-agreement.component';
import { DisclosureModalNewDesignComponent } from './components/disclosure-modal-new-design/disclosure-modal-new-design.component';
import { DisclosureModalComponent } from './components/disclosure-modal/disclosure-modal.component';
import {
  IAgreeAgreementComponent,
  iAgreeAgreementTagName,
} from './components/i-agree-agreement/i-agree-agreement.component';
import {
  LinkTagComponent,
  linkTagComponentSelector,
} from './components/link-tag/link-tag.component';
import {
  SsnAgreementComponent,
  ssnAgreementTagName,
} from './components/ssn-agreement/ssn-agreement.component';
import {
  TextAgreementComponent,
  textAgreementTagName,
} from './components/text-agreement/text-agreement.component';
import { DisclosureFormService } from './services/disclosure-form.service';
import { DisclosureRenderService } from './services/disclosure-render.service';

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    ValidationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    DynamicHTMLModule.forRoot({
      components: [
        {
          component: CheckboxAgreementComponent,
          selector: checkboxAgreementTagName,
        },
        {
          component: SsnAgreementComponent,
          selector: ssnAgreementTagName,
        },
        {
          component: TextAgreementComponent,
          selector: textAgreementTagName,
        },
        {
          component: IAgreeAgreementComponent,
          selector: iAgreeAgreementTagName,
        },
        {
          component: BrandComponent,
          selector: 'rise-brand',
        },
        {
          component: LinkTagComponent,
          selector: linkTagComponentSelector,
        },
      ],
    }),
  ],
  declarations: [
    CheckboxAgreementComponent,
    SsnAgreementComponent,
    TextAgreementComponent,
    DisclosureModalComponent,
    DisclosureModalNewDesignComponent,
    IAgreeAgreementComponent,
    LinkTagComponent,
  ],
  providers: [DisclosureFormService, DisclosureRenderService],
  entryComponents: [
    DisclosureModalComponent,
    DisclosureModalNewDesignComponent,
  ],
})
export class DisclosureModule {
  constructor() {}
}
