import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Environment } from '@environment/environment.module';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

export class FeatureFlag {
  constructor(public Name: string, public sEnabled: boolean) {}
}
export enum AzureAppConfingFlags {
  logrocketEnabled = 'logrocket-enabled',
  routePpqStatesToOrganicApp = 'route-ppq-states-to-organic-app',
  lowInterestState = 'low-interest-state',
  plaidForFormer = 'plaid-for-former-rise-customer',
  disableCoBrowse = 'disable-coBrowse',
  identityWithB2C = 'AzureB2C-IDM',
}

@Injectable()
export class AppConfigService {
  public azureAppFlagSubject: ReplaySubject<any>;

  private serverFlags = new Map<string, boolean>([
    [AzureAppConfingFlags.logrocketEnabled, true],
    [AzureAppConfingFlags.lowInterestState, true],
    [AzureAppConfingFlags.routePpqStatesToOrganicApp, false],
    [AzureAppConfingFlags.plaidForFormer, false],
    [AzureAppConfingFlags.identityWithB2C, false],
  ]);

  private lowInterestState: BehaviorSubject<boolean>;
  private plaidForFormer: BehaviorSubject<boolean>;
  private lowInterestStateFlag = false;
  private isCoBrowseDisabled: BehaviorSubject<boolean>;
  private isIdentityWithB2CEnabled: BehaviorSubject<boolean>;

  constructor(
    protected environment: Environment,
    protected http: HttpClient,
    protected router: Router,
    protected dialog: MatDialog
  ) {
    this.azureAppFlagSubject = new ReplaySubject<Map<string, boolean>>(1);
    this.lowInterestState = new BehaviorSubject<boolean>(false);
    this.plaidForFormer = new BehaviorSubject<boolean>(false);
    this.isCoBrowseDisabled = new BehaviorSubject<boolean>(true);
    this.isIdentityWithB2CEnabled = new BehaviorSubject<boolean>(false);
    const listOfFlags = Array.from(this.serverFlags.keys());
    this.getFeatureFlags(listOfFlags);
    this.isFormerPlaidEnabled();
  }

  public isFormerPlaidEnabled() {
    this.isFlagEnabled(AzureAppConfingFlags.plaidForFormer).subscribe(
      (response: boolean) => {
        this.plaidForFormer.next(response);
      }
    );
  }

  public async reconnectWithActiveState(state: string) {
    let routetoPPQFlag = false;
    this.isFlagEnabledforState(
      AzureAppConfingFlags.routePpqStatesToOrganicApp,
      state
    ).subscribe((response: boolean) => {
      routetoPPQFlag = response;
      this.serverFlags.set(
        AzureAppConfingFlags.routePpqStatesToOrganicApp,
        routetoPPQFlag
      );
      this.azureAppFlagSubject.next(this.serverFlags);
    });
  }

  public async getLowInterestState(state: string) {
    this.lowInterestStateFlag = false;
    this.isFlagEnabledforState(
      AzureAppConfingFlags.lowInterestState,
      state
    ).subscribe((response: boolean) => {
      this.lowInterestStateFlag = response;
      this.lowInterestState.next(this.lowInterestStateFlag);
    });
  }

  public getFeatureFlags(featureNames: string[]) {
    let availablefeatureNames: String[];
    this.getfeatureNames().subscribe((response: string[]) => {
      availablefeatureNames = response;
      const self = this;
      featureNames.forEach(function (value) {
        let isEnabled: boolean;
        const index = availablefeatureNames.findIndex(
          (item) => value.toLowerCase() === item.toLowerCase()
        );
        if (index > -1) {
          self.isFlagEnabled(value).subscribe((response: boolean) => {
            self.serverFlags.set(value, response);
          });
        } else {
          self.serverFlags.set(value, false);
        }
      });
      this.azureAppFlagSubject.next(this.serverFlags);
    });
  }
  public showLowInterestState(): Observable<boolean> {
    return this.lowInterestState.asObservable();
  }

  public IsPlaidforFormer(): Observable<boolean> {
    return this.plaidForFormer.asObservable();
  }

  public IsCoBrowseDisabled(): Observable<boolean> {
    return this.isFlagEnabled(AzureAppConfingFlags.disableCoBrowse);
  }

  public isAzureB2cEnabled(): Observable<boolean> {
    this.CheckAzureB2cEnabled();
    return this.isIdentityWithB2CEnabled.asObservable();
  }
  private isFlagEnabled(featureName: string): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${this.environment.brandApi.url}/api/AppConfiguration/IsFeatureEnabled?featureName=${featureName}`
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          return throwError(error);
        })
      );
  }
  private getfeatureNames(): Observable<string[]> {
    return this.http
      .get<string[]>(
        `${this.environment.brandApi.url}/api/AppConfiguration/FeatureNames`
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          return throwError(error);
        })
      );
  }

  private isFlagEnabledforState(
    featureName: string,
    state: string
  ): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${this.environment.brandApi.url}/api/AppConfiguration/IsFeatureEnabledForState?featureName=${featureName}&state=${state}`
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          return throwError(error);
        })
      );
  }
  private CheckAzureB2cEnabled() {
    let isAzureB2cEnabledFlag = false;
    this.isFlagEnabled(AzureAppConfingFlags.identityWithB2C).subscribe(
      (response: boolean) => {
        isAzureB2cEnabledFlag = response;
        this.isIdentityWithB2CEnabled.next(isAzureB2cEnabledFlag);
      }
    );
  }

  private checkIfCoBrowseDisabled() {
    this.isFlagEnabled(AzureAppConfingFlags.disableCoBrowse).subscribe(
      (response: boolean) => {
        this.isCoBrowseDisabled.next(response);
      }
    );
  }
}
