<div class="container refi-loan-summary">
	<div class="row">
		<div class="col-8 left-col margin-bottom-15">
			<h4 class="regular-font no-margin-bot"><strong>NEW LOAN AMOUNT</strong></h4>
			<h4 class="regular-font no-margin">CURRENT PAYOFF</h4>
		</div>
		<div class="col-4 right-col text-right">
			<h4 class="regular-font no-margin-bot"><strong>{{(loanAmount || 0) | currency:'USD':'symbol':'1.2-2'}}</strong></h4>
			<h4 class="regular-font no-margin"><span>-</span>{{(currentPayoff || 0) | currency:'USD':'symbol':'1.2-2'}}</h4>
		</div>
	</div>
	<div class="row cash-available-row">
		<div class="col-8 left-col margin-bottom-15">
			<h4 class="regular-font"><strong>CASH AVAILABLE FOR DEPOSIT</strong></h4>
		</div>
		<div class="col-4 right-col text-right">
			<h4 class="regular-font"><strong>{{(depositAmount || 0) | currency:'USD':'symbol':'1.2-2'}}</strong></h4>
		</div>
	</div>
</div>
