import { Component, Input } from '@angular/core';

@Component({
  selector: 'rise-doc-retrieval-error',
  templateUrl: './document-retrieval-error.component.html',
  styleUrls: ['./document-retrieval-error.component.scss'],
})
export class DocumentRetrievalErrorComponent {
  @Input() public showDocumentError = false;
  constructor() {}
}
