import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment.module';

@Injectable()
export class TealiumConfiguration {
  public readonly account: string;
  public readonly profile: string;
  public readonly environment: string;
  public readonly debug: boolean;
  public readonly queryStrings: string;

  constructor(environment: Environment) {
    this.account = environment.tealium.account;
    this.profile = environment.tealium.profile;
    this.environment = environment.tealium.environment;
    this.debug = environment.tealium.debug;
    this.queryStrings = environment.tealium.queryStrings;
  }
}
