<div class="rise-page-content">
  <div class="rise-page-container">
    <form [formGroup]="form">
      <div class="rise-padded-content">
        <div id="pageTitle" class="rise-page-title">
          Getting started with <rise-brand></rise-brand> is simple and secure
        </div>

        <mat-form-field color="accent" class="rise-form-field">
          <input
            matInput
            tealiumInputFocus="First-Name"
            data-nid-target="firstName"
            id="firstNameTextInput"
            formControlName="firstName"
            riseInputTrim
            maxlength="50"
          />

          <mat-label id="firstNameLabel">First name</mat-label>

          <mat-error>
            <rise-validation-messages
              controlName="firstName"
            ></rise-validation-messages>
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" class="rise-form-field">
          <input
            matInput
            data-nid-target="lastName"
            tealiumInputFocus="Last-Name"
            id="lastNameTextInput"
            formControlName="lastName"
            riseInputTrim
            maxlength="50"
          />

          <mat-label id="lastNameLabel">Last name</mat-label>

          <mat-error>
            <rise-validation-messages
              controlName="lastName"
            ></rise-validation-messages>
          </mat-error>
        </mat-form-field>

        <div class="rise-split-field">
          <mat-form-field color="accent" class="rise-half-form-field">
            <input
              matInput
              data-nid-target="zipCode"
              id="zipCodeTextInput"
              tealiumInputFocus="Zip-Code"
              formControlName="zipCode"
              mask="00000"
              pattern="\d*"
              #zipcodeInput
            />

            <mat-label id="zipCodeLabel">Zip code</mat-label>

            <mat-error>
              <rise-validation-messages
                controlName="zipCode"
              ></rise-validation-messages>
            </mat-error>
          </mat-form-field>

          <mat-form-field color="accent" class="rise-half-form-field">
            <mat-select
              data-nid-target="state"
              placeholder="State"
              tealiumSelectChange="State"
              id="stateSelect"
              formControlName="state"
            >
              <mat-option></mat-option>
              <mat-option
                *ngFor="let state of allStates"
                [value]="state.stateCode"
              >
                {{ state.name }}
              </mat-option>
            </mat-select>

            <mat-error>
              <rise-validation-messages
                controlName="state"
              ></rise-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field color="accent" class="rise-form-field">
          <input
            #ssn
            data-nid-target="ssn"
            data-cobrowse-masked="true"
            data-private
            matInput
            id="ssnTextInput"
            tealiumInputFocus="SSN"
            type="text"
            formControlName="ssn"
            autocomplete="off"
            pattern="\d*"
            maxlength="11"
            [validation]="false"
            [hiddenInput]="ssnHiddenInput"
            (blur)="hideSSNInput(); resetEc()"
            (focus)="showSSNInput()"
            [mask]="ssnMaskPattern"
          />

          <mat-label id="ssnLabel">Social Security number</mat-label>
          <img
            matSuffix
            src="assets/images/info_icon.svg"
            class="tool-tip-icon"
            id="ssnTooltipIcon"
            #ssnToolTip="matTooltip"
            matTooltip="Your Social Security number allows us to check your credit score. 
                      We use multiple security methods to ensure that your information is safe."
            matTooltipClass="matTooltipClass"
            [matTooltipPosition]="isMobile ? 'below' : 'above'"
            (blur)="ssnToolTip.hide()"
            (focus)="ssnToolTip.show()"
            (click)="ssnToolTip.show()"
          />
          <mat-error>
            <rise-validation-messages
              controlName="ssn"
            ></rise-validation-messages>
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" class="rise-form-field">
          <input
            #email
            matInput
            data-nid-target="email"
            id="emailTextInput"
            tealiumInputFocus="Email"
            formControlName="email"
            maxlength="50"
            autocomplete="off"
            (blur)="emailToolTip.hide(); resetEc()"
            (focus)="emailToolTip.show()"
            (click)="emailToolTip.show()"
          />

          <mat-label id="emailLabel">Email</mat-label>
          <img
            matSuffix
            src="assets/images/info_icon.svg"
            class="tool-tip-icon"
            id="emailTooltipIcon"
            #emailToolTip="matTooltip"
            matTooltip="may contact you about our services 
                        at the email address provided."
            matTooltipClass="matTooltipClassLocal"
            [matTooltipPosition]="isMobile ? 'below' : 'above'"
          />
          <mat-error>
            <rise-validation-messages
              controlName="email"
            ></rise-validation-messages>
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" class="rise-form-field">
          <input
            matInput
            type="text"
            id="mobilePhoneNumberTextInput"
            data-nid-target="cellPhone"
            tealiumInputFocus="Mobile-Phone"
            formControlName="mobilePhoneNumber"
            maxlength="14"
            pattern="\d*"
            autocomplete="off"
            mask="(000) 000-0000"
          />

          <mat-label id="mobilePhoneNumberLabel">Mobile phone</mat-label>

          <mat-error>
            <rise-validation-messages
              controlName="mobilePhoneNumber"
            ></rise-validation-messages>
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" class="rise-form-field">
          <input
            #directMailCodeTextInput
            matInput
            maxlength="50"
            id="directMailCodeTextInput"
            data-nid-target="invitationCode"
            tealiumInputFocus="Enrollment-Code"
            formControlName="directMailCode"
            (blur)="resetEc()"
          />
          <img
            matSuffix
            src="assets/images/info_icon.svg"
            class="tool-tip-icon"
            id="directMailTooltipIcon"
            #directMailToolTip="matTooltip"
            [matTooltip]="
              lowInterestState
                ? 'You can find your invitation code on your offer letter.'
                : 'You can find your invitation code on your offer letter. No code?\n         No worries! Just leave this field blank and continue with your application.'
            "
            matTooltipClass="matTooltipClass"
            matTooltipPosition="above"
          />

          <mat-label id="directMailCodeLabel">
            Direct Mail / Invitation Code?
            <span *ngIf="!lowInterestState">(Optional)</span>
          </mat-label>

          <mat-error>
            <rise-validation-messages
              controlName="directMailCode"
            ></rise-validation-messages>
          </mat-error>
        </mat-form-field>
      </div>

      <button
        mat-flat-button
        focusOnError
        [riseSubmitToggle]="form"
        tealiumClick="Continue-Getting-Started"
        id="gettingStartedContinueButton"
        type="submit"
        class="rise-submit-button"
        (click)="submit()"
      >
        Continue
      </button>
      <p id="privacyAcknowledgement" class="privacyMessage">
        By clicking Continue, you acknowledge and agree for RISEcredit.com to
        record your web session for training and customer support purposes.
        RISEcredit.com will not have access to your device or personal
        information and the recorded web session will remain private and secure.
        Please note that RISEcredit.com cannot see your screen once you leave
        RISEcredit.com.
      </p>
      <p id="ccpaMessage" class="privacyMessage">
        California Residents, view the <rise-brand></rise-brand>
        <a
          tabindex="-1"
          target="_blank"
          rel="noopener noreferrer"
          id="caPrivacyPolicyLink"
          class="learn-more-link"
          href="https://www.risecredit.com/privacy-policy/ca-privacy-policy/"
          >California Disclosures and Privacy Policy</a
        >
        for more information on what we collect.
      </p>
      <rise-side-bar></rise-side-bar>
    </form>

    <div *ngIf="debugService.isReCaptchaEnabled">
      <rise-recaptcha></rise-recaptcha>
    </div>
  </div>
</div>
<rise-debug-hud>
  <button (click)="debugService.simulateData()">Simulate Data</button>
  <button (click)="debugService.toggleReCatpcha()">
    {{ debugService.isReCaptchaEnabled ? 'Disable' : 'Enable' }} ReCaptcha
  </button>
  <button (click)="debugService.setEmailOnlyTimeout()">
    Set lock to 1 min
  </button>
  <!-- <button (click)="toggleHolidayBanner()">Toggle Holiday Banner</button> -->
</rise-debug-hud>
