import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '@app/validation/validators/required.validator';
import { DisclosureFormService } from '../../services/disclosure-form.service';

export const textAgreementTagName = 'rise-text-agreement';

@Component({
  selector: textAgreementTagName,
  templateUrl: './text-agreement.component.html',
  styleUrls: ['./text-agreement.component.scss'],
})
export class TextAgreementComponent implements AfterViewInit {
  public fieldName: string;
  public showForm = false;
  private formControl: FormControl;

  constructor(
    private el: ElementRef,
    private disclosureFormService: DisclosureFormService
  ) {
    this.fieldName = this.el.nativeElement.getAttribute('fieldName');
    this.formControl = new FormControl(null, [requiredValidator('Required')]);
  }

  public ngAfterViewInit(): void {
    this.disclosureFormService.addFormControl(this.fieldName, this.formControl);
    this.showForm = true;
  }

  public get form(): FormGroup {
    return this.disclosureFormService.form;
  }
}
