import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MaintenanceService } from '@app/acquisition/maintenance/services/maintenance.service';
import { SpinnerModalComponent } from '@app/acquisition/shared-module/spinner-modal/spinner-modal.component';
import { AppConfigService } from '@app/appconfig/appconfig.service';
import { Tealium } from '@app/tealium/tealium.service';
import { ApplicationDataService } from '@application/application.service';
import { ValidationMessagesError } from '@elevate/forms';
import { IdentityApiService } from '../services/identity-api.service';
import {
  AccountSetupFormGroup,
  userNameValidationType,
} from './account-setup.form';
import { passwordValidationType } from './account-setup.form';

@Component({
  selector: 'rise-app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss'],
  providers: [AccountSetupFormGroup],
})
export class AccountSetupComponent implements OnInit {
  public passwordValidation = passwordValidationType;
  public userNameValidation = userNameValidationType;
  public validIcon = 'assets/images/validation_confirmed.svg';
  public unvalidIcon = 'assets/images/validation_not_confirmed.svg';
  public defaultIcon = 'assets/images/validation.svg';
  public mockString = 'L0ans12345678!';
  public defaultUserName = 'UserName' + this.getRandomInt();
  public userNameEnabled = false;
  private spinnerDialogRef: MatDialogRef<SpinnerModalComponent>;

  constructor(
    public form: AccountSetupFormGroup,
    private dialog: MatDialog,
    private router: Router,
    private identityApi: IdentityApiService,
    private applicationDataService: ApplicationDataService,
    private appConfigService: AppConfigService,
    private tealium: Tealium,
    private maintenance: MaintenanceService
  ) {}

  public ngOnInit(): void {
    const application = this.applicationDataService.get();
    const applicant = application.applicant;

    this.form.patchValue({
      email: applicant.emails[0].address,
    });
    this.appConfigService
      .isAzureB2cEnabled()
      .subscribe((result) => (this.userNameEnabled = result));
  }
  public getvalidationIcon(validator: string): string {
    const passwordControl = this.form.controls['createPassword'];
    if (passwordControl.hasError(validator)) {
      if (passwordControl.pristine || passwordControl.untouched) {
        return this.defaultIcon;
      }
      return this.unvalidIcon;
    }
    return this.validIcon;
  }

  public getvalidationUserIcon(validator: string): string {
    const userNameControl = this.form.controls['userName'];

    if (userNameControl.hasError(validator)) {
      if (userNameControl.pristine || userNameControl.untouched) {
        return this.defaultIcon;
      }
      return this.unvalidIcon;
    }
    return this.validIcon;
  }
  public submit(): void {
    if (this.maintenance.checkMaintenance()) {
      return;
    }
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    this.spinnerDialogRef = this.dialog.open(SpinnerModalComponent, {
      panelClass: 'rise-saving-modal',
      disableClose: true,
      closeOnNavigation: false,
    });

    this.identityApi
      .createIdentity(
        this.form.get('createPassword').value,
        this.form.get('userName').value
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          this.spinnerDialogRef.close();
          if (response.status === 204) {
            this.tealium.trackPageSuccessEvent(
              'Continue-Account-Setup-Success'
            );
            this.router.navigate(['accept/disclosures']);
          }
        },
        (error) => {
          const httpError = error as HttpErrorResponse;
          if (httpError.status === 400) {
            this.addDuplicateUserNameError();
          } else {
            this.router.navigate(['/error']);
          }
          this.spinnerDialogRef.close();
        }
      );
  }

  public getEmailValidationIcon(validator: string): string {
    const passwordControl = this.form.controls['createPassword'];
    if (passwordControl.hasError(validator)) {
      return this.unvalidIcon;
    }
    if (passwordControl.pristine) {
      return this.defaultIcon;
    }
    if (
      passwordControl.dirty &&
      passwordControl.untouched &&
      passwordControl.value === ''
    ) {
      return this.defaultIcon;
    }
    if (
      passwordControl.dirty &&
      passwordControl.touched &&
      passwordControl.value === ''
    ) {
      return this.unvalidIcon;
    }

    return this.validIcon;
  }

  public setValues(): void {
    const passwordControl = this.form.controls['createPassword'];
    passwordControl.setValue(this.mockString);
    passwordControl.markAsDirty();
    this.form.get('confirmPassword').setValue(this.mockString);
    this.setUserName();
  }
  private getRandomInt() {
    return Math.floor(Math.random() * 1000);
  }

  private setUserName(): void {
    const userNameControl = this.form.controls['userName'];
    userNameControl.setValue(this.defaultUserName);
    userNameControl.markAsDirty();
  }

  private addDuplicateUserNameError(): void {
    this.form
      .get('userName')
      .setErrors(
        new ValidationMessagesError(
          'duplicateUserNameError',
          null,
          'User already exists. Please use a different user name.'
        )
      );
  }
}
