import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FocusOnErrorDirective } from './focus-on-error/focus-on-error.directive';
import { RestrictInputDirective } from './restrict-input/restrict-input.directive';
import { ValidationErrorComponent } from './rise-validation-error/rise-validation-error.component';
import { TrimInputDirective } from './trim-input/trim-input.directive';
import { ValidationInputClassDirective } from './validation-input-class/validation-input-class.directive';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';
import { ValidationConfiguration } from './validation.configuration';

// const exports = [
//   ValidationMessagesComponent,
//   ValidationInputClassDirective,
//   RestrictInputDirective,
//   FocusOnErrorDirective,
//   TrimInputDirective,
// ];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule],
  declarations: [
    ValidationMessagesComponent,
    ValidationErrorComponent,
    ValidationInputClassDirective,
    RestrictInputDirective,
    FocusOnErrorDirective,
    TrimInputDirective,
  ],
  providers: [ValidationConfiguration],
  exports: [
    ValidationMessagesComponent,
    ValidationErrorComponent,
    ValidationInputClassDirective,
    RestrictInputDirective,
    FocusOnErrorDirective,
    TrimInputDirective,
  ],
  entryComponents: [ValidationMessagesComponent, ValidationErrorComponent],
})
export class ValidationModule {}
