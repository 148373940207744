import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const requiredValidatorName = 'required';

export function requiredValidator(
  text: string = '! Required field.',
  rules?: Rule[]
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors {
    if (Validators.required(control) || Validators.pattern(/.*\S.*/)(control)) {
      return new ValidationMessagesError(requiredValidatorName, rules, text);
    }
    return null;
  };
}
