import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SmartyStreetConfiguration } from './smarty-street.configuration';

export interface SmartyStreetLookupResponse {
  isValid: boolean;
  zipcode?: SmartyStreetZipCode;
}

export interface SmartyStreetLookupRequest {
  city?: string;
  state?: string;
  zipcode?: string;
}

export interface SmartyStreetLookupApiResponse {
  status?: boolean;
  reason?: string;
  city_states?: SmartyStreetCityState[];
  zipcodes?: SmartyStreetZipCode[];
}

export interface SmartyStreetCityState {
  city?: string;
  state_abbreviation?: string;
  state?: string;
  mailable_city?: boolean;
}

export interface SmartyStreetZipCode {
  alternate_counties?: any[];
  zipcode: string;
  zipcode_type: string;
  default_city: string;
  county_fips: string;
  county_name: string;
  state_abbreviation: string;
  state: string;
  latitude: number;
  longitude: number;
  precision: string;
}

export interface SmartyStreetSuggestRequest {
  prefix: string;
  state_filter: string;
  city_filter?: string;
}

export interface SmartyStreetSuggestResponse {
  suggestions: SmartyStreetSuggestion[];
}

export interface SmartyStreetSuggestion {
  city?: string;
  state?: string;
  street_line?: string;
  text?: string;
}

@Injectable()
export class SmartyStreetApi {
  constructor(
    private http: HttpClient,
    private configuration: SmartyStreetConfiguration
  ) {}

  public lookup(
    request: SmartyStreetLookupRequest
  ): Observable<SmartyStreetLookupApiResponse[]> {
    const options = {
      params: {
        'auth-id': this.configuration.siteKey,
        ...request,
      },
    };

    return this.http.get<SmartyStreetLookupApiResponse[]>(
      this.configuration.url.lookup,
      options
    );
  }

  public lookupPrimaryZipcode(
    zipcode: string
  ): Observable<SmartyStreetLookupResponse> {
    return this.lookup({ zipcode }).pipe(
      map((apiResponse: SmartyStreetLookupApiResponse[]) => {
        const isValid = apiResponse[0].status === undefined;

        const response: SmartyStreetLookupResponse = {
          isValid,
          zipcode: isValid ? apiResponse[0].zipcodes[0] : undefined,
        };

        return response;
      })
    );
  }

  public suggest(
    request: SmartyStreetSuggestRequest
  ): Observable<SmartyStreetSuggestResponse> {
    const options = {
      params: {
        'auth-id': this.configuration.siteKey,
        ...request,
      },
    };

    return this.http
      .get<SmartyStreetSuggestResponse>(this.configuration.url.suggest, options)
      .pipe(
        catchError((err: any) => {
          throw new Error(
            'Address Verification Provider is currently unavailable'
          );
        })
      );
  }
}
