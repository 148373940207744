import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment.module';

@Injectable()
export class ReCaptchaConfiguration {
  public readonly siteKey: string;

  constructor(environment: Environment) {
    this.siteKey = environment.reCaptcha.siteKey;
  }
}
