<div [formGroup]="form" *ngIf="showForm">
  <div class="ssn-field-wrapper">
    <!-- htmllint id-class-style=false -->
    <input
      data-cobrowse-masked="true"
      data-private
      id="{{ fieldName }}"
      data-nid-target="last4SsnText"
      formControlName="{{ fieldName }}"
      maxlength="4"
      mask="0000"
    />
  </div>
  <mat-error>
    <rise-validation-messages
      controlName="{{ fieldName }}"
    ></rise-validation-messages>
  </mat-error>
</div>
