import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlaidEventRequest } from '@app/acquisition/plaid-link/plaid-link.types';
import { Environment } from '@app/environment/environment.module';
import { Observable } from 'rxjs';

export interface BankConnectionApiRequest {
  publicToken: string;
  provider: 'Plaid';
  selectedAccountId: string;
  applicationId: string;
}

export interface BankConnectionApiResponse {
  accounts: PlaidCheckingAccounts[];
  checkingAccountSelected: boolean;
}

export interface PlaidCheckingAccounts {
  key: string;
  accountNumberLast4: string;
}

export interface BankAccountsInfo {
  apiRequestOrigin: string;
  accounts: PlaidCheckingAccounts[];
}

export interface PlaidErrorEvent {
  code: string;
}

@Injectable()
export class BankConnectionApi {
  constructor(private http: HttpClient, private environment: Environment) {}

  public postPlaidResults(
    request: BankConnectionApiRequest
  ): Observable<HttpResponse<BankConnectionApiResponse>> {
    return this.http.post<BankConnectionApiResponse>(
      `${this.environment.brandApi.url}/api/v1/bank-connection`,
      request,
      {
        observe: 'response',
      }
    );
  }

  public postPlaidErrorCode(event: PlaidErrorEvent): Observable<any> {
    return this.http.post<any>(
      `${this.environment.brandApi.url}/api/v1/bank-connection-failed`,
      event
    );
  }

  public postPlaidEvent(event: PlaidEventRequest): Observable<any> {
    return this.http.post<any>(
      `${this.environment.brandApi.url}/api/v1/bank-connection/ui/events`,
      event
    );
  }
}
