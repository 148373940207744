<mat-card class="loan-option-card">
  <div class="card-title">LOAN AMOUNT</div>
  <div class="card-amount"
       id="loanAmountCardLoanText">
    {{ loanAmount | currency: 'USD':'symbol':'1.0-0' }}
  </div>
  <div class="card-subtext"
       id="loanAmountCardAprText">APR {{ apr }}%</div>
  <div class="card-subtext"
       id="loanAmountCardOriginationFeeText"
       *ngIf="originationFeeConfig.isloanOriginationFeeApplicable">
    {{ originationFeeConfig.originationFeeDisplayString }} Origination Fee up to ${{
      originationFeeConfig.originationFeeCapDollarAmount
    }} [${{
    originationFeeConfig.originationFee.toFixed(2)
    }}] deducted from loan amount
  </div>
  <button class="card-button"
          (click)="openLoanAmountModal()"
          tealiumPopup="Loan-Amount-Loan-Amt-Pop-up"
          id="loanAmountCardChangeButton">
    CHANGE LOAN AMOUNT
  </button>
</mat-card>
