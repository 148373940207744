import { Component, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Environment } from '@app/environment/environment.module';
declare var ININ: any;
function startCobrowse(
  webchatAppUrl: string,
  webchatServiceUrl: string,
  orgGuid: string,
  promptForSecurityKey: boolean,
  logLevel: string,
  stylesUrl: string
) {
  const cobrowseConfig = {
    // Web chat application URL
    webchatAppUrl: webchatAppUrl,

    // Web chat service URL
    webchatServiceUrl: webchatServiceUrl,

    // organization GUID
    orgGuid: orgGuid,

    // Log level
    logLevel: logLevel,

    // Locale code
    locale: 'en',

    // CSS class applied to the security key window, but not its contents
    cssClass: 'webchat-frame',

    contentCssUrl: stylesUrl,

    // Custom style applied to the security key window, but not its contents
    css: {
      width: '100%',
    },

    // Element id where the security key form will appear.
    containerEl: 'cobrowse-security-form',

    // Set to true to display security key form. Do not include for co-browse with web chat or for PureConnect co-browse with voice.
    promptForSecurityKey: promptForSecurityKey,
  };

  // Can also use callback
  return ININ.cobrowse.create(cobrowseConfig);
}

@Component({
  selector: 'CoBrowse-modal',
  templateUrl: './cobrowse-modal.component.html',
  styleUrls: ['./cobrowse-modal.component.scss'],
})
export class CoBrowseModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CoBrowseModalComponent>,
    private environment: Environment,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.addWebChatScript();
    this.addCobrowseScript();
  }

  public ngAfterViewInit() {
    this.startCobrowse();
  }

  public startCobrowse() {
    const chatScript = this.el.nativeElement.querySelector('#chat-script');
    const stylesHref = this.getStylesHref();
    const stylesUrl = `${this.environment.coBrowseConfiguration.appBaseUrl}/${stylesHref}`;
    this.renderer.listen(chatScript, 'load', () => {
      startCobrowse(
        this.environment.coBrowseConfiguration.webchatAppUrl,
        this.environment.coBrowseConfiguration.webchatServiceUrl,
        this.environment.coBrowseConfiguration.orgGuid,
        this.environment.coBrowseConfiguration.promptForSecurityKey,
        this.environment.coBrowseConfiguration.logLevel,
        stylesUrl
      );
    });
  }

  public ngDoCheck() {
    const webchatFrame =
      document.documentElement.querySelector('.webchat-frame');
    if (webchatFrame && !webchatFrame.getAttribute('title')) {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      webchatFrame?.setAttribute('title', 'coBrowseWebChat');
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  private addWebChatScript(): void {
    const webchatScript = this.renderer.createElement('script');
    webchatScript.id = 'chat-script';
    webchatScript.type = 'text/javascript';
    webchatScript.src = this.environment.coBrowseConfiguration.chatScriptUrl;
    webchatScript.setAttribute(
      'region',
      this.environment.coBrowseConfiguration.chatRegion
    );
    webchatScript.setAttribute(
      'org-guid',
      this.environment.coBrowseConfiguration.orgGuid
    );
    webchatScript.setAttribute(
      'deployment-key',
      this.environment.coBrowseConfiguration.chatDeploymentKey
    );
    this.renderer.appendChild(this.el.nativeElement, webchatScript);
  }

  private addCobrowseScript(): void {
    const cobrowseScript = this.renderer.createElement('script');
    cobrowseScript.id = 'cobrowse-script';
    cobrowseScript.type = 'text/javascript';
    cobrowseScript.src =
      this.environment.coBrowseConfiguration.cobrowseScriptUrl +
      this.environment.coBrowseConfiguration.cobrowseDeploymentKey;
    this.renderer.appendChild(this.el.nativeElement, cobrowseScript);
  }

  private getStylesHref(): string | null | undefined {
    const stylesElement = document.querySelector(
      "link[rel='stylesheet'][href*='styles.'][href$='.css']"
    );
    const stylesHref = stylesElement?.getAttribute('href');
    return stylesHref;
  }
}
