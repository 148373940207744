import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DisclosureFormService {
  private formGroup: FormGroup;

  constructor() {
    this.resetForm();
  }

  public addFormControl(name: string, control: FormControl): void {
    this.formGroup.addControl(name, control);
  }

  public get form(): FormGroup {
    return this.formGroup;
  }

  public resetForm(): void {
    this.formGroup = new FormGroup({});
  }
}
