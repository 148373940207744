<div class="dialog-header">
  <img
    id="closeImage"
    class="close-img"
    alt="close button"
    tealiumClick="OTP-Popup-Close"
    src="assets/images/button_close.svg"
    (click)="dialogRef.close()"
  />
</div>

<div *ngIf="page === 'WelcomeWithOptions'">
  <div mat-dialog-title class="dialog-header">
    <p class="modal-title-text">Welcome back!</p>
  </div>
  <mat-dialog-content class="mat-typography">
    <div>
      <p>
        You have already started an application with <rise-brand></rise-brand>.
        Would you like to resume that application?
      </p>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions id="welcomeButtonDiv">
    <button
      id="noResumeAppButton"
      mat-button
      class="otp-modal-button no-button"
      tealiumClick="Welcome-back-Popup-No"
      (click)="closeModal()"
    >
      No
    </button>
    <button
      id="yesResumeAppButton"
      mat-button
      class="otp-modal-button"
      tealiumClick="Welcome-back-Popup-Yes"
      (click)="showSendCode()"
      cdkFocusInitial
    >
      Yes
    </button>
  </div>
</div>

<div *ngIf="page === 'WelcomeWithoutOptions'">
  <div mat-dialog-title class="dialog-header">
    <p class="modal-title-text">Welcome back!</p>
  </div>
  <mat-dialog-content class="mat-typography">
    <div>
      <p>
        You have an active application already on file with <i>Rise</i>. Click
        continue below to resume the application.
      </p>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button
      id="continueAppButton"
      mat-button
      class="otp-modal-button"
      (click)="showSendCode()"
      cdkFocusInitial
    >
      Continue
    </button>
  </div>
</div>

<div *ngIf="page === 'SendCode'">
  <div mat-dialog-title class="dialog-header">
    <p class="modal-title-text">
      Please verify your identity to resume your application.
    </p>
  </div>
  <mat-dialog-content class="mat-typography">
    <div>
      <p>We'll send a code to the telephone number you've already provided.</p>
    </div>
    <div [formGroup]="contactTypeForm">
      <mat-radio-group
        class="send-code-radio-group"
        formContrlName="sendCodeMethod"
      >
        <mat-radio-button
          (click)="radioButtonChange('Sms')"
          class="send-code-radio-button"
          id="textRadioButton"
          tealiumClick="Verify-Identity-OTP1-Popup-Text"
          #textRadioButton
          value="Sms"
          checked
        >
          Text Me at XXX-XXX-{{ phoneNumberLast4 }}
        </mat-radio-button>
        <mat-radio-button
          (click)="radioButtonChange('Call')"
          class="send-code-radio-button"
          id="callRadioButton"
          tealiumClick="Verify-Identity-OTP1-Popup-Call"
          #callRadioButton
          value="Call"
        >
          Call Me at XXX-XXX-{{ phoneNumberLast4 }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-error>
        <rise-validation-messages
          controlName="sendCodeMethod"
        ></rise-validation-messages>
      </mat-error>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button
      id="sendCodeButton"
      class="otp-modal-button"
      (click)="sendVerificationCode()"
      tealiumClick="Verify-Identity-OTP1-Popup-Send-Code"
      mat-button
      cdkFocusInitial
    >
      Send Code
    </button>
  </div>
</div>

<div *ngIf="page === 'VerifyCode'">
  <div mat-dialog-title class="dialog-header">
    <p id="verifyCodeTitle" class="modal-title-text">
      Please verify your identity to resume your application.
    </p>
  </div>
  <mat-dialog-content class="mat-typography">
    <div [formGroup]="verificationForm">
      <mat-form-field color="accent" class="rise-form-field">
        <input
          matInput
          data-private
          id="codeTextInput"
          formControlName="verificationCode"
          placeholder="Enter your unique verification code"
          autocomplete="off"
          mask="000000"
          data-cobrowse-masked="true"
          tealiumInputFocus="Verify-Identity-OTP2-Popup-VerificationCode"
        />
        <mat-error>
          <rise-validation-messages
            controlName="verificationCode"
          ></rise-validation-messages>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions id="verifyCodeButtonDiv">
    <button
      id="goBackButton"
      class="otp-modal-button no-button"
      (click)="goBack()"
      tealiumClick="Verify-Identity-OTP2-Popup-GoBack"
      mat-button
    >
      Go Back
    </button>
    <button
      id="verifyCodeButton"
      focusOnError
      mat-flat-button
      [riseSubmitToggle]="verificationForm"
      class="otp-modal-button"
      type="submit"
      (click)="verificationSubmit()"
      tealiumClick="Verify-Identity-OTP2-Popup-Submit"
    >
      Submit
    </button>
  </div>
</div>
