import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const requiredCheckboxValidatorName = 'required';

export function requiredCheckboxValidator(
  text: string = '! Required field.',
  rules?: Rule[]
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors {
    if (Validators.requiredTrue(control)) {
      return new ValidationMessagesError(
        requiredCheckboxValidatorName,
        rules,
        text
      );
    } else {
      return null;
    }
  };
}
