import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApplicationDataService } from '@application/application.service';
import { MarriedFormGroup, marriedFormGroupName } from './married.form';

@Component({
  selector: 'rise-married',
  templateUrl: './married.component.html',
  styleUrls: ['./married.component.scss'],
  providers: [MarriedFormGroup],
})
export class MarriedComponent implements OnInit, OnDestroy {
  @Input() public formGroup: FormGroup;
  private applicantEmail: string;

  constructor(
    public form: MarriedFormGroup,
    private applicationDataService: ApplicationDataService
  ) {}

  public ngOnInit(): void {
    const application = this.applicationDataService.get();
    this.applicantEmail = application.applicant.emails[0].address;

    this.formGroup.addControl(marriedFormGroupName, this.form);
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl(marriedFormGroupName);
  }

  public checkboxChange(checkboxValue: boolean): void {
    if (checkboxValue) {
      this.form.patchValue({ spouseEmailAddress: this.applicantEmail });
      this.form.controls['spouseEmailAddress'].disable();
    } else {
      this.form.patchValue({ spouseEmailAddress: null });
      this.form.controls['spouseEmailAddress'].enable();
    }
  }
}
