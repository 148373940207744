import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[riseInputTrim]',
})
export class TrimInputDirective {
  constructor(private control: NgControl) {}

  @HostListener('blur', ['$event.target.value'])
  public onInputBlur(value: string): any {
    this.control.control.setValue(value.trim());
  }
}
