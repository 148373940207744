import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationProxy } from '@location-proxy/location-proxy.service';

export interface MonetizationData {
  monetizationUrl: string;
  stateName: string;
  formElements?: any;
  
  
}

@Component({
  selector: 'rise-monetization-modal',
  templateUrl: './monetization-modal.component.html',
  styleUrls: ['./monetization-modal.component.scss'],
})
export class MonetizationModalComponent {
  specialStates: string[] = ['Indiana'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public monetizationData: MonetizationData,
    public locationProxy: LocationProxy,
    private dialogRef: MatDialogRef<MonetizationModalComponent>
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public notInStateClose(): void {
    this.dialogRef.close();

    // actions in getstarted form
    this.monetizationData.formElements.getStartedform
      .get('zipCode')
      .setValue(null);
    this.monetizationData.formElements.getStartedform
      .get('zipCode')
      .markAsPristine();
    this.monetizationData.formElements.getStartedform
      .get('state')
      .setValue(null);
    this.monetizationData.formElements.getStartedform
      .get('state')
      .markAsPristine();

    this.monetizationData.formElements.zipcodeInput.nativeElement.focus();
  }

  public redirectToGuideToLenders(): void {
    this.locationProxy.assign(this.monetizationData.monetizationUrl);
  }
  isSpecialState(stateName: string | undefined): boolean {
    return stateName ? this.specialStates.includes(stateName) : false;
  }
}
