import { Injectable } from '@angular/core';
import { ScriptLoader } from '@app/script-loader.service';
import { Observable, ReplaySubject } from 'rxjs';

export interface BlackboxResponse {
  blackbox: string;
  finished: boolean;
}

@Injectable()
export class IovationService {
  private iovationCompleteSubject = new ReplaySubject<BlackboxResponse>(1);

  constructor(private scriptLoader: ScriptLoader) {
    window['io_global_object_name'] = 'IGLOO';
    window['IGLOO'] = window['IGLOO'] || {
      bb_callback: this.bbCallback.bind(this),
      loader: {
        version: 'general5',
        subkey: 'eos-rise',
      },
    };

    this.scriptLoader.loadScript('assets/scripts/iovation_loader.js');
  }

  public getIovationBlackBox(): Observable<BlackboxResponse> {
    return this.iovationCompleteSubject.asObservable();
  }

  public get iovationBlackBoxSubject(): ReplaySubject<BlackboxResponse> {
    return this.iovationCompleteSubject;
  }

  public bbCallback(bb: string, complete: boolean): void {
    if (complete) {
      this.iovationCompleteSubject.next({
        blackbox: bb,
        finished: complete,
      });
    }
  }
}
