import { Component, Input, OnDestroy } from '@angular/core';
import { PaymentOptionItem } from '@app/acquisition/accept/models/payment-option-item.interface';
import { PaymentScheduleItem } from '@app/acquisition/accept/models/payment-schedule-item.interface';
import { LoanPaymentDataService } from '@app/acquisition/accept/services/loan-payment-data.service';
import { Tealium } from '@app/tealium/tealium.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rise-payment-calendar',
  templateUrl: './payment-calendar.component.html',
  styleUrls: ['./payment-calendar.component.scss'],
})
export class PaymentCalendarComponent implements OnDestroy {
  public get stateCode(): string {
    return this._stateCode;
  }
  @Input() public set stateCode(value: string) {
    this._stateCode = value;
    this.interestColumnName =
      this._stateCode === 'MS' ? 'HANDLING CHARGE' : 'INTEREST';
  }
  public firstPaymentDate: string;
  public toggleButtonMessage = 'View your entire payment schedule';
  public showAllPayments: boolean;
  public displayPaymentSchedule: PaymentScheduleItem[];
  public interestColumnName: string;
  private paymentSchedule: PaymentScheduleItem[];
  private paymentScheduleSubscription: Subscription;
  // tslint:disable-next-line: variable-name
  private _stateCode: string;

  constructor(
    private loanPaymentDataService: LoanPaymentDataService,
    private tealium: Tealium
  ) {
    this.paymentScheduleSubscription = this.loanPaymentDataService
      .getPaymentScheduleSubject()
      .subscribe((item: PaymentOptionItem) => {
        this.firstPaymentDate =
          item.paymentSchedule.length > 0
            ? this.formatDate(item.paymentSchedule[0].dueDate)
            : '';
        this.paymentSchedule = item.paymentSchedule.map(
          (scheduleItem: PaymentScheduleItem) => {
            return {
              principal: scheduleItem.principal,
              interest: scheduleItem.interest,
              totalPayment: scheduleItem.totalPayment,
              dueDate: this.formatDate(scheduleItem.dueDate),
            };
          }
        );
        this.displayPaymentSchedule = this.paymentSchedule.slice(0, 3);
        this.showAllPayments = false;
        this.setToggledProperties();
      });
  }

  public formatDate(date: string): string {
    return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }

  public toggleShowAllPayments(): void {
    this.showAllPayments = !this.showAllPayments;
    this.setToggledProperties();
  }

  public ngOnDestroy(): void {
    this.paymentScheduleSubscription.unsubscribe();
  }

  private setToggledProperties(): void {
    this.toggleButtonMessage = this.showAllPayments
      ? 'View your first three payments'
      : 'View your entire payment schedule';
    this.displayPaymentSchedule = this.showAllPayments
      ? this.paymentSchedule
      : this.paymentSchedule.slice(0, 3);

    if (this.showAllPayments) {
      this.tealium.clickOptionEvent('View-All-Payment-Schedule');
    } else {
      this.tealium.clickOptionEvent('View-First-Three-Payment-Schedule');
    }
  }
}
