import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PlaidLinkService } from '../../plaid-link/plaid-link.service';

@Component({
  selector: 'rise-multiple-bank-account-modal',
  templateUrl: './multiple-bank-account-modal.component.html',
  styleUrls: ['./multiple-bank-account-modal.component.scss'],
})
export class MultipleBankAccountModalComponent {
  constructor(
    public dialogRef: MatDialogRef<MultipleBankAccountModalComponent>,
    private plaidLinkService: PlaidLinkService
  ) {}

  public onReturnClick(): void {
    this.dialogRef.close();
    this.plaidLinkService.plaidOAuthStateId = null;
    this.plaidLinkService.createPlaidLink();
  }
}
