import * as deepMerge from 'deepmerge';

export function mergeObjectInArrayByKey(): any {
  return (obj1: any, obj2: any) => {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      obj1.forEach((o1: any, i: number) => {
        const sameKeyObject = obj2.find((o2: any) => o2.key === o1.key);

        if (sameKeyObject) {
          obj1[i] = deepMerge(o1, sameKeyObject, {
            customMerge: mergeObjectInArrayByKey,
          });
        }
      });
      return obj1;
    }
    return deepMerge(obj1, obj2, {
      customMerge: mergeObjectInArrayByKey,
    });
  };
}
