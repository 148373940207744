import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rise-bank-connection-error-modal',
  templateUrl: './bank-connection-error-modal.component.html',
  styleUrls: ['./bank-connection-error-modal.component.scss'],
})
export class BankConnectionErrorModalComponent {
  constructor(
    public dialogRef: MatDialogRef<BankConnectionErrorModalComponent>
  ) {}

  public onReturnClick(): void {
    this.dialogRef.close();
  }
}
