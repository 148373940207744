import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rise-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss'],
})
export class InactivityModalComponent {
  public message: String;
  constructor(
    public dialogRef: MatDialogRef<InactivityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public continueSession(): void {
    this.dialogRef.close();
  }
}
