import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@app/forms/page-form-group/page-form-group';
import { requiredValidator } from '@app/validation/validators/required.validator';

export interface ResumeApplicationInfo {
  verificationCode: string;
  sendCodeMethod: string;
}

@Injectable()
export class ResumeApplicationVerificationInfoFormGroup extends PageFormGroup {
  public value: ResumeApplicationInfo;

  constructor() {
    super({
      verificationCode: new FormControl(null, {
        validators: [requiredValidator('Verification code is required.')],
      }),
    });
  }
}

@Injectable()
export class ResumeApplicationInfoFormGroup extends PageFormGroup {
  public value: ResumeApplicationInfo;

  constructor() {
    super({
      sendCodeMethod: new FormControl('Sms', [requiredValidator('Required')]),
    });
  }
}
