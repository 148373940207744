import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@app/environment/environment.module';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class PlaidLinkApiService {
  constructor(private http: HttpClient, private environment: Environment) {}

  public getLinkToken(
    lenderCode: string,
    clientName: string,
    products: string[],
    linkCustomizationName: string,
    redirectUri: string
  ): Observable<string> {
    const request = {
      lenderCode,
      clientName,
      products,
      linkCustomizationName,
      redirectUri,
    };

    return this.http
      .post<string>(
        `${this.environment.brandApi.url}/api/v1/create-link-token`,
        request
      )
      .pipe(
        map((response: any) => response.linkToken),
        take(1)
      );
  }
}
