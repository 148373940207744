import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment.module';

@Injectable()
export class SmartyStreetConfiguration {
  public readonly siteKey: string;
  public readonly url: {
    lookup: string;
    suggest: string;
  };

  constructor(environment: Environment) {
    Object.assign(this, environment.smartyStreets);
  }
}
