<div class="rise-page-content">
  <div class="rise-page-container">
    <div class="rise-padded-content">
      <div class="rise-page-title" id="cytTitleText">
        Great news, {{ firstName }}! You're approved for
        {{ loanMinMax.maximum | currency: 'USD':'symbol':'1.0-0' }}!
      </div>
      <p
        *ngIf="offerType === 'CounterOffer'"
        class="rise-body-text"
        id="cytCounterOfferText"
      >
        You didn't qualify for
        {{ counterAmount | currency: 'USD':'symbol':'1.0-0' }}
        <a (click)="getNOAA()" class="learn-more-link" id="noaaDownloadLink">
          (view more details)</a
        >​, but you are qualified for
        {{ loanMinMax.maximum | currency: 'USD':'symbol':'1.0-0' }}.
      </p>

      <rise-doc-retrieval-error
        [showDocumentError]="showDocumentError"
      ></rise-doc-retrieval-error>

      <p class="rise-body-text" id="cytBodyText">
        Please review your loan amount and terms to receive your money.
        Customize your loan amount and payment options below.
      </p>

      <div
        class="rise-page-title-local"
        id="fundingAmountHeader"
        *ngIf="isloanOriginationFeeApplicable"
      >
        <b>The amount you will receive will be {{ fundedAmount  |  currency: 'USD':'symbol': '1.2-2'}} </b>
      </div>

      <div *ngIf="isRefinanceApplication">
        <rise-refi-loan-summary
          [currentPayoff]="currentPayoff"
          [depositAmount]="depositAmount | async"
          [loanAmount]="loanAmount"
        ></rise-refi-loan-summary>
      </div>

      <div class="payment-card-div">
        <rise-loan-amount-card
          class="loan-card-item"
          [payoffAmount]="currentPayoff"
          [originationFeeConfig]="originationFeeConfig"
        ></rise-loan-amount-card>
        <rise-payment-options-card
          class="loan-card-item"
          [frequency]="frequency"
          [isloanOriginationFeeApplicable]="isloanOriginationFeeApplicable"
        ></rise-payment-options-card>
      </div>

      <rise-payment-calendar [stateCode]="state"></rise-payment-calendar>
    </div>

    <button
      mat-flat-button
      id="cytContinueButton"
      type="submit"
      class="rise-submit-button"
      (click)="submit()"
      tealiumClick="Continue-CYT-Installment"
    >
      Continue
    </button>

    <rise-side-bar></rise-side-bar>
  </div>
</div>
