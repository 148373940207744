import { Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { UnderwritingService } from '@app/acquisition/apply/services/underwriting.service';
import { SessionStorageService } from '@app/acquisition/services/session-storage-service/session-storage.service';
import { PlaidLinkService } from '@plaid-link/plaid-link.service';

@Injectable()
export class MaintenanceService implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private sessionStorageService: SessionStorageService,
    private underwritingService: UnderwritingService,
    private appDataService: ApplicationDataService,
    private plaidLinkService: PlaidLinkService
  ) {}

  public ngOnInit(): void {}

  public checkMaintenance(): boolean {
    return false;
  }

  public showMaintPage(): void {
    this.dialog.closeAll();
    this.sessionStorageService.clearAllSessionStorage();
    this.underwritingService.resetDecision();
    this.appDataService.resetApplication();
    this.plaidLinkService.closePlaid();
    this.router.navigated = false;
    this.router.navigate(['maint']);
  }

  public showGettingStartedtPage(): void {
    this.dialog.closeAll();
    this.sessionStorageService.clearAllSessionStorage();
    this.underwritingService.resetDecision();
    this.appDataService.resetApplication();
    this.plaidLinkService.closePlaid();
    this.router.navigated = false;
    this.router.navigate(['getting-started']);
  }
}
