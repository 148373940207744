import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppInsightService } from '@app/acquisition/services/app-insights/app-insights.service';
import { GpcService } from '@app/gpc/services/gpc.service';
import { Tealium } from '@app/tealium/tealium.service';
import { NeuroIdService } from './acquisition/services/neuro-id/neuro-id.service';

@Component({
  selector: 'rise-app',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private appInsights: AppInsightService,
    private neuroIdService: NeuroIdService,
    private tealium: Tealium,
    private gpcService: GpcService,
    private router: Router
  ) {
    this.appInsights.logPageView();
    this.neuroIdService.init();
    this.tealium.setTealiumVariables({
      visitorid: this.appInsights.appInsightsId,
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.gpcService.PostGpc();
      }
    });
  }
}
