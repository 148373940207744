import { AppInsightService } from '@acquisition/services/app-insights/app-insights.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationData,
  ApplicationFlows,
  UnderwritingOfferModel,
} from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { UnderwritingDecisionModel } from '@app/acquisition/application/underwriting.model';
import {
  PaymentFrequencyOption,
  paymentFrequencyOptions,
} from '@app/acquisition/apply/income/payment-frequency/payment-frequency-type';
import { MaintenanceService } from '@app/acquisition/maintenance/services/maintenance.service';
import { DocumentRetrievalService } from '@app/acquisition/services/document-retrieval-service/document-retrieval.service';
import { Tealium } from '@app/tealium/tealium.service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  StateOptionsApi,
  StateOptionsApiResponse,
} from '../../../../apply/state-options-api/state-options.api';
import {
  SessionStorageObjects,
  SessionStorageService,
} from '../../../../services/session-storage-service/session-storage.service';
import { ActiveLoanData } from '../../../models/active-loan-data.interface';
import { PaymentOptionItem } from '../../../models/payment-option-item.interface';
import { PaymentScheduleItem } from '../../../models/payment-schedule-item.interface';
import {
  LoanAmountMinMax,
  LoanPaymentDataService,
} from '../../../services/loan-payment-data.service';

export interface OriginationFeeConfiguration {
  originationFeePercentage?: number;
  originationFee?: number;
  isloanOriginationFeeApplicable?: boolean;
  originationFeeCapDollarAmount?: number;
  originationFeeDisplayString?: string;
}
@Component({
  selector: 'rise-app-choose-your-terms-installment',
  templateUrl: './choose-your-terms-installment.component.html',
  styleUrls: ['./choose-your-terms-installment.component.scss'],
})
export class ChooseYourTermsInstallmentComponent implements OnDestroy, OnInit {
  public firstName: string;
  public frequency: string;
  public offerType: string;
  public state: string;
  public csoFee: number;
  public loanMinMax: LoanAmountMinMax;
  public selectedSchedule: PaymentOptionItem;
  public loanAmount: number;
  public apr: number;
  public interestAmount: number;
  public requestedAmount: number;
  public counterAmount: number;
  public currentPayoff: number;
  public documentName = `noaa_${Date.now()}.pdf`;
  public applicationSubmitOffer: UnderwritingDecisionModel;
  public appPrequalOffer: UnderwritingDecisionModel;
  public showDocumentError = false;
  public isFormerApplication = false;
  public isRefinanceApplication = false;
  public depositAmount: Observable<number>;
  public originationFeePercentage?: number;
  public originationFeeCapDollarAmount: number;
  public isloanOriginationFeeApplicable?: boolean;
  public originationFee?: number;
  public fundedAmount: number;
  public originationFeeConfig: OriginationFeeConfiguration;
  public stateResourceResponseObs: Observable<StateOptionsApiResponse>;
  private application: ApplicationData;
  private paymentScheduleSubscription: Subscription;
  private loanAmountSubscription: Subscription;

  constructor(
    public loanPaymentDataService: LoanPaymentDataService,
    private applicationDataService: ApplicationDataService,
    private sessionStorageService: SessionStorageService,
    private documentRetrievalService: DocumentRetrievalService,
    private router: Router,
    private tealium: Tealium,
    private maintenance: MaintenanceService,
    private appInsightsService: AppInsightService,
    private stateOptionsApi: StateOptionsApi
  ) {
    this.application = this.applicationDataService.getApplication();

    this.state =
      this.application.form.applicant.residences[0].address.stateCode;

    this.isloanOriginationFeeApplicable = false;

    // Set these initial values so that web wont throw console errors on page lowd
    const configModel: OriginationFeeConfiguration = {
      originationFeePercentage: 0,
      originationFee: 0,
      isloanOriginationFeeApplicable: false,
      originationFeeCapDollarAmount: 0,
      originationFeeDisplayString: '',
    };
    this.originationFeeConfig = configModel;

    this.isFormerApplication =
      this.application.applicationFlow === ApplicationFlows.Former;
    this.isRefinanceApplication =
      this.application.applicationFlow === ApplicationFlows.Refi;
    this.loanMinMax = this.loanPaymentDataService.getLoanAmountMinMax();

    this.appPrequalOffer = this.application.underwriting.steps.find(
      (decision: any) =>
        decision.name === 'Prequal' && decision.status === 'Complete'
    );
    this.applicationSubmitOffer = this.application.underwriting.steps.find(
      (decision: any) =>
        decision.name === 'ApplicationSubmit' && decision.status === 'Complete'
    );
    this.firstName = this.application.form.applicant.identity.firstName;
    this.offerType = this.applicationSubmitOffer.disposition.type;

    if (this.isRefinanceApplication) {
      this.loanPaymentDataService
        .getActiveLoanData()
        .subscribe((response: ActiveLoanData) => {
          if (response.currentPayoff <= 0) {
            // If Current Payoff is $0 for refi, do not let the customer continue
            // This is usually caused by a failed refi loan booking and systems exceptions will handle it
            appInsightsService.trackEvent(
              'ChooseYourTermsInstallmentComponent.ctor.ZeroDollarPayoffForRefi',
              { applicationId: this.application.id }
            );
            this.router.navigate(['../error']);
          }

          this.currentPayoff = response.currentPayoff;
        });
    }

    if (this.appPrequalOffer?.disposition?.type === 'CounterOffer') {
      this.requestedAmount = this.appPrequalOffer.disposition.offer.line;
    } else {
      this.requestedAmount = this.application.form.requestedAmount;
    }

    if (this.appPrequalOffer?.disposition?.offer) {
      this.counterAmount = this.appPrequalOffer.disposition.offer.line;
    } else {
      this.counterAmount = this.application.form.requestedAmount;
    }

    if (
      this.application.applicationFlow === ApplicationFlows.PartnerPrequal &&
      this.application.form.requestedAmount <
        this.application.product.stateLine.minAmount
    ) {
      this.requestedAmount = this.application.product.stateLine.minAmount;
    }

    let incomeFrequency =
      this.application.form.applicant.income.sources[0].frequency;
    incomeFrequency =
      incomeFrequency !== 'Weekly' ? incomeFrequency : 'BiWeekly';
    this.frequency = paymentFrequencyOptions.filter(
      (option: PaymentFrequencyOption) => {
        return option.value === incomeFrequency;
      }
    )[0].text;
    this.paymentScheduleSubscription = this.loanPaymentDataService
      .getPaymentScheduleSubject()
      .subscribe((schedule: PaymentOptionItem) => {
        this.selectedSchedule = schedule;
        this.csoFee = schedule.csoFee;
        this.interestAmount =
          schedule.paymentSchedule.length > 0
            ? schedule.paymentSchedule
                .map((item: PaymentScheduleItem) => item.interest)
                .reduce((prev: number, next: number) => prev + next)
            : 0;

        if (schedule.paymentSchedule.length > 0) {
          this.tealium.setTealiumVariables({
            loan_first_payment_date: moment
              .utc(schedule.paymentSchedule[0].dueDate)
              .format(),
          });
        }
      });
  }

  public async ngOnInit(): Promise<void> {
    const configModel: OriginationFeeConfiguration = {
      originationFeePercentage: 0,
      originationFee: 0,
      isloanOriginationFeeApplicable: false,
      originationFeeCapDollarAmount: 0,
      originationFeeDisplayString: '',
    };

    await this.stateOptionsApi
      .getStateOptionsAsync(this.state, 'RISE')
      .then((OnSuccess) => {
        this.originationFeePercentage = OnSuccess.originationFeePercentage;
        this.originationFeeCapDollarAmount =
          OnSuccess.originationFeeCapDollarAmount;
        this.InitializeLoanPaymentDataService();
      });

    this.loanAmountSubscription = this.loanPaymentDataService
      .getLoanAmountSubject()
      .subscribe((loanAmount: UnderwritingOfferModel) => {
        this.loanAmount = loanAmount.line;
        this.apr = loanAmount.apr;
        this.tealium.setTealiumVariables({
          loan_amount: loanAmount.line,
          loan_apr: loanAmount.apr,
        });

        // The case where origination fee is not involved, funded amount is equal to loan amount
        this.fundedAmount = this.loanAmount;

        // Origination fee is only applicable to Organic and Former flows
        // Also it is applicable only to the states that have this origination fee set
        if (
          this.originationFeePercentage != null &&
          this.originationFeePercentage != 0 &&
          (this.application.applicationFlow == ApplicationFlows.Former ||
            this.application.applicationFlow ==
              ApplicationFlows.OrganicPrequal ||
            this.application.applicationFlow ==
              ApplicationFlows.PartnerPrequal ||
            this.application.applicationFlow == ApplicationFlows.PartnerPrepop)
        ) {
          this.isloanOriginationFeeApplicable = true;
          // Orgination Fee Calculation
          this.originationFee = this.calculateOriginationFee(
            this.originationFeeConfig.originationFeeCapDollarAmount,
            this.originationFeeConfig.originationFeePercentage,
            this.loanAmount
          );

          // the amount funded is loan amount subtracted with the origination fee calculated
          this.fundedAmount = this.loanAmount - this.originationFee;

          // setting these so that these will be available for loan-amount card component
          const configModel: OriginationFeeConfiguration = {
            originationFeePercentage: this.originationFeePercentage,
            originationFee: this.originationFee,
            isloanOriginationFeeApplicable: this.isloanOriginationFeeApplicable,
            originationFeeCapDollarAmount: this.originationFeeCapDollarAmount,
            originationFeeDisplayString:
              this.originationFeePercentage.toString() + '% ',
          };
          this.originationFeeConfig = configModel;
          // this session object is set so as to be able to retrieve on Finalize Loan Page
          this.sessionStorageService.setSessionStorageObject(
            SessionStorageObjects.originationFeeConfig,
            configModel
          );
        }

        this.tealium.setTealiumVariables({
          approved_amount: this.applicationSubmitOffer.disposition.offer.line,
          loan_date: moment
            .utc(this.applicationSubmitOffer.requestedAt)
            .format(),
        });
      });

    this.sessionStorageService.setSessionStorageObject(
      SessionStorageObjects.originationFeeConfig,
      configModel
    );
    this.depositAmount = this.loanPaymentDataService.getDepositAmountSubject();
  }

  public InitializeLoanPaymentDataService(): void {
    setTimeout(() => {
      const originationFeeOnPageLoad = this.isloanOriginationFeeApplicable
        ? this.calculateOriginationFee(
            this.originationFeeCapDollarAmount,
            this.originationFeePercentage,
            this.applicationSubmitOffer.disposition.offer.line
          )
        : 0;

      this.loanPaymentDataService.initialize({
        loanAmount: this.applicationSubmitOffer.disposition.offer.line,
        apr: this.applicationSubmitOffer.disposition.offer.apr,
        requestedAmount: this.requestedAmount,
        applicationFlow: this.application.applicationFlow,
        originationFee: originationFeeOnPageLoad,
      });
    });
  }
  public ngOnDestroy(): void {
    this.paymentScheduleSubscription.unsubscribe();
    if (this.loanAmountSubscription != null) {
      this.loanAmountSubscription.unsubscribe();
    }
  }
  public submit(): void {
    if (this.maintenance.checkMaintenance()) {
      return;
    }
    const paymentSelection = {
      fundAmount: this.fundedAmount,
      loanAmount: this.loanAmount,
      apr: this.apr,
      originationFee: this.originationFee,
      originationFeePercentage: this.originationFeePercentage,
      originationFeeCapDollarAmount: this.originationFeeCapDollarAmount,
      paymentOptions: this.selectedSchedule,
    };

    this.sessionStorageService.setSessionStorageObject(
      SessionStorageObjects.selectedPaymentOption,
      paymentSelection
    );
    this.tealium.trackPageSuccessEvent('Continue-CYT-Installment-Success');
    this.router.navigate(['accept/account-setup']);
  }

  public getNOAA(): void {
    this.documentRetrievalService.getLegalDocument('noaa').subscribe(
      (fileBlob: Blob) => {
        this.showDocumentError = false;
        this.documentRetrievalService.downloadPdf(fileBlob, this.documentName);
      },
      () => {
        this.showDocumentError = true;
      }
    );
  }
  private calculateOriginationFee(
    originationFeeCap,
    originationFeePercentage,
    loanAmount
  ): number {
    // Orgination Fee Calculation
    let originationFee = (loanAmount / 100) * originationFeePercentage;
    if (originationFee > originationFeeCap) {
      originationFee = originationFeeCap;
    }
    return originationFee;
  }
}
