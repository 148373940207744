<div class="dialog-header">
  <img 
    id="closeImage" 
    class="close-img" 
    alt="close button" 
    src="assets/images/button_close.svg" 
    (click)="onNoClick()" />
</div>
<div mat-dialog-title class="dialog-header">
 <p id="modalTitleText" *ngIf="isSpecialState(monetizationData?.stateName)" >
   Hello, <rise-brand></rise-brand> is temporarily unavailable in {{monetizationData.stateName}}
  </p>
  <p id="modalTitleText" *ngIf="!isSpecialState(monetizationData?.stateName)">
    Hello, <rise-brand></rise-brand> is not currently available in {{monetizationData.stateName}}
   </p>
</div>

<mat-dialog-content class="mat-typography" *ngIf="!monetizationData.monetizationUrl">
    <div id="mainTextDivIl" *ngIf="isSpecialState(monetizationData?.stateName)">
      We are currently updating things in your state and working on a new loan product. 
      Don't worry, we'll be back! Please check back soon to see your loan options. 
    </div>
    <div id="notInStateDivIl" *ngIf="isSpecialState(monetizationData?.stateName)" (click)="notInStateClose()">
      Not in {{monetizationData.stateName}}?
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="!monetizationData.monetizationUrl">
  <div id="mainTextDivIl" *ngIf="!isSpecialState(monetizationData?.stateName)">
    The state you currently reside in is not serviced by this product.
  </div>
<div id="notInStateDivIl" *ngIf="!isSpecialState(monetizationData?.stateName)" (click)="notInStateClose()">
    Not in {{monetizationData.stateName}}?
</div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="monetizationData.monetizationUrl">
  <div id="guideToLendersLogoDiv">
    <img 
      id="guideToLendersLogoImage" 
      src="assets/images/AmOne_logo.svg"
      alt="AmOne logo" />
  </div>
  <div id="mainTextDiv">
      However, you may qualify with AmOne. Get started below
      to see if AmOne works for you.
  </div>
  <div id="disclaimerTextDiv">
      Disclaimer: <rise-brand></rise-brand> and the partner above are independent companies.
      Neither company has the authority to evaluate a credit application
      on behalf of the other. By clicking on the button below, you will
      be leaving the <rise-brand></rise-brand> website and entering the partner website.
  </div>
  <div id="visitGuideToLendersLink" (click)="redirectToGuideToLenders()">
    Visit AmOne
  </div>
  <div id="notInStateDiv" (click)="notInStateClose()">
    Not in {{monetizationData.stateName}}?
  </div>
</mat-dialog-content>
