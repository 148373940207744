import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { patternValidator } from '@app/validation/validators/pattern.validator';
import { requiredValidator } from '@app/validation/validators/required.validator';
import { DisclosureFormService } from '../../services/disclosure-form.service';

export const iAgreeAgreementTagName = 'rise-i-agree-agreement';

@Component({
  selector: iAgreeAgreementTagName,
  templateUrl: './i-agree-agreement.component.html',
  styleUrls: ['./i-agree-agreement.component.scss'],
})
export class IAgreeAgreementComponent implements AfterViewInit {
  public fieldName: string;
  public showForm = false;
  private formControl: FormControl;

  constructor(
    private el: ElementRef,
    private disclosureFormService: DisclosureFormService
  ) {
    this.fieldName = this.el.nativeElement.getAttribute('fieldName');
    this.formControl = new FormControl(null, [
      requiredValidator('Required'),
      patternValidator(/^['"]?i\s*agree['"]?$/i, `Must match "I Agree"`),
    ]);
  }

  public ngAfterViewInit(): void {
    this.disclosureFormService.addFormControl(this.fieldName, this.formControl);
    this.showForm = true;
  }

  public get form(): FormGroup {
    return this.disclosureFormService.form;
  }
}
