import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@app/environment/environment.module';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentRetrievalService {
  constructor(private environment: Environment, private http: HttpClient) {}

  public getLegalDocument(type: 'noaa' | 'rfai' | 'noia'): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
    });
    const httpOptions: any = {
      headers: headers,
      responseType: 'blob',
    };
    return this.http.get(
      `${this.environment.brandApi.url}/api/v1/document?type=${type}`,
      httpOptions
    );
  }

  public downloadPdf(fileBlob: Blob, filename: string): void {
    if (this.isBrowserIE) {
      navigator.msSaveBlob(fileBlob, filename);
    } else {
      const fileUrl = URL.createObjectURL(fileBlob);
      const aLink = document.createElement('a');
      aLink.href = fileUrl;
      aLink.download = filename;
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
    }
  }

  public get isBrowserIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }

    return false;
  }
}
