import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ApplicationFlows,
  UnderwritingOfferModel,
} from '@app/acquisition/application/application.models';
import { SpinnerModalComponent } from '@app/acquisition/shared-module/spinner-modal/spinner-modal.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PaymentOptionItem } from '../models/payment-option-item.interface';
import { PaymentOptionsResponse } from '../models/payment-options-response.interface';
import { LoanPaymentApiService } from './loan-payment-api.service';

export interface LoanPaymentInitializationData {
  loanAmount: number;
  apr: number;
  requestedAmount: number;
  applicationFlow: ApplicationFlows;
  originationFee: number;
}

export interface LoanAmountMinMax {
  minimum: number;
  maximum: number;
}

export interface PaymentOptionsState {
  currentSelection: number;
  paymentOptions: PaymentOptionItem[];
}

export interface SelectedPaymentOption {
  fundAmount: number;
  originationFee: number;
  paymentOptions: PaymentOptionItem[];
}

@Injectable({ providedIn: 'root' })
export class LoanPaymentDataService {
  private apr: number;
  private loanAmountMinMax: LoanAmountMinMax = {
    minimum: 0,
    maximum: 0,
  };
  private dialogRef: MatDialogRef<SpinnerModalComponent>;
  private paymentOptionsState: PaymentOptionsState;
  private paymentScheduleSubject: BehaviorSubject<PaymentOptionItem>;
  private loanAmountSubject: BehaviorSubject<UnderwritingOfferModel>;
  private depositAmountSubject: BehaviorSubject<number>;
  private isLoadingSubject: BehaviorSubject<boolean>;
  private defaultOptionsSubscription: Subscription;
  private additionalOptionsSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private loanPaymentApiService: LoanPaymentApiService
  ) {
    this.paymentScheduleSubject = new BehaviorSubject<PaymentOptionItem>({
      csoFee: 0,
      tilaApr: 0,
      effectiveDate: '',
      interestRate: 0,
      paymentSchedule: [],
    });
    this.loanAmountSubject = new BehaviorSubject<UnderwritingOfferModel>({
      line: 0,
      apr: 0,
    });
    this.depositAmountSubject = new BehaviorSubject<number>(0);
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.resetDisplayValues();
  }

  public initialize(initializationData: LoanPaymentInitializationData): void {
    this.loanAmountMinMax.maximum = initializationData.loanAmount;
    this.apr = initializationData.apr;
    this.updateLoanAmount(initializationData.loanAmount, initializationData.originationFee);
  }

  public updateLoanAmount(
    loanAmount: number,
    originationFee: number = 0
  ): void {
    this.resetDisplayValues();
    this.isLoadingSubject.next(true);

    this.loanAmountSubject.next({
      line: loanAmount,
      apr: this.apr,
    });

    this.dialogRef = this.dialog.open(SpinnerModalComponent, {
      panelClass: 'rise-saving-modal',
      disableClose: true,
      closeOnNavigation: false,
    });

    this.loadDefaultPaymentOptions(loanAmount, originationFee);
  }

  public updatePaymentSchedule(optionIndex: number): void {
    this.paymentOptionsState.currentSelection = optionIndex;

    this.paymentScheduleSubject.next(
      this.paymentOptionsState.paymentOptions[
        this.paymentOptionsState.currentSelection
      ]
    );
  }

  public getDepositAmountSubject(): Observable<number> {
    return this.depositAmountSubject.asObservable();
  }

  public getPaymentScheduleSubject(): Observable<PaymentOptionItem> {
    return this.paymentScheduleSubject.asObservable();
  }

  public getLoanAmountSubject(): Observable<UnderwritingOfferModel> {
    return this.loanAmountSubject.asObservable();
  }

  public getIsLoadingSubject(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  public getLoanAmountMinMax(): LoanAmountMinMax {
    return this.loanAmountMinMax;
  }

  public getPaymentOptionsState(): PaymentOptionsState {
    return this.paymentOptionsState;
  }

  public getActiveLoanData(): any {
    return this.loanPaymentApiService.getActiveLoanData();
  }

  private loadDefaultPaymentOptions(
    loanAmount: number,
    originationFee: number = 0
  ): void {
    if (
      this.additionalOptionsSubscription &&
      !this.additionalOptionsSubscription.closed
    ) {
      this.additionalOptionsSubscription.unsubscribe();
    }

    this.defaultOptionsSubscription = this.loanPaymentApiService
      .getPaymentOptions(loanAmount, 'Default', originationFee)
      .subscribe((response: PaymentOptionsResponse) => {
        this.defaultOptionsSubscription.unsubscribe();
        this.loanAmountMinMax.minimum = response.minLineAmount;
        this.setPaymentOptions(response.paymentOptions[0]);
        this.paymentOptionsState.currentSelection = 0;
        this.paymentOptionsState.paymentOptions.push(
          response.paymentOptions[0]
        );
        this.depositAmountSubject.next(response.cashAvailableForDeposit);
        this.loadAdditionalOptions(loanAmount, originationFee);
        this.dialogRef.close();
      });
  }

  private loadAdditionalOptions(
    loanAmount: number,
    originationFee: number = 0
  ): void {
    this.additionalOptionsSubscription = this.loanPaymentApiService
      .getPaymentOptions(loanAmount, 'All', originationFee)
      .subscribe((response: PaymentOptionsResponse) => {
        this.additionalOptionsSubscription.unsubscribe();
        response.paymentOptions = response.paymentOptions.filter(
          (paymentOption: PaymentOptionItem) =>
            this.paymentOptionsState.paymentOptions[0].paymentSchedule
              .length !== paymentOption.paymentSchedule.length
        );
        this.paymentOptionsState.paymentOptions.push(
          ...response.paymentOptions
        );
        this.depositAmountSubject.next(response.cashAvailableForDeposit);
        this.isLoadingSubject.next(false);
      });
  }

  private setPaymentOptions(paymentOptions: PaymentOptionItem): void {
    this.paymentScheduleSubject.next(paymentOptions);
  }

  private resetDisplayValues(): void {
    this.paymentOptionsState = {
      currentSelection: null,
      paymentOptions: [],
    };

    this.paymentScheduleSubject.next({
      csoFee: 0,
      tilaApr: 0,
      effectiveDate: '',
      interestRate: 0,
      paymentSchedule: [],
    });
  }
}
