<div class="rise-page-content">
  <div class="rise-page-container">
    <div class="rise-padded-content">
      <div class="rise-page-title" id="diclosureHeader">
        {{ applicantFirstName }}, you're almost done!
      </div>
      <p id="disclosureHeaderMessage">
        Review the
        <span
          class="learn-more-link"
          (click)="openCreditScoreModal()"
          id="openCreditScoreModalLink"
          tealiumPopup="Credit-Score-Diclosure"
          >Credit Score Disclosure</span
        >
        which provides more information about your credit score and the
        <span
          class="learn-more-link"
          (click)="openKnowBeforeModal()"
          id="openKnowBeforeModalLink"
          tealiumPopup="Know-Before-You-Borrow"
          >Know Before You Borrow Disclosure</span
        >
        that will help you understand whether <rise-brand></rise-brand> is the
        best form of credit for you.
      </p>

      <div
        class="disclosure-box"
        id="agreementDiclosure"
        (click)="openCreditAgreementModal()"
        [class.selected-credit-service]="isCreditAgreementSelected"
        tealiumPopup="Credit-Agreement"
      >
        <div class="disclosure-head">
          <div class="image-container">
            <div class="circle" *ngIf="!isCreditAgreementSelected"></div>
            <img
              id="creditAgreementDDisclosureComplete"
              *ngIf="isCreditAgreementSelected"
              src="assets/images/disclosure_complete.svg"
            />
          </div>
          <div class="disclosure-box-text">
            Read and agree to the Credit Agreement
          </div>
        </div>
        <i class="arrow-icon material-icons">arrow_forward_ios</i>
      </div>
      <div *ngIf="showCreditAgreementError" class="mat-error disclosure-error">
        Please read and agree to the above to continue.
      </div>
      <div
        class="disclosure-box"
        *ngIf="isCaliforniaState"
        id="californiaBrokerQuestion"
        (click)="openCAbrokerModal()"
      >
        <div class="disclosure-head">
          <div class="image-container">
            <div class="circle" *ngIf="!isCaBrokerQuestionSelected"></div>
            <img
              id="caBrokerDisclosureComplete"
              alt="disclosure_complete"
              *ngIf="isCaBrokerQuestionSelected"
              src="assets/images/disclosure_complete.svg"
            />
          </div>
          <div class="disclosure-box-text">
            Read and agree to the State Specific Question
          </div>
        </div>
        <em class="arrow-icon material-icons">arrow_forward_ios</em>
      </div>
      <div *ngIf="showCAbrokerError" class="mat-error disclosure-error">
        Please read and agree to the above to continue.
      </div>
      <div *ngIf="isWisconsinState" class="state-specific-box">
        <div class="bold-text">ADDITIONAL STATE SPECIFIC QUESTIONS</div>
        <p>
          We are required to ask these questions for compliance in your home
          state.
        </p>

        <div
          [formGroup]="form"
          class="state-specific-form"
          id="stateSpecificForm"
        >
          <mat-form-field color="accent" class="rise-form-field">
            <mat-select
              formControlName="maritalStatus"
              id="maritalStatusSelector"
              placeholder="Marital status"
            >
              <mat-option
                *ngFor="let option of maritalOptions"
                [value]="option.value"
              >
                {{ option.description }}
              </mat-option>
            </mat-select>

            <mat-error>
              <rise-validation-messages
                controlName="maritalStatus"
              ></rise-validation-messages>
            </mat-error>
          </mat-form-field>

          <rise-married
            *ngIf="isMarriedSelected"
            [formGroup]="form"
          ></rise-married>
        </div>
      </div>

      <div class="information-message">
        <img
          id="disclosureHardInquiryIcon"
          src="assets/images/icon_exclamation.svg"
          alt="exclamation icon"
        />
        <div id="disclosureHardInquiryMessage" class="information-text">
          Clicking Finalize below will cause a hard inquiry on your credit
          report.
        </div>
      </div>
    </div>

    <button
      mat-flat-button
      id="disclosureSubmit"
      type="submit"
      class="rise-submit-button"
      (click)="submit()"
      tealiumClick="Submit-Disclosure"
      [riseSubmitToggle]="form"
    >
      Finalize
    </button>
    <rise-side-bar></rise-side-bar>
  </div>
</div>
