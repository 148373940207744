import { Component, Input, Optional } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import {
  Rule,
  ValidationConfiguration,
} from '@validation/validation.configuration';

import { ValidationError } from './validation-error';

export function generateValidationErrorId(
  errorName: string,
  controlName: string
): string {
  const errorNameCapitalized =
    errorName?.charAt(0).toUpperCase() + errorName.slice(1);
  return `${controlName}${errorNameCapitalized}ErrorText`;
}

@Component({
  selector: 'rise-validation-messages',
  templateUrl: './validation-messages.component.html',
})
export class ValidationMessagesComponent {
  @Input() public controlName: string;

  constructor(
    @Optional() private form: FormGroupDirective,
    private validationConfiguration: ValidationConfiguration
  ) {
    if (!form) {
      throw Error(
        '<rise-validation-messages> component needs to be nested within a from group.'
      );
    }
  }

  private get control(): AbstractControl {
    return this.form.control.get(this.controlName);
  }

  public getErrorId(errorName: string): string {
    return generateValidationErrorId(errorName, this.controlNameWithoutGroup);
  }

  private get controlNameWithoutGroup(): string {
    return this.controlName.split('.').pop();
  }

  public get errors(): ValidationError[] {
    if (!this.control.errors) {
      return [];
    } else {
      const errors = this.getRequiredErrorOrAllOtherErrors();
      const visibleError = errors.find((error: ValidationError) =>
        this.shouldDisplay(error)
      );

      return visibleError ? [visibleError] : [];
    }
  }

  private getRequiredErrorOrAllOtherErrors(): ValidationError[] {
    if (this.control.errors.required) {
      return [this.control.errors.required];
    } else {
      return Object.values(this.control.errors);
    }
  }

  private shouldDisplay(error: ValidationError): boolean {
    return this.shouldShowValidationErrorsForControl(this.control, error);
  }

  private shouldShowValidationErrorsForControl(
    control: AbstractControl,
    error: ValidationError
  ): boolean {
    if (control.disabled) {
      return false;
    }

    const rules = error.rules || this.validationConfiguration.defaultRules;

    if (control instanceof FormGroup) {
      const formGroup: FormGroup = control;
      return Object.values(formGroup.controls).every(
        (nestedControl: AbstractControl) =>
          this.shouldShowValidationErrorsForControl(nestedControl, error)
      );
    } else {
      return rules.every((rule: Rule) => control[rule]);
    }
  }
}
