import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@app/environment/environment.module';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiVersionInterceptor implements HttpInterceptor {
  constructor(private environment: Environment) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.environment.brandApi.url)) {
      // add api-version header
      const headers = req.headers.set('Api-Version', `v2`);
      const dupRequest = req.clone({
        headers: headers,
      });
      return next.handle(dupRequest);
    }
    return next.handle(req);
  }
}
