import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  SessionStorageKeys,
  SessionStorageService,
} from '@app/acquisition/services/session-storage-service/session-storage.service';
import { AppConfigService } from '@app/appconfig/appconfig.service';
import { CoBrowseModalComponent } from '../cobrowse-modal/cobrowse-modal.component';
@Component({
  selector: 'rise-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  public enableCoBrowse = false;
  constructor(
    private dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private appConfigService: AppConfigService
  ) {}
  public ngOnInit(): void {}
  public ngAfterContentInit(): void {
    const sessionValueIsDisabled =
      this.sessionStorageService.getSessionStorageItem(
        SessionStorageKeys.IsCoBrowseDisabled
      );
    if (
      sessionValueIsDisabled == 'undefined' ||
      sessionValueIsDisabled == null
    ) {
      this.appConfigService.IsCoBrowseDisabled().subscribe((result) => {
        this.sessionStorageService.setSessionStorageItem(
          SessionStorageKeys.IsCoBrowseDisabled,
          result
        );
        this.enableCoBrowse = !result;
      });
    } else {
      this.enableCoBrowse = sessionValueIsDisabled == 'false' ? true : false;
    }
  }
  public openCobrowseDialog(): void {
    this.dialog.open(CoBrowseModalComponent, {
      id: 'cobrowse-dialog',
      panelClass: 'rise-modal-medium',
      closeOnNavigation: false,
      disableClose: false,
    });
  }
}
