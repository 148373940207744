import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[focusOnError]',
})
export class FocusOnErrorDirective {
  @HostListener('click', ['$event'])
  public check(): void {
    const invalidElements = document.querySelectorAll(
      'input.ng-invalid, mat-select.ng-invalid, mat-checkbox.ng-invalid, .ng-invalid'
    );
    if (invalidElements.length > 0) {
      const elm = invalidElements[0] as HTMLElement;
      elm.scrollIntoView({
        block: 'center',
      });
      elm.focus();
    }
  }
}
