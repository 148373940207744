<div class="rise-page-content">
  <div class="rise-page-container">
    <form [formGroup]="form">
      <div class="rise-padded-content">
        <div id="accountSetupTitle" class="rise-page-title">
          Set up your account
        </div>

        <mat-form-field color="accent" class="rise-form-field">
          <input matInput id="emailTextInput" formControlName="email" #email />
          <mat-label id="emailLabel">Email</mat-label>
        </mat-form-field>

        <div class="line-text-middle" *ngIf="userNameEnabled">
          <mat-form-field color="accent" class="rise-form-field">
            <input
              #userName
              matInput
              data-private
              id="userNameInput"
              data-nid-target="userName"
              formControlName="userName"
              tealiumInputFocus="UserName"
              type="userName"
              disabled="{{ !userNameEnabled }}"
            />

            <mat-label id="userNameLabel">Username</mat-label>
            <mat-error>
              <rise-validation-messages controlName="userName">
              </rise-validation-messages>
            </mat-error>
          </mat-form-field>
          <div class="userName-requirements-block">
            <p class="userName-must-message" id="userNameMustMessage">
              Username must:
            </p>
            <img
              class="confirm-icon"
              id="lengthUserNameCharacterValidationIcon"
              src="{{ getvalidationUserIcon('minLength') }}"
              alt="validation icons"
            />
            <mat-label class="icon-text" id="atleast8CharactersLabel">
              Be at least 8 characters</mat-label
            >
          </div>
          <div>
            <img
              class="confirm-icon"
              id="alphanumericCharacterValidationIcon"
              src="{{
                getvalidationUserIcon(userNameValidation.specialCharacters)
              }}"
              alt="validation icons"
            />
            <mat-label class="icon-text" id="alphanumericTextLabel">
              Must be made up of letters and numbers only</mat-label
            >
          </div>
        </div>
        <div class="password-requirements-block">
          <mat-form-field color="accent" class="rise-form-field">
            <input
              #createPassword
              matInput
              data-private
              id="createPasswordInput"
              data-nid-target="password"
              formControlName="createPassword"
              tealiumInputFocus="Create-Password"
              type="password"
              data-cobrowse-masked="true"
            />

            <mat-label id="createPasswordLabel">Create password</mat-label>
            <mat-error>
              <rise-validation-messages controlName="createPassword">
              </rise-validation-messages>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <p class="password-must-message" id="passwordMustMessage">
            Password must:
          </p>
          <img
            class="confirm-icon"
            id="lengthCharacterValidationIcon"
            src="{{ getvalidationIcon('minLength') }}"
            alt="validation icons"
          />
          <mat-label class="icon-text" id="atleast12CharactersLabel">
            Be at least 12 characters</mat-label
          >
        </div>

        <div>
          <img
            class="confirm-icon"
            id="upperCaseValidationIcon"
            src="{{ getvalidationIcon(passwordValidation.upperCase) }}"
            alt="validation icons"
          />
          <mat-label class="icon-text" id="upperCaseTextLabel">
            Include an uppercase letter</mat-label
          >
        </div>

        <div>
          <img
            class="confirm-icon"
            id="lowerCaseValidationIcon"
            src="{{ getvalidationIcon(passwordValidation.lowerCase) }}"
            alt="validation icons"
          />
          <mat-label class="icon-text" id="lowerCaseTextLabel">
            Include a lowercase letter</mat-label
          >
        </div>

        <div>
          <img
            class="confirm-icon"
            id="numberValidationIcon"
            src="{{ getvalidationIcon(passwordValidation.number) }}"
            alt="validation icons"
          />
          <mat-label class="icon-text" id="numberTextLabel">
            Include a number (0-9)</mat-label
          >
        </div>

        <div>
          <img
            class="confirm-icon"
            id="specialCharacterValidationIcon"
            src="{{ getvalidationIcon(passwordValidation.specialCharacters) }}"
            alt="validation icons"
          />
          <mat-label class="icon-text" id="specialCharacterTextLabel">
            Include a special character (ex. !@#$%^)</mat-label
          >
        </div>

        <div class="not-contain-email">
          <img
            class="confirm-icon"
            id="emailValidationIcon"
            src="{{ getEmailValidationIcon('notContainEmail') }}"
            alt="validation icons"
          />
          <mat-label class="icon-text" id="notContainEmailTextLabel">
            Not contain any part of your email address or username.</mat-label
          >
        </div>

        <mat-form-field color="accent" class="rise-form-field">
          <input
            matInput
            data-private
            id="confirmPasswordInput"
            data-nid-target="confirmPassword"
            formControlName="confirmPassword"
            tealiumInputFocus="Confirm-Password"
            type="password" 
            data-cobrowse-masked="true"
          />

          <mat-label id="confirmPasswordLabel">Confirm password</mat-label>
          <mat-error>
            <rise-validation-messages
              controlName="confirmPassword"
            ></rise-validation-messages>
          </mat-error>
        </mat-form-field>
      </div>
      <button
        mat-flat-button
        id="accountSetupContinueButton"
        type="submit"
        (click)="submit()"
        [riseSubmitToggle]="form"
        class="rise-submit-button"
        tealiumClick="Continue-Account-Setup"
      >
        Continue
      </button>
    </form>
    <rise-side-bar></rise-side-bar>
  </div>
</div>
<rise-debug-hud>
  Password: {{ mockString }} UserName: {{ defaultUserName }}
  <button class="debug-hud-password" (click)="setValues()">Set Values</button>
</rise-debug-hud>
