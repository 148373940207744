import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationApi } from '@app/acquisition/application/application.api';
import { ApplicationData } from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import {
  ManualReviewType,
  UnderwritingStatus,
  UnderwritingSteps,
} from '@app/acquisition/application/underwriting.model';
import { Environment } from '@environment/environment.module';
import { interval, Subject, timer } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import {
  UnderwritingResponseModel,
  UnderwritingService,
} from '../services/underwriting.service';
@Component({
  selector: 'rise-pendingunderwriting',
  templateUrl: './pending-underwriting-page.component.html',
  styleUrls: ['./pending-underwriting-page.component.scss'],
})
export class PendingUnderwritingPageComponent implements OnInit {
  public loadingAnimationOffset: number;
  public isAnimating = true;
  public target: string;
  public decisionUpdated: Subject<boolean> = new Subject<boolean>();
  public applicationData: ApplicationData;
  public rfaiBankConnect = false;
  private waitTimeInSeconds = 60;
  private loadingAnimationOffsetMax = 294;
  private tickIntervalInMilliseconds = 20;
  private currentCycle: number;
  private animationInterval: ReturnType<typeof setInterval>;
  constructor(
    private router: Router,
    private applicationDataService: ApplicationDataService,
    private applicationApi: ApplicationApi,
    private underwritingService: UnderwritingService,
    private environment: Environment
  ) {
    this.applicationData = this.applicationDataService.getApplication();
    const currentNavigateState =
      this.router.getCurrentNavigation().extras.state;
    if (currentNavigateState) {
      this.rfaiBankConnect = currentNavigateState.bankConnected;
    }
  }

  public ngOnInit(): void {
    if (this.rfaiBankConnect) {
      this.waitTimeInSeconds = 150;
      const timer$ = timer(
        (this.environment?.pendingUnderwritingTimeoutInMinutes || 8) * 60 * 1000
      );
      interval(5000)
        .pipe(
          switchMap(() => this.applicationApi.get()),
          takeUntil(this.decisionUpdated),
          takeUntil(timer$),
          finalize(() =>
            this.underwritingService.submitApplicationUnderwriting()
          )
        )
        .subscribe((response: ApplicationData) => {
          const trackRequest = response.trackedRequests.find(
            (entry: any) => entry.status === 'Complete' && entry.name === '199'
          );
          if (trackRequest) {
            this.decisionUpdated.next(true);
          }
        });
    } else if (
      !this.underwritingService.isUnderwritingProcessing &&
      !this.underwritingService.hasUnderwritingCompleted
    ) {
      this.underwritingService.submitApplicationUnderwriting();
    }

    this.startAnimation();
    this.underwritingService.underwritingDecisionSubject.subscribe(
      (response: UnderwritingResponseModel) => {
        this.underwritingService.underwritingDecisionSubject.complete();
        const application = this.buildUnderwriting(response);
        const fullSubmitIndex =
          this.applicationData.underwriting.steps.findIndex(
            (decision: any) => decision.name === 'ApplicationSubmit'
          );

        if (fullSubmitIndex !== -1) {
          this.applicationData.underwriting.steps[fullSubmitIndex] =
            application.underwriting.steps[0];
        } else {
          this.applicationData.underwriting.steps.push(
            application.underwriting.steps[0]
          );
        }
        this.router.navigate([this.getUrlTarget(response)]);
      },
      (error: any) => {
        this.router.navigate(['../error']);
      }
    );
  }

  public buildUnderwriting(response: UnderwritingResponseModel): any {
    return {
      underwriting: {
        steps: [
          {
            name: UnderwritingSteps.ApplicationSubmit,
            status: response.underwrite.status,
            disposition: response.underwrite.disposition,
          },
        ],
        decisions: [],
      },
    };
  }

  private getUrlTarget(response: UnderwritingResponseModel): string {
    let target: string;
    if (response.status === UnderwritingStatus.Approved) {
      target = '../accept/cyt-installment';
    } else if (response.status === UnderwritingStatus.Declined) {
      // Showing a separate verbiage for Credit Freeze(226) and TechnicalError(239)
      if (
        response.underwrite.disposition.noaa?.code == '226' ||
        response.underwrite.disposition.noaa?.code == '239'
      ) {
        target = '../accept/notice-of-incomplete-action';
      } else {
        target = '../accept/app-decline-offer';
      }
    } else if ('agentReviewType' in response.requiredBeforeNextUnderwrite) {
      switch (response.requiredBeforeNextUnderwrite.agentReviewType) {
        case ManualReviewType.OFACTA:
          target = '../accept/app-review-phase';
          break;
        case ManualReviewType.FACTA:
          target = '../accept/app-review-page';
          break;
        case ManualReviewType.MLA:
          target = '../accept/app-review-phase';
          break;
        case ManualReviewType.BANKVerification:
          target = '../accept/app-review-step';
          break;
        case ManualReviewType.CVS:
          target = '../accept/app-review-phase';
          break;
      }
    } else if (
      response.requiredBeforeNextUnderwrite.additionalInformation.length > 0
    ) {
      if (
        response.requiredBeforeNextUnderwrite.additionalInformation[0] === '199'
      ) {
        if (this.applicationData.form.applicant.bank.connectionResourceUrl) {
          target = '../error';
        } else {
          target = '../accept/additional-bank-info';
        }
      } else {
        target = '../accept/additional-info';
      }
    } else {
      target = '../error';
    }

    return target;
  }

  private startAnimation(): void {
    this.isAnimating = true;
    this.currentCycle = 0;
    this.animationInterval = setInterval(
      this.advanceAnimation.bind(this),
      this.tickIntervalInMilliseconds
    );
  }

  private advanceAnimation(): void {
    this.currentCycle += 1;
    const perc =
      1 -
      (this.currentCycle * this.tickIntervalInMilliseconds) /
        (this.waitTimeInSeconds * 1000);
    if (perc <= 0) {
      clearInterval(this.animationInterval);
      this.isAnimating = false;
    } else {
      this.loadingAnimationOffset = Math.round(
        this.loadingAnimationOffsetMax * perc
      );
    }
  }
}
