<div class="cobrowse-dialog purecloud-webchat-security-key-prompt">
  <div id="cobrowseTitle" class="cobrowse-title-header">
    <div class="title">
      To begin Co-Browse, call Customer Care at
      <span class="phone">833-419-3388</span> to receive your Security Key to enter below.
   
    </div>
    <div class="cobrowse-close">
      <img
        alt="Close"
        src="assets/images/button_close.svg"
        (click)="closeModal()"
      />
    </div>
  </div>
  <mat-dialog-content id="coborowseContainer" class="mat-dialog-content">
    <div class="acknowledge">
      <p>
        By clicking Share Your Screen, you acknowledge and agree for
        RISEcredit.com to record your co-browse session for training and
        customer support purposes. RISEcredit.com will not have access to your
        device or personal information and the recorded co-browse session will
        remain private and secure. Please note that RISEcredit.com cannot see
        your screen once you leave RISEcredit.com.
      </p>
    </div>
    <div id="cobrowse-security-form" class="cobrowse-container"></div>
  </mat-dialog-content>
</div>
