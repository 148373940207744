import { Directive, HostBinding, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  Rule,
  ValidationConfiguration,
} from '@validation/validation.configuration';

@Directive({ selector: '[formControlName]' })
export class ValidationInputClassDirective {
  constructor(
    @Self() private control: NgControl,
    private configuration: ValidationConfiguration
  ) {}

  @HostBinding('class.invalid')
  public get invalid(): boolean {
    return (
      this.control.invalid &&
      this.configuration.defaultRules.every((rule: Rule) => this.control[rule])
    );
  }
}
