<div id="debugHudContainer" class="debug-hud-container" *ngIf="!isProduction">
  <div id="debugHudContent" class="debug-hud-content" [hidden]="isHudCollapsed">
    <h5 id="debugHudHeading">DEBUG HUD</h5>

    <div class="mt-3">
      <p id="debugHudVisitorId">Visitor ID: {{ visitorId }}</p>
    </div>

    <ng-content></ng-content>
  </div>

  <div id="debugHudTab" class="debug-hud-tab" (click)="toggle()">
    <i
      class="text-white fa"
      [ngClass]="isHudCollapsed ? 'fa-caret-right' : 'fa-caret-left'"
    ></i>
  </div>
</div>
