import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[riseSubmitToggle]',
})
export class SubmitToggleDirective implements OnInit, OnDestroy {
  @Input('riseSubmitToggle') public formGroup: FormGroup;

  private hostElement: ElementRef;
  private formSubscription: Subscription;

  constructor(el: ElementRef) {
    this.hostElement = el;
  }

  public ngOnInit(): void {
    this.hostElement.nativeElement.style.opacity = this.formGroup.valid
      ? 1
      : 0.6;

    this.formSubscription = this.formGroup.statusChanges.subscribe(
      (status: any) => {
        this.hostElement.nativeElement.style.opacity =
          status === 'VALID' ? 1 : 0.6;
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
