import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StateLicenseModalComponent } from './state-license-modal/state-license-modal.component';
import { StateLicenseComponent } from './state-license.component';

@NgModule({
  imports: [CommonModule],
  declarations: [StateLicenseComponent, StateLicenseModalComponent],
  exports: [StateLicenseComponent],
  entryComponents: [StateLicenseModalComponent],
})
export class StateLicensesModule {}
