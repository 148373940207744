import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const maxLengthValidatorName = 'maxLength';

export function maxLengthValidator(
  maxLength: number,
  text: string = 'Invalid Entry',
  rules?: Rule[]
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors {
    const matchesLength =
      (typeof control.value === 'number' ||
        typeof control.value === 'string') &&
      control.value.toString().length <= maxLength;

    if (matchesLength) {
      return null;
    } else {
      return new ValidationMessagesError(maxLengthValidatorName, rules, text);
    }
  };
}
