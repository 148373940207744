import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationData,
  ApplicationStates,
} from '@app/acquisition/application/application.models';
import { ApplicationApi } from '@application/application.api';
import { interval, Subject, timer } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationDataService } from '../../application/application.service';

@Component({
  selector: 'rise-booking-loan-component',
  templateUrl: './booking-loan.component.html',
  styleUrls: ['./booking-loan.component.scss'],
})
export class BookingLoanComponent implements OnInit {
  public loadingAnimationOffset: number;
  public isAnimating = true;
  public signAgreementCompleted = new Subject<boolean>();
  public signedAgreement = false;

  private loadingAnimationOffsetMax = 294;
  private currentCycle: number;
  private animationInterval: ReturnType<typeof setInterval>;
  private tickIntervalInMilliseconds = 20;
  private waitTimeInSeconds = 30;

  constructor(
    private applicationApi: ApplicationApi,
    private router: Router,
    private applicationDataService: ApplicationDataService
  ) {}

  public ngOnInit(): void {
    this.pollForSignAgreement();
    this.startAnimation();
  }

  private pollForSignAgreement(): void {
    const timer$ = timer(120000);
    interval(5000)
      .pipe(
        switchMap(() => this.applicationApi.get()),
        takeUntil(this.signAgreementCompleted),
        takeUntil(timer$),
        finalize(() => {
          this.signedAgreement
            ? this.router.navigate(['accept/finalize-loan'])
            : this.router.navigate(['../error']);
        })
      )
      .subscribe((application: ApplicationData) => {
        if (application.state === ApplicationStates.Booked) {
          this.signedAgreement = true;
          this.signAgreementCompleted.next(true);
          clearInterval(this.animationInterval);
          this.applicationDataService.merge(application);
        }
      });
  }

  private startAnimation(): void {
    this.isAnimating = true;
    this.currentCycle = 0;
    this.animationInterval = setInterval(
      this.advanceAnimation.bind(this),
      this.tickIntervalInMilliseconds
    );
  }

  private advanceAnimation(): void {
    this.currentCycle += 1;
    const perc =
      1 -
      (this.currentCycle * this.tickIntervalInMilliseconds) /
        (this.waitTimeInSeconds * 1000);
    if (perc <= 0) {
      clearInterval(this.animationInterval);
      this.isAnimating = false;
    } else {
      this.loadingAnimationOffset = Math.round(
        this.loadingAnimationOffsetMax * perc
      );
    }
  }
}
