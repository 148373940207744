import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NonDecimalCurrencyPipe } from './non-decimal-currency.pipe';

@Directive({
  selector: '[riseInputCurrencyFormatter]',
})
export class InputCurrencyFormatterDirective implements OnInit, OnDestroy {
  @Input() public isRequestedAmount: boolean;
  private element: HTMLInputElement;
  private incrementControl: NgControl;
  private maskInitialValueSubscription: Subscription;

  private readonly PREFIX = '$';
  private readonly DECIMAL_SEPARATOR = '.';
  private readonly THOUSANDS_SEPARATOR = ',';
  private readonly SUFFIX = '';
  private readonly PADDING = '000000';
  private incrementedValue: string;

  constructor(
    private elementRef: ElementRef,
    private nonDecimalCurrencyPipe: NonDecimalCurrencyPipe,
    private control: NgControl
  ) {
    this.element = this.elementRef.nativeElement;
    this.incrementControl = this.control;
  }

  public ngOnInit(): void {
    this.element.value = this.mask(this.element.value);

    this.maskInitialValueSubscription = this.control.valueChanges
      .pipe(take(1))
      .subscribe(() => {
        if (this.control.pristine) {
          this.element.value = this.mask(this.element.value);
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.maskInitialValueSubscription) {
      this.maskInitialValueSubscription.unsubscribe();
    }
  }

  @HostListener('focus', ['$event.target.value'])
  public onFocus(value: string): void {
    this.element.value = this.unmask(value); // opposite of transform
  }

  @HostListener('blur', ['$event.target.value'])
  public onBlur(value: string): void {
    this.element.value = this.mask(value);
  }

  public mask(value: string): string {
    try {
      if (this.isRequestedAmount) {
        this.incrementedValue = String(Math.ceil(Number(value) / 50) * 50);
        this.incrementControl.control.setValue(this.incrementedValue);
      } else {
        this.incrementedValue = value;
      }
      return this.nonDecimalCurrencyPipe.transform(this.incrementedValue);
    } catch (error) {
      return this.incrementedValue;
    }
  }

  public unmask(value: string, fractionSize: number = 2): string {
    let [integer, fraction = ''] = (value || '')
      .replace(this.PREFIX, '')
      .replace(this.SUFFIX, '')
      .split(this.DECIMAL_SEPARATOR);

    integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');

    fraction =
      parseInt(fraction, 10) > 0 && fractionSize > 0
        ? this.DECIMAL_SEPARATOR +
          (fraction + this.PADDING).substring(0, fractionSize)
        : '';

    return integer + fraction;
  }
}
