import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { DynamicHTMLRef, DynamicHTMLRenderer } from './dynamic-html.renderer';

@Component({
  selector: 'rise-dynamic-html',
  template: '',
})
export class DynamicHTMLComponent implements DoCheck, OnChanges, OnDestroy {
  @Input() public content: string;
  private ref: DynamicHTMLRef = null;

  constructor(
    private renderer: DynamicHTMLRenderer,
    private elementRef: ElementRef
  ) {}

  public ngDoCheck(): void {
    if (this.ref) {
      this.ref.check();
    }
  }

  public ngOnChanges(_: SimpleChanges): void {
    if (this.ref) {
      this.ref.destroy();
      this.ref = null;
    }
    if (this.content && this.elementRef) {
      this.ref = this.renderer.renderInnerHTML(this.elementRef, this.content);
    }
  }

  public ngOnDestroy(): void {
    if (this.ref) {
      this.ref.destroy();
      this.ref = null;
    }
  }
}
