import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const inputMatchValidatorName = 'inputMatch';

export function inputMatchValidator(
  targetControlPath: string,
  text: string = `Inputs do not match`,
  rules?: Rule[]
): ValidatorFn {
  let initializing = true;

  return function (control: AbstractControl): ValidationErrors {
    const controlTreeStructureNotInitialized = control === control.root;

    if (controlTreeStructureNotInitialized) {
      return null;
    }

    const targetControl = control.root.get(targetControlPath);

    if (!targetControl) {
      throw new Error(
        `Input Match Validator -- no control found for control path ${targetControlPath}`
      );
    }

    if (initializing) {
      setValidatorToTargetControl(control, targetControl);
      initializing = false;
    }

    if (targetControl.value !== control.value) {
      return new ValidationMessagesError(inputMatchValidatorName, rules, text);
    } else {
      return null;
    }
  };

  function setValidatorToTargetControl(
    control: AbstractControl,
    target: AbstractControl
  ): void {
    const validator = target.validator
      ? [target.validator, targetValidator.bind(null, control)]
      : targetValidator.bind(null, control);

    target.setValidators(validator);
  }

  function targetValidator(control: AbstractControl): ValidationErrors {
    control.updateValueAndValidity();
    return null;
  }
}
