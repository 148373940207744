import { AppInsightService } from '@acquisition/services/app-insights/app-insights.service';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ApplicationForm,
  MaritalStatusModel,
  MarriedSpouseModel,
} from '@app/acquisition/application/application.models';
import { MaintenanceService } from '@app/acquisition/maintenance/services/maintenance.service';
import {
  SessionStorageObjects,
  SessionStorageService,
} from '@app/acquisition/services/session-storage-service/session-storage.service';
import { SpinnerModalComponent } from '@app/acquisition/shared-module/spinner-modal/spinner-modal.component';
import { Tealium } from '@app/tealium/tealium.service';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import {
  Disclosure,
  DisclosuresRetrievalService,
  disclosureTypes,
} from '../../services/disclosures-retrieval-service/disclosures-retrieval-service';
// tslint:disable-next-line: max-line-length
import { DisclosureModalComponent } from '../../shared-module/disclosure-module/disclosure-module/components/disclosure-modal/disclosure-modal.component';
import { PaymentOptionItem } from '../models/payment-option-item.interface';
import { LoanPaymentApiService } from '../services/loan-payment-api.service';
import { CAbrokerModalComponent } from './ca-broker-modal/ca-broker-modal.component';
import { DisclosuresFormGroup, maritalOptions } from './disclosures.form';
import { Married } from './married-form/married.form';

@Component({
  selector: 'rise-app-cyt-disclosures',
  templateUrl: './disclosures.component.html',
  styleUrls: ['./disclosures.component.scss'],
  providers: [DisclosuresFormGroup],
})
export class DisclosuresComponent implements OnInit {
  public isCaliforniaState = false;
  public isWisconsinState = false;
  public isCreditAgreementSelected = false;
  public showCreditAgreementError = false;
  public isCaBrokerQuestionSelected = false;
  public showCAbrokerError = false;
  public applicantFirstName: string;
  public maritalOptions = maritalOptions;
  public selectedPaymentOption: PaymentOptionItem;
  public modalBody: string;
  public creditAgreementDisclosures;
  public application: ApplicationForm;

  private knowbeforeYouBorrowHtml: Disclosure;
  private creditScoreDisclosureHtml: Disclosure;
  private dialogRef: MatDialogRef<SpinnerModalComponent>;
  private previousSignedDisclosures;

  public get isMarriedSelected(): boolean {
    return Boolean(
      this.form.get('maritalStatus').value === MaritalStatusModel.Married
    );
  }
  public get maritalStatus(): MaritalStatusModel {
    return this.form.get('maritalStatus').value;
  }

  constructor(
    public form: DisclosuresFormGroup,
    private applicationApi: ApplicationApi,
    private dialog: MatDialog,
    private applicationDataService: ApplicationDataService,
    private loanPaymentService: LoanPaymentApiService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private disclosureRetrievalService: DisclosuresRetrievalService,
    private tealium: Tealium,
    private maintenance: MaintenanceService,
    private appInsightsService: AppInsightService
  ) {}

  public ngOnInit(): void {
    const app = this.applicationDataService.getApplication();
    try {
      this.application = this.applicationDataService.get();

      this.previousSignedDisclosures = this.application.disclosures;

      const stateCode =
        this.application.applicant.residences[0].address.stateCode;
      this.applicantFirstName = this.application.applicant.identity.firstName;

      if (!this.applicantFirstName) {
        this.appInsightsService.trackEvent(
          'DisclosuresComponent.Init.MissingApplicantName',
          { applicationId: app?.id }
        );
      }

      this.dialogRef = this.dialog.open(SpinnerModalComponent, {
        panelClass: 'rise-saving-modal',
        disableClose: true,
        closeOnNavigation: false,
        data: 'Loading',
      });

      const loanPayment = this.loanPaymentService.getLoanAgreement(
        this.sessionStorageService.getSessionStorageObject(
          SessionStorageObjects.selectedPaymentOption
        )
      );

      const disclosures =
        this.disclosureRetrievalService.getAcceptanceDisclosures();

      forkJoin([loanPayment, disclosures]).subscribe(
        (responses: any) => {
          this.modalBody = responses[0];

          this.knowbeforeYouBorrowHtml = responses[1].disclosures.find(
            (disclosure: Disclosure) =>
              disclosure.name === disclosureTypes.KnowBeforeYouBorrow
          );

          this.creditScoreDisclosureHtml = responses[1].disclosures.find(
            (disclosure: Disclosure) =>
              disclosure.name === disclosureTypes.CreditScoreDisclosure
          );

          this.dialogRef.close();
        },
        () => {
          this.dialogRef.close();
          this.router.navigate(['../error']);
        }
      );

      if (stateCode === 'CA') {
        this.isCaliforniaState = true;
        this.form.appendCaliforniaDisclosures();
      }

      if (stateCode === 'WI') {
        this.isWisconsinState = true;
        this.form.appendMarriedStatus();
      }
    } catch (e) {
      this.appInsightsService.trackEvent(
        'DisclosuresComponent.Init.ErrorDuringInit',
        {
          applicationId: app?.id,
          exception: e,
        }
      );
      throw e;
    }
  }

  public submit(): void {
    if (this.maintenance.checkMaintenance()) {
      return;
    }
    this.form.showValidationErrors();

    if (!this.form.valid) {
      this.showCreditAgreementError = !this.isCreditAgreementSelected;
      if (this.isCaliforniaState) {
        this.showCAbrokerError = !this.isCaBrokerQuestionSelected;
      }
      return;
    }

    const application: ApplicationForm = {
      disclosures: this.creditAgreementDisclosures,
    };
    if (this.isCaliforniaState) {
      application.disclosures.push({
        key: 'CaliforniaBrokerAgreement',
        consentGiven: true,
      });
    }

    this.dialogRef = this.dialog.open(SpinnerModalComponent, {
      panelClass: 'rise-saving-modal',
      disableClose: true,
      closeOnNavigation: false,
      data: 'Loading',
    });

    if (this.isWisconsinState) {
      if (this.application.applicant.marital) {
        const payload = {
          sections: [
            {
              path: 'applicant.marital',
              value: this.constructMaritalObject(),
            },
          ],
        };
        this.applicationApi
          .replace(payload)
          .subscribe((replaceResponse: HttpResponse<any>) => {});
      } else {
        application.applicant = {
          marital: this.constructMaritalObject(),
        };
      }
    }

    this.applicationApi
      .append(application)
      .subscribe((appendResponse: HttpResponse<any>) => {
        if (appendResponse.status === 204) {
          this.applicationDataService.merge({ form: application });

          this.loanPaymentService
            .signLoanAgreement(
              this.sessionStorageService.getSessionStorageObject(
                SessionStorageObjects.selectedPaymentOption
              )
            )
            .subscribe(
              (signAgreementResponse: HttpResponse<any>) => {},
              (error: any) => {
                this.applicationApi
                  .replace({
                    sections: [
                      {
                        path: 'disclosures',
                        value: [...this.previousSignedDisclosures],
                      },
                    ],
                  })
                  .subscribe((replaceResponse: HttpResponse<any>) => {});
              }
            );

          this.tealium.setTealiumVariables({
            application_complete_date: moment.utc().format(),
          });

          this.tealium.trackPageSuccessEvent('Submit-Disclosure-Success');

          this.dialogRef.close();
          this.router.navigate(['accept/booking-loan']);
        }
      });
  }

  public openCreditAgreementModal(): void {
    this.showCreditAgreementError = false;
    if (this.modalBody) {
      const commentDialogRef = this.dialog.open(DisclosureModalComponent, {
        panelClass: 'rise-modal-medium',
        autoFocus: false,
        disableClose: true,
        data: {
          body: this.modalBody,
          title: 'Credit Agreement',
          scrollToSign: true,
          savePrintBtn: true,
          data: this.creditAgreementDisclosures,
        },
      });

      commentDialogRef.afterClosed().subscribe((data: any) => {
        if (data && data.valid) {
          this.creditAgreementDisclosures = data.disclosures;
          this.isCreditAgreementSelected = true;
          this.form.get('creditAgreementDisclosure').setValue(true);
        } else {
          this.isCreditAgreementSelected = false;
          this.form.get('creditAgreementDisclosure').setValue(null);
        }
      });
    }
  }

  public openCreditScoreModal(): void {
    this.dialog.open(DisclosureModalComponent, {
      panelClass: 'rise-modal-medium',
      autoFocus: false,
      data: {
        title: 'Credit Score Diclosure',
        body: this.creditScoreDisclosureHtml.html,
        continueCancelBtns: false,
        savePrintBtn: true,
      },
    });
  }

  public openKnowBeforeModal(): void {
    this.dialog.open(DisclosureModalComponent, {
      panelClass: 'rise-modal-medium',
      autoFocus: false,
      data: {
        title: 'Know Before You Borrow',
        body: this.knowbeforeYouBorrowHtml.html,
        continueCancelBtns: false,
        savePrintBtn: true,
      },
    });
  }

  public openCAbrokerModal(): void {
    const brokerModalRef = this.dialog.open(CAbrokerModalComponent, {
      panelClass: 'rise-modal-medium',
      autoFocus: false,
      data: {
        isCaBrokerQuestionSelected: this.isCaBrokerQuestionSelected,
      },
    });

    brokerModalRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.isCaBrokerQuestionSelected = true;
        this.form.get('caBrokerQuestion').setValue(true);
      } else {
        this.isCaBrokerQuestionSelected = false;
        this.form.get('caBrokerQuestion').setValue(null);
      }
    });
  }

  private constructMaritalObject(): MarriedSpouseModel {
    const maritalStatus: MarriedSpouseModel = {
      status: this.maritalStatus,
    };
    if (this.isMarriedSelected) {
      const married: Married = this.form.get('married').value;

      maritalStatus.spouseFirstName = married.spouseFirstName;
      maritalStatus.spouseLastName = married.spouseLastName;
      maritalStatus.spouseEmailAddress = this.form.get(
        'married.spouseEmailAddress'
      ).value;
    }
    return maritalStatus;
  }
}
