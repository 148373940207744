import { Optional } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { UnderwritingService } from '@app/acquisition/apply/services/underwriting.service';
import { SessionStorageService } from '@app/acquisition/services/session-storage-service/session-storage.service';
import { InactivityModalComponent } from '@app/acquisition/shared-module/inactivity-module/inactivity-modal/inactivity-modal.component';
import { Environment } from '@app/environment/environment.module';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class UserInactivityStatusService {
  public dialogRef: MatDialogRef<InactivityModalComponent>;
  public idleTimeOutStatusObservable: Observable<any>;

  private timeoutInSeconds: number;
  private idleInSeconds: number;

  private idleTimeOutStatus = new Subject<any>();

  private runIdle = new BehaviorSubject<boolean>(true);
  private runIdleObservable = this.runIdle.asObservable();

  constructor(
    private idle: Idle,
    private router: Router,
    private dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private enviroment: Environment,
    private underwritingService: UnderwritingService,
    private appDataService: ApplicationDataService,
    @Optional() @Inject('RedirectRoute') private redirectRoute: string,
    @Optional()
    @Inject('RedirectIsExternal')
    private redirectIsExternal: boolean
  ) {
    this.timeoutInSeconds = this.enviroment.inactivity.timeout;
    this.idleInSeconds = this.enviroment.inactivity.idle;
    this.idleTimeOutStatusObservable = this.idleTimeOutStatus.asObservable();
    this.redirectRoute = this.redirectRoute ?? 'getting-started';

    this._init();
  }

  public _init(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.setIdleTimeConfigurations();

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      if (this.dialogRef === undefined) {
        this.idleTimeOutStatus.next();
        this.dialogRef = this.dialog.open(InactivityModalComponent, {
          panelClass: 'rise-modal-small',
          disableClose: true,
        });
        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = undefined;
        });
      }
    });

    this.idle.onTimeout.subscribe(() => {
      this.dialog.closeAll();
      this.sessionStorageService.clearAllSessionStorage();
      this.underwritingService.resetDecision();
      this.appDataService.resetApplication();
      this.router.navigated = false;
      this.redirectTimeout();
    });

    this.runIdleObservable.subscribe((userStartedApp: Boolean) => {
      userStartedApp ? this.idle.watch() : this.idle.stop();
    });
  }

  public setIdleTimeConfigurations(
    idle: number = this.idleInSeconds,
    timeout: number = this.timeoutInSeconds
  ): void {
    this.idle.setIdle(idle);
    this.idle.setTimeout(timeout);
  }

  public pingInterruptionToIdle(): void {
    this.idle.interrupt();
  }

  public setUserApplicationStatus(userStartedApp: boolean): void {
    this.runIdle.next(userStartedApp);
  }

  private redirectTimeout(): void {
    if (this.redirectIsExternal) {
      window.location.assign(this.redirectRoute);
    } else {
      this.router.navigate([this.redirectRoute]);
    }
  }
}
