import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@app/forms/page-form-group/page-form-group';
import { requiredValidator } from '@validation/validators/required.validator';

export interface AccountNotPermittedModalForm {
  account: string;
}

@Injectable()
export class AccountNotPermittedModalFormGroup extends PageFormGroup {
  public value: AccountNotPermittedModalForm;

  constructor() {
    super({
      account: new FormControl(null, [
        requiredValidator('Please select a checking account.'),
      ]),
    });
  }
}
