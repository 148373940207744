import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {
  ApplicationData,
  UnderwritingDecisionEnum,
} from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { UnderwritingSteps } from '@app/acquisition/application/underwriting.model';
import { CookieService } from '@app/cookie/cookie-service';
import { Tealium } from '../../../tealium/tealium.service';
import {
  UnderwritingResponseModel,
  UnderwritingService,
  UnderwritingStepEnums,
} from '../services/underwriting.service';

@Component({
  selector: 'rise-app-checking-options',
  templateUrl: './checking-options.component.html',
  styleUrls: ['./checking-options.component.scss'],
})
export class CheckingOptionsComponent implements OnInit {
  public loadingMessage = 'Loading....';
  public loadingAnimationOffset: number;
  public isAnimating = true;
  public navigationExtras: NavigationExtras;
  private waitTimeInSeconds = 18;
  private loadingAnimationOffsetMax = 294;
  private tickIntervalInMilliseconds = 20;
  private currentCycle: number;
  private animationInterval: ReturnType<typeof setInterval>;

  constructor(
    private underwritingApi: UnderwritingService,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private cookieService: CookieService,
    private tealium: Tealium
  ) {}

  public ngOnInit(): void {
    const application: ApplicationData =
      this.applicationDataService.getApplication();
    this.startAnimation();
    this.underwritingApi.underwrite(UnderwritingStepEnums.prequal).subscribe(
      (underwritingResponse: UnderwritingResponseModel) => {
        const underwritingType =
          underwritingResponse.underwrite.disposition.type;

        if (underwritingType === UnderwritingDecisionEnum.Offer) {
          this.tealium.setTealiumVariables({
            pre_qual_amount: underwritingResponse.offer.line,
          });
        }

        if (underwritingType === UnderwritingDecisionEnum.CounterOffer) {
          this.tealium.setTealiumVariables({
            counter_offer_amount: underwritingResponse.offer.line,
          });
        }

        const applicationUnderwriting: ApplicationData =
          this.convertToApplicationData(underwritingResponse);

        this.applicationDataService.mergeArray({
          ...applicationUnderwriting,
        });
        this.cookieService.set('resumeByAppID', application.id, {
          expires: 30,
        });
        this.router.navigate(
          [
            this.determineDecisionPage(
              underwritingResponse.underwrite.disposition
            ),
          ],
          this.navigationExtras
        );
      },
      () => {
        this.router.navigate(['../connect-bank']);
      }
    );
  }

  private startAnimation(): void {
    this.isAnimating = true;
    this.currentCycle = 0;
    this.animationInterval = setInterval(
      this.advanceAnimation.bind(this),
      this.tickIntervalInMilliseconds
    );
  }

  private advanceAnimation(): void {
    this.currentCycle += 1;
    const perc =
      1 -
      (this.currentCycle * this.tickIntervalInMilliseconds) /
        (this.waitTimeInSeconds * 1000);
    if (perc <= 0) {
      clearInterval(this.animationInterval);
      this.isAnimating = false;
    } else {
      this.loadingAnimationOffset = Math.round(
        this.loadingAnimationOffsetMax * perc
      );
    }
  }

  private convertToApplicationData(
    underwritingPrequalData: UnderwritingResponseModel
  ): ApplicationData {
    const appData: ApplicationData = {
      mostRecentDecision: underwritingPrequalData.underwrite.disposition.type,
      underwriting: {
        steps: [
          {
            name: UnderwritingSteps.Prequal,
            status: underwritingPrequalData.underwrite.status,
            disposition: underwritingPrequalData.underwrite.disposition,
          },
        ],
        decisions: [],
      },
    };

    if (underwritingPrequalData.offer) {
      appData.offer = underwritingPrequalData.offer;
    }

    return appData;
  }

  private determineDecisionPage(disposition: any): any {
    let targetUrl: any;
    this.navigationExtras = {};
    switch (disposition.type) {
      case UnderwritingDecisionEnum.Offer:
        targetUrl = '../pre-qualification-approval';
        break;
      case UnderwritingDecisionEnum.Decline: {
        disposition.noaa?.code == '226' || disposition.noaa?.code == '239'
          ? (targetUrl = '../accept/notice-of-incomplete-action')
          : (targetUrl = '../pre-qualification-decline');

        break;
      }
      case UnderwritingDecisionEnum.CounterOffer:
        targetUrl = '../pre-qualification-counter-offer';
        break;
      case UnderwritingDecisionEnum.Continue:
        targetUrl = '../connect-bank';
        break;
      case UnderwritingDecisionEnum.NoOffer:
        if (disposition.reason === 'LenderChange') {
          this.navigationExtras = { state: { lenderChange: true } };
          targetUrl = '../connect-bank';
        } else {
          targetUrl = '../connect-bank-more-info';
        }
        break;
      default:
        targetUrl = '../connect-bank-more-info';
        break;
    }
    return targetUrl;
  }
}
