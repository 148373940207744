import { FormControl, FormGroup } from '@angular/forms';
import { emailValidator } from '@validation/validators/email.validator';
import { minLengthValidator } from '@validation/validators/min-length.validator';
import { patternValidator } from '@validation/validators/pattern.validator';
import { requiredValidator } from '@validation/validators/required.validator';

export const marriedFormGroupName = 'married';

export interface Married {
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseEmailAddress?: string;
  spouseEmailIsTheSame?: boolean;
}
export class MarriedFormGroup extends FormGroup {
  public value: Married;

  constructor() {
    super({
      spouseFirstName: new FormControl(null, [
        requiredValidator('Please enter first name.'),
        minLengthValidator(2, 'Field requires between 2 and 50 characters.'),
        patternValidator(
          /^[a-zA-Z"'\- ]+$/,
          'Alphabetical characters only please.'
        ),
      ]),
      spouseLastName: new FormControl(null, [
        requiredValidator('Please enter last name.'),
        minLengthValidator(2, 'Field requires between 2 and 50 characters.'),
        patternValidator(
          /^[a-zA-Z."'\- ]+$/,
          'Alphabetical characters only please.'
        ),
      ]),
      spouseEmailAddress: new FormControl(null, [
        requiredValidator('Please enter email address.'),
        emailValidator('Please enter full email address.'),
        patternValidator(
          /^[^\x80-\xFF]*$/,
          'Please enter valid email address.'
        ),
      ]),
    });
  }
}
