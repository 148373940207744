import { Component } from '@angular/core';

@Component({
  selector: 'rise-ip-threshold-modal',
  templateUrl: './ip-threshold-modal.component.html',
  styleUrls: ['./ip-threshold-modal.component.scss'],
})
export class IPThresholdModalComponent {
  constructor() {}
}
