import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@app/environment/environment.module';
import { Observable } from 'rxjs';

export interface CheckIdentityResponse {
  userExists: boolean;
}

@Injectable()
export class IdentityApiService {
  constructor(private environment: Environment, private http: HttpClient) {}

  public createIdentity(
    password: string,
    username: string
  ): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/create-identity`,
      { password: password, userName: username },
      {
        observe: 'response',
      }
    );
  }

  public checkIdentity(): Observable<CheckIdentityResponse> {
    return this.http.get<CheckIdentityResponse>(
      `${this.environment.brandApi.url}/api/v1/check-identity`
    );
  }
}
