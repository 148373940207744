import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ReCaptchaConfiguration } from './recaptcha.configuration';

@Injectable()
export class ReCaptchaService {
  private grecaptcha: any;
  private callback: Subject<string>;

  constructor(private configuration: ReCaptchaConfiguration) {
    this.grecaptcha = window['grecaptcha'];
  }

  public initialize(): void {
    this.callback = new Subject();
  }

  public execute(): Observable<any> {
    this.grecaptcha.execute();
    return this.callback.asObservable();
  }

  public render(element: HTMLElement): void {
    this.grecaptcha.ready(() => {
      this.grecaptcha.render(element, {
        sitekey: this.configuration.siteKey,
        size: 'invisible',
        badge: 'bottomright',
        callback: (token: string) => {
          this.callback.next(token);
          this.callback.complete();
        },
      });
    });
  }
}
