import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaidCheckingAccounts } from '@app/acquisition/apply/connect-bank/bank-connection-api/bank-connection-api.service';
import { MaintenanceService } from '@app/acquisition/maintenance/services/maintenance.service';
import { AccountNotPermittedModalFormGroup } from './account-not-permitted-modal-form/account-not-permitted-modal.form';

@Component({
  selector: 'rise-account-not-permitted-modal',
  templateUrl: './account-not-permitted-modal.component.html',
  styleUrls: ['./account-not-permitted-modal.component.scss'],
  providers: [AccountNotPermittedModalFormGroup],
})
export class AccountNotPermittedModalComponent {
  public bankAccountsInformation: PlaidCheckingAccounts[];
  public checkingAccountsExist = true;

  constructor(
    private dialogRef: MatDialogRef<AccountNotPermittedModalComponent>,
    public form: AccountNotPermittedModalFormGroup,
    @Inject(MAT_DIALOG_DATA) public accounts: PlaidCheckingAccounts[],
    private maintenance: MaintenanceService
  ) {
    this.checkingAccountsExist = this.accounts.length > 0;
  }

  public saveInformation(): void {
    if (this.maintenance.checkMaintenance()) {
      return;
    }
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.get('account').value);
  }

  public closeModal(): void {
    this.dialogRef.close(null);
  }
}
