import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const maxAmountValidatorName = 'maxAmount';

export function maxAmountValidator(
  maximum: number,
  text: string = 'Greater than maximum',
  rules?: Rule[]
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors {
    if ([undefined, null, ''].includes(control.value)) {
      return null;
    }

    const num = Number(control.value);

    if (num <= maximum) {
      return null;
    }

    return new ValidationMessagesError(maxAmountValidatorName, rules, text);
  };
}
