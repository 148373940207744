import { Component } from '@angular/core';
import { CookieKeys } from '@cookie/cookie-keys';
import { CookieService } from '@cookie/cookie-service';
import { Environment } from '@environment/environment.module';

@Component({
  selector: 'rise-debug-hud',
  templateUrl: './debug-hud.component.html',
  styleUrls: ['./debug-hud.component.scss'],
})
export class DebugHudComponent {
  public isProduction: boolean;
  public visitorId: string;
  public isHudCollapsed = true;

  constructor(environment: Environment, cookieService: CookieService) {
    this.isProduction = environment.environmentName === 'PROD';
    this.visitorId = cookieService.get(CookieKeys.visitorId);
  }

  public toggle(): void {
    this.isHudCollapsed = !this.isHudCollapsed;
  }
}
