import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MaritalStatusModel } from '@app/acquisition/application/application.models';
import { PageFormGroup } from '@app/forms/page-form-group/page-form-group';
import { requiredCheckboxValidator } from '@validation/validators/required-checkbox.validator';
import { requiredValidator } from '@validation/validators/required.validator';

export interface Disclosures {
  creditServiceDisclosure?: boolean;
  creditAgreementDisclosure?: boolean;
  caBrokerQuestion?: boolean;
}
export interface MaritalOptions {
  readonly value: string;
  readonly description: string;
}

export const creditServiceDisclosureFieldName = 'creditServiceDisclosure';
export const caBrokerQuestionFieldName = 'caBrokerQuestion';
export const marriedStatus = 'maritalStatus';
export const maritalOptions: MaritalOptions[] = [
  {
    value: MaritalStatusModel.Married,
    description: 'Married',
  },
  {
    value: MaritalStatusModel.Unmarried,
    description: 'Unmarried',
  },
];

@Injectable()
export class DisclosuresFormGroup extends PageFormGroup {
  constructor() {
    super({
      creditAgreementDisclosure: new FormControl(null, [
        requiredCheckboxValidator(
          'Please read and agree to the above to continue.'
        ),
      ]),
    });
  }

  public appendCaliforniaDisclosures(): void {
    // this.addControl(
    //   creditServiceDisclosureFieldName,
    //   new FormControl(null, [
    //     requiredCheckboxValidator(
    //       'Please read and agree to the above to continue.'
    //     ),
    //   ])
    // );
    this.addControl(
      caBrokerQuestionFieldName,
      new FormControl(null, [
        requiredCheckboxValidator(
          'Please read and agree to the above to continue.'
        ),
      ])
    );
  }

  public appendMarriedStatus(): void {
    this.addControl(
      marriedStatus,
      new FormControl(null, [
        requiredValidator('Please select your marital status.'),
      ])
    );
  }
}
