<div [formGroup]="form" *ngIf="showForm">
  <div class="checkbox-wrapper-div">
    <!-- htmllint id-class-style=false -->
    <mat-checkbox
      id="{{ fieldName }}"
      [formControlName]="fieldName"
    ></mat-checkbox>
    <div>{{ fieldLabel }}</div>
  </div>
  <mat-error>
    <rise-validation-messages controlName="{{ fieldName }}"></rise-validation-messages>
  </mat-error>
</div>
