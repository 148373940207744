import { UnderwritingOfferModel } from './application.models';

export enum UnderwritingSteps {
  Prequal = 'Prequal',
  ApplicationSubmit = 'ApplicationSubmit',
}

export enum UnderwritingStepStatus {
  NotStarted = 'NotStarted',
  PendingDecision = 'PendingDecision',
  Incomplete = 'Incomplete',
  Complete = 'Complete',
}

export enum UnderwritingStatus {
  InProgress = 'InProgress',
  Approved = 'Approved',
  Declined = 'Declined',
}

export enum ManualReviewType {
  OFACTA = 'O',
  FACTA = 'F',
  MLA = 'M',
  BANKVerification = 'B',
  CVS = 'C',
}

export enum ManualTrackedRequestsType {
  OFACTA = 'OFAC',
  FACTA = 'FACTA',
  MLA = 'MLA',
  CVS = 'CVS',
  BankVerification = 'BankVerification',
}

export interface UnderwritingModel {
  steps: UnderwritingStepModel[];
  decisions: UnderwritingDecisionModel[];
}

export interface UnderwritingStepModel {
  name: UnderwritingSteps;
  status: string;
  disposition?: UnderwritingDispositionModel;
}

export interface UnderwritingDecisionModel {
  name: UnderwritingSteps;
  requestedAt?: string;
  status: string;
  disposition?: UnderwritingDispositionModel;
}

export interface UnderwritingDispositionModel {
  type?: string;
  reason?: string;
  offer?: UnderwritingOfferModel;
  noaa?: { code?: string };
}

export interface TrackedRequests {
  name?: string;
  requester?: string;
  status?: string;
  type?: string;
  result?: string;
}
