<div class="dialog-header">
  <img
    id="closeImage"
    class="close-img"
    src="assets/images/button_close.svg"
    (click)="closeModal()"
  />
</div>
<div mat-dialog-title id="consentTitle" #consentInfoTitle class="dialog-header">
  <p>{{ title }}</p>
</div>
<mat-dialog-actions  *ngIf="showSavePrintBtn">
  <div  id="printContent" (click)="printDisclosure()" class="save-print-link">
    Save/Print a Copy
  </div>
</mat-dialog-actions>
<mat-dialog-content id="diclosureModalContent" class="mat-typography">
    <div  *ngIf="scrollToSign" class="scroll-box">
        <div class="scroll-head">
          <div class="image-container">
            <img id="scrollImage"
                 src="assets/images/disclosure_scroll_arrow.svg" />
    
          </div>
          <div class="scroll-box-text">Please scroll down completely to sign, agree, and continue.</div>
        </div>
      </div>
  <rise-dynamic-html [content]="htmlBody"></rise-dynamic-html>

  <div class="dialog-buttons-div" *ngIf="continueCancelBtns">
    <button
      class="dialog-button dialog-button-agree"
      id="agreeBtn"
      mat-flat-button
      data-nid-target="Sign_BtnDisclosureCompleted"
      type="submit"
      (click)="handleAgreeClick()"
    >
      Agree and continue
    </button>
    <button
      mat-flat-button
      class="dialog-button dialog-button-cancel"
      id="cancelBtn"
      type="submit"
      (click)="closeModal()"
    >
      Cancel
    </button>
  </div>
</mat-dialog-content>
