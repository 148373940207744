import { NgModule } from '@angular/core';

import { BrandComponent } from './brand.component';

@NgModule({
  declarations: [BrandComponent],
  exports: [BrandComponent],
  entryComponents: [BrandComponent],
})
export class BrandModule {}
