export enum StateCode {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  DistrictOfColumbia = 'DC',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
}
export interface State {
  name: string;
  stateCode: StateCode;
}
export const states: State[] = [
  {
    name: 'Alabama',
    stateCode: StateCode.Alabama,
  },
  {
    name: 'Alaska',
    stateCode: StateCode.Alaska,
  },
  {
    name: 'Arizona',
    stateCode: StateCode.Arizona,
  },
  {
    name: 'Arkansas',
    stateCode: StateCode.Arkansas,
  },
  {
    name: 'California',
    stateCode: StateCode.California,
  },
  {
    name: 'Colorado',
    stateCode: StateCode.Colorado,
  },
  {
    name: 'Connecticut',
    stateCode: StateCode.Connecticut,
  },
  {
    name: 'Delaware',
    stateCode: StateCode.Delaware,
  },
  {
    name: 'District Of Columbia',
    stateCode: StateCode.DistrictOfColumbia,
  },
  {
    name: 'Florida',
    stateCode: StateCode.Florida,
  },
  {
    name: 'Georgia',
    stateCode: StateCode.Georgia,
  },
  {
    name: 'Hawaii',
    stateCode: StateCode.Hawaii,
  },
  {
    name: 'Idaho',
    stateCode: StateCode.Idaho,
  },
  {
    name: 'Illinois',
    stateCode: StateCode.Illinois,
  },
  {
    name: 'Indiana',
    stateCode: StateCode.Indiana,
  },
  {
    name: 'Iowa',
    stateCode: StateCode.Iowa,
  },
  {
    name: 'Kansas',
    stateCode: StateCode.Kansas,
  },
  {
    name: 'Kentucky',
    stateCode: StateCode.Kentucky,
  },
  {
    name: 'Louisiana',
    stateCode: StateCode.Louisiana,
  },
  {
    name: 'Maine',
    stateCode: StateCode.Maine,
  },
  {
    name: 'Maryland',
    stateCode: StateCode.Maryland,
  },
  {
    name: 'Massachusetts',
    stateCode: StateCode.Massachusetts,
  },
  {
    name: 'Michigan',
    stateCode: StateCode.Michigan,
  },
  {
    name: 'Minnesota',
    stateCode: StateCode.Minnesota,
  },
  {
    name: 'Mississippi',
    stateCode: StateCode.Mississippi,
  },
  {
    name: 'Missouri',
    stateCode: StateCode.Missouri,
  },
  {
    name: 'Montana',
    stateCode: StateCode.Montana,
  },
  {
    name: 'Nebraska',
    stateCode: StateCode.Nebraska,
  },
  {
    name: 'Nevada',
    stateCode: StateCode.Nevada,
  },
  {
    name: 'New Hampshire',
    stateCode: StateCode.NewHampshire,
  },
  {
    name: 'New Jersey',
    stateCode: StateCode.NewJersey,
  },
  {
    name: 'New Mexico',
    stateCode: StateCode.NewMexico,
  },
  {
    name: 'New York',
    stateCode: StateCode.NewYork,
  },
  {
    name: 'North Carolina',
    stateCode: StateCode.NorthCarolina,
  },
  {
    name: 'North Dakota',
    stateCode: StateCode.NorthDakota,
  },
  {
    name: 'Ohio',
    stateCode: StateCode.Ohio,
  },
  {
    name: 'Oklahoma',
    stateCode: StateCode.Oklahoma,
  },
  {
    name: 'Oregon',
    stateCode: StateCode.Oregon,
  },
  {
    name: 'Pennsylvania',
    stateCode: StateCode.Pennsylvania,
  },
  {
    name: 'Rhode Island',
    stateCode: StateCode.RhodeIsland,
  },
  {
    name: 'South Carolina',
    stateCode: StateCode.SouthCarolina,
  },
  {
    name: 'South Dakota',
    stateCode: StateCode.SouthDakota,
  },
  {
    name: 'Tennessee',
    stateCode: StateCode.Tennessee,
  },
  {
    name: 'Texas',
    stateCode: StateCode.Texas,
  },
  {
    name: 'Utah',
    stateCode: StateCode.Utah,
  },
  {
    name: 'Vermont',
    stateCode: StateCode.Vermont,
  },
  {
    name: 'Virginia',
    stateCode: StateCode.Virginia,
  },
  {
    name: 'Washington',
    stateCode: StateCode.Washington,
  },
  {
    name: 'West Virginia',
    stateCode: StateCode.WestVirginia,
  },
  {
    name: 'Wisconsin',
    stateCode: StateCode.Wisconsin,
  },
  {
    name: 'Wyoming',
    stateCode: StateCode.Wyoming,
  },
];
