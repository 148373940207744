<div id="paymentCalendarDiv">

  <div id="paymentCalendarTitle">Review your repayment calendar below</div>
  <div id="firstPaymentDateTitle">FIRST PAYMENT DATE</div>
  <div id="firstPaymentDateText">{{firstPaymentDate}}</div>

  <div id="paymentTableHeader">
    <div class="payment-table-header-cell">DATE</div>
    <div class="payment-table-header-cell">PRINCIPAL</div>
    <div class="payment-table-header-cell">{{interestColumnName || 'INTEREST'}}</div>
    <div class="payment-table-header-cell">TOTAL</div>
  </div>

  <div class="payment-table-row" *ngFor="let payment of displayPaymentSchedule">
    <div class="payment-table-row-cell">{{payment.dueDate}}</div>
    <div class="payment-table-row-cell">{{payment.principal | currency}}</div>
    <div class="payment-table-row-cell">{{payment.interest | currency}}</div>
    <div class="payment-table-row-cell">{{payment.totalPayment | currency}}</div>
  </div>

  <div id="paymentTableToggleDiv"
    (click)="toggleShowAllPayments()"
    *ngIf="displayPaymentSchedule.length > 0">
    <div id="toggleButtonText">{{toggleButtonMessage}}</div>
    <img
      id="toggleButtonArrowImage"
      [ngClass]="{'toggle-rotation': showAllPayments}"
      src="assets/images/line_arrow.svg" />
  </div>
</div>