import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Environment } from '@environment/environment.module';
import { Observable, ReplaySubject } from 'rxjs';
import { delay, retryWhen, takeWhile, tap } from 'rxjs/operators';

export interface UnderwritingResponseModel {
  offer?: {
    line?: number;
    apr?: number;
    expireAt?: string;
  };
  status?: string;
  requiredBeforeNextUnderwrite?: {
    agentReviewType?: string;
    additionalInformation?: string[];
  };
  underwrite?: {
    name?: string;
    status?: string;
    disposition?: {
      type?: string;
      reason?: string;
      offer?: {
        line?: number;
        apr?: number;
        expireAt?: string;
      };
      noaa?: {
        code?: string;
      };
    };
  };
}

export enum UnderwritingStepEnums {
  prequal = 'prequal',
  submit = 'applicationsubmit',
}

@Injectable()
export class UnderwritingService {
  public underwritingDecisionSubject =
    new ReplaySubject<UnderwritingResponseModel>(1);
  public isUnderwritingProcessing = false;
  public hasUnderwritingCompleted = false;

  constructor(
    private http: HttpClient,
    private environment: Environment,
    private dialog: MatDialog,
    private router: Router
  ) {}

  public resetDecision(): void {
    this.underwritingDecisionSubject =
      new ReplaySubject<UnderwritingResponseModel>(1);
    this.hasUnderwritingCompleted = false;
  }

  public submitApplicationUnderwriting(): void {
    this.isUnderwritingProcessing = true;
    this.initiateFinalUnderwrite();
  }

  public underwrite(
    underwriteStep: UnderwritingStepEnums
  ): Observable<UnderwritingResponseModel> {
    return this.http
      .patch<UnderwritingResponseModel>(
        `${this.environment.brandApi.url}/api/v1/application/underwrite/${underwriteStep}`,
        {}
      )
      .pipe(
        retryWhen((error: any) => {
          let retryCount = 0;
          return error.pipe(
            delay(this.environment.brandApi.underwriteRetry.retryTimeout),
            takeWhile(
              () =>
                retryCount <
                this.environment.brandApi.underwriteRetry.retryCount
            ),
            tap(
              () => retryCount++,
              () => {},
              () => {
                throw new Error();
              }
            )
          );
        })
      );
  }

  private initiateFinalUnderwrite(): void {
    this.underwrite(UnderwritingStepEnums.submit).subscribe(
      (response: UnderwritingResponseModel) => {
        this.isUnderwritingProcessing = false;
        this.hasUnderwritingCompleted = true;
        this.underwritingDecisionSubject.next(response);
      },
      () => {
        this.navigateToErrorPage();
      }
    );
  }

  private navigateToErrorPage(): void {
    this.dialog.closeAll();
    this.router.navigate(['../error']);
  }
}
