import { Injectable } from '@angular/core';
import { ParsedDisclosureAgreementData } from '../../disclosure.models';

@Injectable()
export class DisclosureRenderService {
  constructor() {}

  public convertBodyHtml(inputBodyString: string): string {
    return inputBodyString.replace(/{.+?}/gm, this.constructTag);
  }

  public printDisclosure(body: string): void {
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(
      `<html><head></head><body onload="window.print()">${body}</body></html>`
    );
    printWindow.document.close();
  }

  private constructTag(inputJson: string): string {
    let outputTag = '';
    let jsonObject: ParsedDisclosureAgreementData;

    try {
      jsonObject = JSON.parse(inputJson);
    } catch (error) {
      throw new Error(`json tag in disclosure is not readable: ${inputJson}`);
    }

    switch (jsonObject.Type) {
      case 'Checkbox':
        outputTag = `<rise-checkbox-agreement fieldname="${jsonObject.Id}" fieldlabel="${jsonObject.Label}"></rise-checkbox-agreement>`;
        break;
      case 'Textbox':
        outputTag = `<rise-text-agreement fieldname="${jsonObject.Id}"></rise-text-agreement>`;
        break;
      case 'Last4SSN':
        outputTag = `<rise-ssn-agreement fieldname="${jsonObject.Id}"></rise-ssn-agreement>`;
        break;
      case 'SignAndAgree':
        outputTag = `<rise-i-agree-agreement fieldname="${jsonObject.Id}"></rise-i-agree-agreement>`;
        break;
      case 'Link':
        outputTag = `<rise-link-tag fieldName="${jsonObject.Label}" hrefTarget="${jsonObject.hrefTarget}" target="${jsonObject.target}" id="${jsonObject.Id}"></rise-link-tag>`;
        break;
      case 'Brand':
        outputTag = '<rise-brand></rise-brand>';
        break;
    }
    return outputTag;
  }
}
