import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@app/environment/environment.module';
import { Observable } from 'rxjs';
import {
  SessionStorageKeys,
  SessionStorageService,
} from '../../acquisition/services/session-storage-service/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private sessionStorageService: SessionStorageService,
    private environment: Environment
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.environment.brandApi.url)) {
      const authToken = this.sessionStorageService.getSessionStorageItem(
        SessionStorageKeys.authorizationToken
      );

      // add Authorization header if auth token exists
      if (authToken) {
        const headers = req.headers.set('Authorization', `Bearer ${authToken}`);
        const dupRequest = req.clone({
          headers: headers,
        });
        return next.handle(dupRequest);
      }
    }
    return next.handle(req);
  }
}
