import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModifyContactInfo } from '@app/acquisition/apply/verify-info/modify-contact-info/form/modify-contact-info-modal.form';
// tslint:disable-next-line: max-line-length
import { ModifyContactInfoModalComponent } from '@app/acquisition/apply/verify-info/modify-contact-info/modify-contact-info-modal.component';
import { Environment } from '@app/environment/environment.module';

export interface ModifyContactInfoExtended extends ModifyContactInfo {
  stateCode: string;
  zipCode: string;
}

@Component({
  selector: 'app-edit-contact-info',
  templateUrl: './edit-contact-info.component.html',
  styleUrls: [
    '../../../apply/verify-info/modify-contact-info/modify-contact-info-modal.component.scss',
  ],
})
export class EditContactInfoComponent {
  public invalidAddressInfo: boolean;
  public invalidMobileNoInfo: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) private modalData: any,
    private dialogRef: MatDialogRef<ModifyContactInfoModalComponent>,
    private environment: Environment
  ) {
    this.invalidAddressInfo = this.modalData?.showinvalidAddress || false;
    this.invalidMobileNoInfo = this.modalData?.showInvalidMobileNo || false;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public navigateToAccountSettings(): void {
    window.location.assign(`${this.environment.url.wiq}/apps/settings`);
  }
}
