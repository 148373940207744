import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment.module';
import { State, states } from '@state/state';
import { Observable } from 'rxjs';

export interface StateOptionsApiRequest {
  stateAbbreviation: string;
}

export interface StateOptionsApiResponse {
  stateName: string;
  stateCode?: string;
  isLoanServiced: boolean;
  isFinWiseState: boolean;
  isRiseState: boolean;
  monetizationUrl?: string;
  monetizationUrlDecline?: string;
  lenderDisplayName?: string;
  lenderCode?: string;
  amounts?: number[];
  originationFeePercentage?: number;
  originationFeeCapDollarAmount?: number;
}

export interface StateLinesApiResponse {
  id: number;
  lenderCode: string;
  rollupId: number;
  stateLine: StateLine;
}

export interface StateLine {
  minAmount: number;
  maxAmount: number;
}

@Injectable()
export class StateOptionsApi {
  public get currentStateOptions(): StateOptionsApiResponse {
    return this.lastStateOptions;
  }

  public set currentStateOptions(value: StateOptionsApiResponse) {
    this.lastStateOptions = value;
  }

  public stateInfoArray: State[] = states;
  public termsConditionURL: string;
  private lastStateOptions: StateOptionsApiResponse = null;

  constructor(private http: HttpClient, private environment: Environment) {}

  public getStateOptions(
    stateCode: string,
    brandCode?: string
  ): Observable<StateOptionsApiResponse> {
    const brandQs = brandCode == null ? '' : `&brandCode=${brandCode}`;

    const resObservable: Observable<StateOptionsApiResponse> =
      this.http.get<StateOptionsApiResponse>(
        `${this.environment.brandApi.url}/api/product-configuration?stateCode=${stateCode}&productId=2200${brandQs}`
      );

    resObservable.subscribe((res: StateOptionsApiResponse) => {
      res.stateCode = stateCode;
      this.currentStateOptions = res;
      if (res.isRiseState) {
        this.termsConditionURL = `${this.environment.url.home}/terms-and-conditions/${res.stateCode}`;
      } else if (res.isFinWiseState) {
        this.termsConditionURL = `${this.environment.url.home}/terms-and-conditions/finwise`;
      } else {
        this.termsConditionURL = `${this.environment.url.home}/terms-and-conditions/ccbank`;
      }
    });

    return resObservable;
  }

  public async getStateOptionsAsync(
    stateCode: string,
    brandCode?: string
  ): Promise<StateOptionsApiResponse> {
    const brandQs = brandCode == null ? '' : `&brandCode=${brandCode}`;

    return await this.http
      .get<StateOptionsApiResponse>(
        `${this.environment.brandApi.url}/api/product-configuration?stateCode=${stateCode}&productId=2200${brandQs}`
      )
      .toPromise();
  }

  public getStateLines(
    brandCode: string,
    stateCode: string
  ): Observable<StateLinesApiResponse> {
    return this.http.get<StateLinesApiResponse>(
      `${this.environment.brandApi.url}/api/product-statelines?brandCode=${brandCode}&stateCode=${stateCode}`
    );
  }
}
