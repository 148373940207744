<div class="dialog-header">
</div>
<div mat-dialog-title id="bankConnectionErrorTitle" class="dialog-header">
  <p>Account not permitted!</p>
</div>
<mat-dialog-content id="bankConnectionErrorContent" class="mat-typography">
  <p>
    You cannot use an existing account number. Click Return to select a different bank.
  </p>
  <button id="bankConnectionErrorReturnBtn" (click)="onReturnClick()">Return</button>
</mat-dialog-content>