import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputCurrencyFormatterDirective } from './input-currency-formatter/input-currency-formatter.directive';
import { NonDecimalCurrencyPipe } from './input-currency-formatter/non-decimal-currency.pipe';
import { StringFormatterPipe } from './string-formatter/string-formatter.pipe';

@NgModule({
  declarations: [
    InputCurrencyFormatterDirective,
    NonDecimalCurrencyPipe,
    StringFormatterPipe,
  ],
  providers: [CurrencyPipe, NonDecimalCurrencyPipe, StringFormatterPipe],
  exports: [
    InputCurrencyFormatterDirective,
    NonDecimalCurrencyPipe,
    StringFormatterPipe,
  ],
})
export class FormsModule {}
