import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CAbrokerFormGroup } from './ca-broker-modal.form';

interface CaModalData {
  isCaBrokerQuestionSelected: boolean;
}
@Component({
  selector: 'rise-app-ca-broker-modal',
  templateUrl: './ca-broker-modal.component.html',
  styleUrls: ['./ca-broker-modal.component.scss'],
  providers: [CAbrokerFormGroup],
})
export class CAbrokerModalComponent implements OnInit {
  constructor(
    public form: CAbrokerFormGroup,
    public dialogRef: MatDialogRef<CAbrokerModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CaModalData
  ) {}

  public ngOnInit(): void {
    const checkbox = this.form.get('caBrokerChkbx');

    if (this.data.isCaBrokerQuestionSelected) {
      checkbox.patchValue(this.data.isCaBrokerQuestionSelected);
    }
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    this.form.showValidationErrors();
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close(this.form);
  }
}
