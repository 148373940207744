import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { inputMatchValidator } from '@app/validation/validators/input-match.validator';
import { inputNotContainValidator } from '@app/validation/validators/input-not-contain.validator';
import { maxLengthValidator } from '@app/validation/validators/max-length.validator';
import { minLengthValidator } from '@app/validation/validators/min-length.validator';
import { passwordPatternValidator } from '@app/validation/validators/password-pattern.validator';
import { requiredValidator } from '@app/validation/validators/required.validator';
import { userNamePatternValidator } from '@app/validation/validators/userName-pattern.validator';
import { PageFormGroup } from '@forms/page-form-group/page-form-group';

export enum passwordValidationType {
  upperCase = 'UpperCase',
  lowerCase = 'LowerCase',
  number = 'Number',
  specialCharacters = 'SpecialCharacters',
}

export enum userNameValidationType {
  specialCharacters = 'SpecialCharacters',
}
@Injectable()
export class AccountSetupFormGroup extends PageFormGroup {
  constructor() {
    super({
      email: new FormControl({ value: null, disabled: true }),
      userName: new FormControl(null, validateusername),
      createPassword: new FormControl(null, [
        requiredValidator('Please create a password.'),
        passwordPatternValidator(
          /[A-Z]/,
          passwordValidationType.upperCase,
          'Password does not meet requirements.'
        ),
        passwordPatternValidator(
          /[a-z]/,
          passwordValidationType.lowerCase,
          'Password does not meet requirements.'
        ),
        passwordPatternValidator(
          /\d/,
          passwordValidationType.number,
          'Password does not meet requirements.'
        ),
        passwordPatternValidator(
          /[!#$%&'()*+,-.\/:;<=>?@\[\]^_`{|}~]/,
          passwordValidationType.specialCharacters,
          'Password does not meet requirements.'
        ),
        minLengthValidator(12, 'Password does not meet requirements.'),
        maxLengthValidator(
          64,
          'Password does not meet requirements. Maximum length (64)'
        ),
        inputNotContainValidator(
          'email',
          'Password does not meet requirements.'
        ),
      ]),
      confirmPassword: new FormControl(null, [
        requiredValidator('Please re-enter your password.'),
        inputMatchValidator(
          'createPassword',
          'Passwords do not match. Please re-enter.'
        ),
      ]),
    });

    function validateusername(formControl: FormControl) {
      let userNameEnabled = false;
      if (!formControl.parent) {
        return null;
      }
      if (formControl.parent.get('userName').disabled == false) {
        userNameEnabled = true;
      } else {
        userNameEnabled = false;
      }
      const listfunctions = [
        requiredValidator('Please create a username.'),
        userNamePatternValidator(
          /^[a-zA-Z0-9]+$/,
          userNameValidationType.specialCharacters,
          'Only alphanumeric values are allowed.'
        ),
        minLengthValidator(
          8,
          'Username does not meet requirements. Minimum length (8).'
        ),
        maxLengthValidator(
          40,
          'Username does not meet requirements. Maximum length (40).'
        ),
      ];

      if (userNameEnabled) {
        formControl.setValidators(listfunctions);
      }
    }
  }
}
