<div class="dialog-header">
  <img
    id="closeImage"
    class="close-img"
    src="assets/images/button_close.svg"
    (click)="closeModal()"
  />
</div>
<div mat-dialog-title id="bankInfoMiTitle" class="dialog-header">
  <p>Change your loan amount</p>
</div>
<mat-dialog-content
  id="bankInfoMiUsageContent"
  class="mat-typography"
  [formGroup]="form"
>
  <p id="loanAmountModalInstructionText">
    You can change your loan amount by entering an amount from
    <span class="bold-text">{{
      loanMinMax.minimum | currency: "USD":"symbol":"1.0-0"
    }}</span>
    to
    <span class="bold-text">{{
      loanMinMax.maximum | currency: "USD":"symbol":"1.0-0"
    }}</span>
    in increments of <span class="bold-text">$50</span> below.
  </p>

  <mat-form-field color="accent" class="rise-form-field">
    <input
      matInput
      id="loanAmountTextInput"
      formControlName="loanAmount"
      mask="00000"
      [validation]="false"
      [isRequestedAmount]="'true'"
      riseInputCurrencyFormatter
    />

    <mat-label id="loanAmountLabel">Loan Amount</mat-label>

    <mat-error>
      <rise-validation-messages
        controlName="loanAmount"
      ></rise-validation-messages>
    </mat-error>
  </mat-form-field>

  <button
    mat-flat-button
    id="loanAmountModalSubmitButton"
    type="submit"
    class="rise-submit-button"
    (click)="submit()"
    tealiumClick="Change-Loan-Amt"
    [riseSubmitToggle]="form"
  >
    Save
  </button>
</mat-dialog-content>
