<div [formGroup]="form" *ngIf="showForm">
  <!-- htmllint id-class-style=false -->
  <input
    id="{{ fieldName }}"
    maxlength="50"
    formControlName="{{ fieldName }}"
  />
  <mat-error>
    <rise-validation-messages controlName="{{ fieldName }}"></rise-validation-messages>
  </mat-error> 
</div>
