import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SessionStorageObjects,
  SessionStorageService,
} from '@app/acquisition/services/session-storage-service/session-storage.service';
import { CookieService } from '@app/cookie/cookie-service';
import { Environment } from '@app/environment/environment.module';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { delay, retryWhen, takeWhile, tap } from 'rxjs/operators';
import {
  ApplicationSearchByIdModel,
  ApplicationSearchModel,
  SendCodeRequestModel,
  VerifyCodeRequestModel,
  VerifyCodeResponseModel,
} from './otp.models';
import { AbTestingData } from '../../getting-started/form/getting-started.form';

export enum StartOptions {
  StartNewOnly = 'StartNewOnly',
  ResumeOnly = 'ResumeOnly',
  ResumeOrStartNew = 'ResumeOrStartNew',
  NoOptions = 'NoOptions',
  RedirectToBau = 'RedirectToBau',
  RedirectFormerRefinanceSignIn = 'RedirectFormerRefinanceSignIn',
  RedirectToBlueprint = 'RedirectToBlueprint'
}

export interface StartOptionsResponse {
  startOptions: StartOptions;
  reason?: string;
  phoneNumberLast4?: string;
  applicationId?: string;
  abTesting?: AbTestingData[];
}

@Injectable()
export class OtpService {
  private emailAttemptLog: Object;
  private cookieTimeoutInMinutes = 60;

  constructor(
    private environment: Environment,
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
    private cookieService: CookieService
  ) {}

  public lookup(
    searchParams: ApplicationSearchModel
  ): Observable<StartOptionsResponse> {
    return this.http
      .post<StartOptionsResponse>(
        `${this.environment.brandApi.url}/api/v1/application/start-options`,
        searchParams
      )
      .pipe(
        retryWhen((error: any) => {
          let retryCount = 0;
          return error.pipe(
            delay(this.environment.httpRetry.retryTimeout),
            takeWhile(() => retryCount < this.environment.httpRetry.retryCount),
            tap(
              () => retryCount++,
              () => {},
              () => {
                throw new Error();
              }
            )
          );
        })
      );
  }

  public lookupByID(
    searchParams: ApplicationSearchByIdModel
  ): Observable<StartOptionsResponse> {
    return this.http
      .post<StartOptionsResponse>(
        `${this.environment.brandApi.url}/api/v1/application/start-options-by-id`,
        searchParams
      )
      .pipe(
        retryWhen((error: any) => {
          let retryCount = 0;
          return error.pipe(
            delay(this.environment.httpRetry.retryTimeout),
            takeWhile(() => retryCount < this.environment.httpRetry.retryCount),
            tap(
              () => retryCount++,
              () => {},
              () => {
                throw new Error();
              }
            )
          );
        })
      );
  }

  public logEmailOnlyAttempt(emailAddress: string): void {
    const lowercaseEmail = emailAddress.toLowerCase();
    this.emailAttemptLog = this.sessionStorage.getSessionStorageObject(
      SessionStorageObjects.emailOnlyAttempts
    );
    if (this.emailAttemptLog == null) {
      this.emailAttemptLog = {};
      this.emailAttemptLog[lowercaseEmail] = 1;
    } else {
      if (this.emailAttemptLog.hasOwnProperty(lowercaseEmail)) {
        this.emailAttemptLog[lowercaseEmail]++;
      } else {
        this.emailAttemptLog[lowercaseEmail] = 1;
      }
    }
    this.sessionStorage.setSessionStorageObject(
      SessionStorageObjects.emailOnlyAttempts,
      this.emailAttemptLog
    );
  }

  public getEmailAttemptCount(emailAddress: string): number {
    const emailLog = this.sessionStorage.getSessionStorageObject(
      SessionStorageObjects.emailOnlyAttempts
    );
    if (emailLog == null) {
      return 0;
    }
    if (emailLog.hasOwnProperty(emailAddress.toLowerCase())) {
      return emailLog[emailAddress.toLowerCase()];
    }
    return 0;
  }

  public sendCode(sendCodeInfo: SendCodeRequestModel): Observable<any> {
    return this.http.post<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/otp/send`,
      sendCodeInfo,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      }
    );
  }

  public verifyCode(
    verificationCode: VerifyCodeRequestModel
  ): Observable<HttpResponse<VerifyCodeResponseModel>> {
    return this.http.post<VerifyCodeResponseModel>(
      `${this.environment.brandApi.url}/api/v1/application/resume`,
      verificationCode,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      }
    );
  }

  public verifyEmailCode(oneTimePassword: any): Observable<HttpResponse<any>> {
    return this.http.patch<any>(
      `${this.environment.brandApi.url}/api/v1/application/email-confirmation`,
      oneTimePassword,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      }
    );
  }

  public lockUser(): void {
    this.cookieService.set('checkValid', 'true', {
      expires: moment().add(this.cookieTimeoutInMinutes, 'minutes').toDate(),
    });
  }

  public isUserLocked(): boolean {
    return this.cookieService.get('checkValid') === 'true';
  }

  /* istanbul ignore next */
  public setTimeoutToOneMinute(): void {
    this.cookieTimeoutInMinutes = 1;
  }
}
