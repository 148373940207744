import { CurrencyPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({ name: 'nonDecimalCurrency' })
export class NonDecimalCurrencyPipe implements PipeTransform {
  private readonly CURRENCY_CODE = 'USD';
  private readonly DISPLAY = 'symbol';
  private readonly MIN_INTEGER_DIGITS = 1;
  private readonly MIN_FRACTION_DIGITS = 0;
  private readonly MAX_FRACTION_DIGITS = 0;

  constructor(private currencyPipe: CurrencyPipe) {}

  public transform(value: any): string {
    return this.currencyPipe.transform(
      value,
      this.CURRENCY_CODE,
      this.DISPLAY,
      `${this.MIN_INTEGER_DIGITS}.${this.MIN_FRACTION_DIGITS}-${this.MAX_FRACTION_DIGITS}`
    );
  }
}
