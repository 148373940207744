import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({ name: 'stringFormatter' })
export class StringFormatterPipe implements PipeTransform {
  private wordRegex: RegExp = /\\[wWdDsS]({\d(,\d?)?})?/g;

  public transform(value: string, format: string): string {
    if (!value) {
      return;
    }

    const charRangeSelector: string[] = format.match(this.wordRegex);

    let temp = value;

    const result = charRangeSelector.reduce(
      (accumulator: string, regex: string) => {
        const match = temp.match(regex);
        const component = match[0];

        temp = temp.substring(match.index + component.length);

        return accumulator.replace(regex, component);
      },
      format
    );

    return result;
  }
}
