import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Environment } from '@app/environment/environment.module';
import { PageFormGroup } from '@forms/page-form-group/page-form-group';
import {
  SmartyStreetApi,
  SmartyStreetLookupResponse,
  SmartyStreetZipCode,
} from '@smarty-street/smarty-street.api';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';
import { emailValidator } from '@validation/validators/email.validator';
import { fixedLengthValidator } from '@validation/validators/fixed-length.validator';
import { minLengthValidator } from '@validation/validators/min-length.validator';
import { patternValidator } from '@validation/validators/pattern.validator';
import { PhonenumberPrefixValidator } from '@validation/validators/phone-prefix.validator';
import { requiredValidator } from '@validation/validators/required.validator';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

export interface GettingStarted {
  firstName?: string;
  lastName?: string;
  zipCode?: string;
  state?: string;
  ssn?: string;
  email?: string;
  mobilePhoneNumber?: string;
  directMailCode?: string;
  abTesting?: AbTestingData[];
}

export interface ShortFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  state?: string;
}

export interface AbTestingData{
  testName: string;
  segmentName: string;
}

@Injectable()
export class GettingStartedFormGroup extends PageFormGroup {
  public value: GettingStarted;
  public requestAmountMin = 0;
  public requestAmountMax = 0;

  constructor(
    private smartyStreetApi: SmartyStreetApi,
    private environment: Environment
  ) {
    super({
      firstName: new FormControl(null, [
        requiredValidator('Please enter your first name.'),
        minLengthValidator(2, 'Field requires between 2 and 50 characters.'),
        patternValidator(
          /^[a-zA-Z"'\- ]+$/,
          'Alphabetical characters only please.'
        ),
      ]),
      lastName: new FormControl(null, [
        requiredValidator('Please enter your last name.'),
        minLengthValidator(2, 'Field requires between 2 and 50 characters.'),
        patternValidator(
          /^[a-zA-Z."'\- ]+$/,
          'Alphabetical characters only please.'
        ),
      ]),
      zipCode: new FormControl(null, {
        validators: [
          requiredValidator('Please enter your zip code.'),
          fixedLengthValidator(5, 'Zip code must be 5 digits.'),
        ],
        asyncValidators: [
          (control: AbstractControl) =>
            this.zipCodeSmartyStreetValidation(control),
        ],
      }),
      state: new FormControl(null, {
        validators: [requiredValidator('Please select your state.')],
        asyncValidators: [
          (control: AbstractControl) =>
            this.stateSmartyStreetValidation(control),
        ],
      }),
      ssn: new FormControl(null, [
        requiredValidator('Please enter your social security number.'),
        fixedLengthValidator(9, 'Field requires 9 digits.'),
      ]),
      email: new FormControl(null, [
        requiredValidator('Please enter your email address.'),
        emailValidator('Please enter valid email address.'),
        patternValidator(
          /^[^\x80-\xFF]*$/,
          'Please enter valid email address.'
        ),
      ]),
      mobilePhoneNumber: new FormControl(null, [
        requiredValidator('Please enter your mobile phone number.'),
        PhonenumberPrefixValidator(
          'Invalid phone number.',
          null,
          environment.productionMode
        ),
        fixedLengthValidator(10, 'Please enter full 10 digit phone number.'),
      ]),
      directMailCode: new FormControl(null, [
        // requiredValidator('Please enter Direct Mail / Invitation Code.'),
        patternValidator(
          /^[^\x80-\xFF]*$/,
          'Alphabetical or numerical characters only please.'
        ),
      ]),
    });

    this.get('ssn').setValidators([
      this.get('ssn').validator,
      patternValidator(
        this.ssnValidationPattern(),
        'Please enter a valid social security number.'
      ),
    ]);
  }

  private ssnValidationPattern(): RegExp {
    if (this.environment.productionMode) {
      return /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
    }
    return /^(?!219099999|078051120)(?!000)\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
  }

  private get zipCodeControl(): AbstractControl {
    return this.get('zipCode');
  }

  private get stateControl(): AbstractControl {
    return this.get('state');
  }

  private zipCodeSmartyStreetValidation(
    control: AbstractControl
  ): Observable<ValidationMessagesError> {
    this.zipCodeControl.setErrors(
      new ValidationMessagesError('smartyStreet', null, 'Getting your state')
    );

    return this.smartyStreetApi.lookupPrimaryZipcode(control.value).pipe(
      map((response: SmartyStreetLookupResponse) => {
        if (response.isValid) {
          const isZipCodeInMultipleStates = this.zipCodeHasMultipleStates(
            response.zipcode
          );

          if (response.zipcode && !isZipCodeInMultipleStates) {
            // set state value when smarty street returns a valid response
            this.stateControl.setValue(response.zipcode.state_abbreviation);
          } else {
            // cleaning state input
            this.stateControl.setValue(null);
          }

          return null;
        }

        return new ValidationMessagesError(
          'smartyStreet',
          null,
          'Please enter a valid zip code.'
        );
      }),
      catchError((err: any) => {
        return of(null);
      })
    );
  }

  private zipCodeHasMultipleStates(smStResponse: SmartyStreetZipCode): boolean {
    let isZipCodeInMultipleStates = false;

    if (smStResponse && smStResponse.alternate_counties) {
      // getting array of states in alternate_counties
      const alternateStates = smStResponse.alternate_counties.map(
        (countyElmnt: SmartyStreetZipCode) => countyElmnt.state_abbreviation
      );

      // adding the existing state outside alternate_counties
      alternateStates.push(smStResponse.state_abbreviation);
      // clearing repeated states
      const removeDuplicates = new Set(alternateStates);
      // checking if zip code is in more than one state
      isZipCodeInMultipleStates = removeDuplicates.size > 1;
    }

    return isZipCodeInMultipleStates;
  }

  private stateSmartyStreetValidation(
    control: AbstractControl
  ): Observable<ValidationMessagesError> {
    if (this.zipCodeControl.value && this.zipCodeControl.valid) {
      return this.smartyStreetApi
        .lookupPrimaryZipcode(this.zipCodeControl.value)
        .pipe(
          filter((response: SmartyStreetLookupResponse) => response.isValid),
          map((response: SmartyStreetLookupResponse) => {
            const statesInResponse = this.getCurrentStatesInZipcode(
              response.zipcode
            );

            if (!statesInResponse.includes(control.value)) {
              return new ValidationMessagesError(
                'smartyStreet',
                null,
                'The state you selected does not match the zip code you entered.'
              );
            }

            return null;
          }),
          catchError((err: any) => {
            return of(null);
          })
        );
    }

    return of(null);
  }

  private getCurrentStatesInZipcode(ssResponse: SmartyStreetZipCode): String[] {
    if (ssResponse && ssResponse.alternate_counties) {
      let statesArr: String[];
      statesArr = ssResponse.alternate_counties.map(
        (countyElmnt: SmartyStreetZipCode) => countyElmnt.state_abbreviation
      );
      statesArr.push(ssResponse.state_abbreviation);
      return Array.from(new Set(statesArr));
    } else {
      return [ssResponse.state_abbreviation];
    }
  }
}
