import { ModuleWithProviders, NgModule } from '@angular/core';
import { LocationProxy } from '@location-proxy/location-proxy.service';
import { TealiumClickDirective } from './directives/tealium-click.directive';
import { TealiumInputFocusDirective } from './directives/tealium-input-focus.directive';
import { TealiumPopupDirective } from './directives/tealium-popup.directive';
import { TealiumSelectChangeDirective } from './directives/tealium-select-change.directive';
import { FormFieldErrorTrackerDirective } from './form-field-error-tracker/form-field-error-tracker.directive';
import { TealiumConfiguration } from './tealium.configuration';
import { Tealium } from './tealium.service';

const exports = [
  FormFieldErrorTrackerDirective,
  TealiumInputFocusDirective,
  TealiumSelectChangeDirective,
  TealiumClickDirective,
  TealiumPopupDirective,
];

@NgModule({
  declarations: [...exports],
  exports: exports,
  providers: [LocationProxy],
})
export class TealiumModule {
  public static forRoot(): ModuleWithProviders<TealiumModule> {
    return {
      ngModule: TealiumModule,
      providers: [Tealium, TealiumConfiguration],
    };
  }
}
