import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ApplicationApi,
  ApplicationCreateResponse,
  CreateApplicationRequest,
} from '@app/acquisition/application/application.api';
import { ApplicationForm } from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
// tslint:disable: max-line-length
import { EmailOnlyMatchModalComponent } from '@app/acquisition/apply/getting-started/email-only-match-modal/email-only-match-modal.component';
import { MaintenanceService } from '@app/acquisition/maintenance/services/maintenance.service';
import { LogRocketService } from '@app/acquisition/services/log-rocket/log-rocket.service';
import { NeuroIdService } from '@app/acquisition/services/neuro-id/neuro-id.service';
import {
  SessionStorageKeys,
  SessionStorageObjects,
  SessionStorageService,
} from '@app/acquisition/services/session-storage-service/session-storage.service';
import { UserInactivityStatusService } from '@app/acquisition/shared-module/inactivity-module/user-inactivity.service';
import { IPThresholdModalComponent } from '@app/acquisition/shared-module/ip-threshold-modal/ip-threshold-modal.component';
import { SpinnerModalComponent } from '@app/acquisition/shared-module/spinner-modal/spinner-modal.component';
import { AppConfigService } from '@app/appconfig/appconfig.service';
import { CookieService } from '@app/cookie/cookie-service';
import { ReCaptchaService } from '@app/recaptcha/recaptcha.service';
import {
  Tealium,
  TealiumEvent,
  TealiumVariables,
  GoogleTagManagerData,
  GoogleTagEvent,
} from '@app/tealium/tealium.service';
import { State, states } from '@state/state';
import { patternValidator } from '@validation/validators/pattern.validator';
import { requiredValidator } from '@validation/validators/required.validator';
import * as moment from 'moment';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  ApplicantResumeApplicationData,
  ApplicationSearchModel,
} from '../services/otp-resume-app/otp.models';
import {
  OtpService,
  StartOptions,
  StartOptionsResponse,
} from '../services/otp-resume-app/otp.service';
import { StateOptionsDataService } from '../state-options-api/state-options-data.service';
import {
  StateOptionsApi,
  StateOptionsApiResponse,
} from '../state-options-api/state-options.api';
import { GettingStartedDebugService } from './debug-hud/getting-started-debug.service';
import {
  AbTestingData,
  GettingStarted,
  GettingStartedFormGroup,
  ShortFormData,
} from './form/getting-started.form';
import {
  MonetizationData,
  MonetizationModalComponent,
} from './monetization-modal/monetization-modal.component';
import { NlsModalComponent } from './nls-login-modal/nls-modal.component';
import { ResumeApplicationModalComponent } from './resume-application-modal/resume-application-modal.component';
import {
  StateMessageApi,
  StateMessageResponseApi,
} from './state-message-api/state-message-api';
import { StateMessageModalComponent } from './state-message-modal/state-message-modal.component';
import { V11NavigationModalComponent } from './v11-navigation-modal/v11-navigation-modal.component';
import { RedirectToBpModalComponent } from './redirect-to-bp-modal/redirect-to-bp-modal.component';
import { error } from 'protractor';
import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';
import { Environment } from '@app/environment/environment.module';
@Component({
  selector: 'rise-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  providers: [GettingStartedFormGroup, GettingStartedDebugService],
})
export class GettingStartedComponent implements OnInit, OnDestroy {
  private get recaptchaChallenge(): Observable<string> {
    this.dialogRef.close();
    return this.debugService.isReCaptchaEnabled
      ? this.reCaptchaService.execute()
      : of('skip-recaptcha');
  }
  public allStates: State[] = states;
  public isV11State: Boolean;
  public ssnHiddenInput = false;
  public ssnMaskPattern = '000-00-0000';
  public nlsLookupSubscription: Subscription;
  public isMobile = false;
  public lowInterestState = false;
  public directMailToolTip = 'test';
  public verifyRecaptchaToken: string;
  public abTestingFromRedirect?: AbTestingData[];
  public stateFromRedirect?: string;
  private lowInterestVisibleSubscription: Subscription;
  private stateChangeSubscription: Subscription;
  private currentStateInfo: StateOptionsApiResponse;
  private monetizationModalSubscription: Subscription;
  private resumeApplicationModalSubscription: Subscription;
  private dialogRef: MatDialogRef<SpinnerModalComponent>;
  @ViewChild('zipcodeInput')
  private zipcodeInput: ElementRef;
  private applicationIsBeingEdited = false;
  private initialFormValue: GettingStarted;
  private messagesPopupsCount = 0;
  private existingApplication: ApplicationForm;
  private authorizationToken = null;

  constructor(
    public form: GettingStartedFormGroup,
    public debugService: GettingStartedDebugService,
    private neuroIdService: NeuroIdService,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private otpResumeApplicationService: OtpService,
    private userAppStarted: UserInactivityStatusService,
    private dialog: MatDialog,
    private router: Router,
    private stateOptionsApi: StateOptionsApi,
    private stateMessage: StateMessageApi,
    private reCaptchaService: ReCaptchaService,
    private stateOptionsDataService: StateOptionsDataService,
    private sessionStorageService: SessionStorageService,
    private logRocketService: LogRocketService,
    private maintenance: MaintenanceService,
    private tealium: Tealium,
    private cookieService: CookieService,
    private appCopnfigService: AppConfigService,
    private environment: Environment
  ) {
    this.lowInterestVisibleSubscription = this.appCopnfigService
      .showLowInterestState()
      .subscribe((isLowInterest: boolean) => {
        this.lowInterestState = isLowInterest;
        this.updateRequiredFields(isLowInterest);
      });
    this.updateRequiredFields(this.lowInterestState);
  }

  public ngOnInit(): void {
    if (!this.checkRedirectDataAndPrepopulate()) {
      this.checkShortFormDataAndPrepopulate();
    }
    const tealiumVariables: TealiumVariables = {};
    const queryParams = this.sessionStorageService.getSessionStorageObject(
      SessionStorageObjects.queryParameters
    );

    if (this.otpResumeApplicationService.isUserLocked()) {
      this.dialog.open(IPThresholdModalComponent, {
        panelClass: 'rise-modal-small',
        disableClose: true,
      });
      return;
    }

    const cookieAppID = this.cookieService.get('resumeByAppID');

    if (cookieAppID) {
      const spinner = this.dialog.open(SpinnerModalComponent, {
        panelClass: 'rise-saving-modal',
        disableClose: true,
        closeOnNavigation: false,
      });

      this.otpResumeApplicationService
        .lookupByID({
          applicationId: cookieAppID,
        })
        .subscribe(
          (startOptionResponse: StartOptionsResponse) => {
            if (
              startOptionResponse.startOptions === StartOptions.ResumeOnly ||
              startOptionResponse.startOptions === StartOptions.ResumeOrStartNew
            ) {
              this.tealium.popUpEvent('Welcome-back');
              startOptionResponse.startOptions = StartOptions.ResumeOrStartNew;
              spinner.close();
              this.dialog
                .open(ResumeApplicationModalComponent, {
                  panelClass: 'rise-modal-small',
                  disableClose: true,
                  data: Object.assign(startOptionResponse, {
                    applicationId: cookieAppID,
                  }),
                })
                .afterClosed()
                .subscribe((data: ApplicantResumeApplicationData) => {
                  if (data) {
                    if (!data.resumeApplication) {
                      this.cookieService.remove('resumeByAppID');
                    }
                  }
                });
            } else {
              this.cookieService.remove('resumeByAppID');
            }
          },
          (error: any) => {
            this.cookieService.remove('resumeByApp');
            this.dialog.closeAll();
          }
        );
    }
    this.existingApplication = this.applicationDataService.get();
    this.authorizationToken = this.sessionStorageService.getSessionStorageItem(
      SessionStorageKeys.authorizationToken
    );
    this.stateChangeSubscription = this.form
      .get('state')
      .valueChanges.subscribe((val: string) => {
        this.appCopnfigService.getLowInterestState(val);

        this.checkForStateMessagePopUp(val);
        this.stateOptionsApi.getStateOptions(val).subscribe(
          (response: StateOptionsApiResponse) => {
            this.currentStateInfo = {
              ...response,
              stateCode: val,
            };
            this.sessionStorageService.setSessionStorageObject(
              SessionStorageObjects.stateOptions,
              response
            );
            if (!response.isLoanServiced) {
              this.showMonetization();
            }
          },
          () => {
            this.currentStateInfo = {
              stateCode: val,
              stateName: 'Unknown',
              isLoanServiced: true,
              isRiseState: true,
              isFinWiseState: false,
            };
          }
        );
      });

    if (
      this.existingApplication !== undefined &&
      this.authorizationToken !== null
    ) {
      this.applicationIsBeingEdited = true;
      this.prepopulateForm(this.existingApplication);
      this.hideSSNInput();
    }

    if (!this.applicationIsBeingEdited) {
      this.userAppStarted.setUserApplicationStatus(false);
    }

    this.tealium.setTealiumVariables({
      page_name: 'getting-started',
    });

    if (queryParams && queryParams.params) {
      const gcidcookieName = this.environment.cookie.gcid.name;
      if (queryParams.params.gcid) {
        tealiumVariables.gcid = queryParams.params.gcid;
        this.cookieService.set(gcidcookieName, queryParams.params.gcid, {
          expires: this.environment.cookie.gcid.empiryInDays,
          domain: this.environment.cookie.gcid.domain,
        });
      } else {
        const gcidCookieValue = this.cookieService.get(gcidcookieName);
        tealiumVariables.gcid = gcidCookieValue;
        this.sessionStorageService.setSessionStorageItem(
          SessionStorageKeys.gcidCookieValue,
          gcidCookieValue
        );
      }

      if (queryParams.params.referralUrl) {
        tealiumVariables.full_referral_url = queryParams.params.referralUrl;
      }

      this.tealium.setTealiumVariables({
        ...tealiumVariables,
      });

      this.tealium.trackDataLoaded('Query-String-Read');
    }
    if (window.screen.width < 1024) {
      this.isMobile = true;
    }
  }

  public ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
  }

  public hideSSNInput(): void {
    this.ssnHiddenInput = true;
    this.ssnMaskPattern = 'XXXXXXXXXXX';
  }

  public showSSNInput(): void {
    this.ssnHiddenInput = false;
    this.ssnMaskPattern = '000-00-0000';
  }

  public checkForStateMessagePopUp(state: string): void {
    if (this.applicationIsBeingEdited && this.messagesPopupsCount < 1) {
      this.messagesPopupsCount++;
      return;
    }

    const response: StateMessageResponseApi =
      this.stateMessage.getStateMessage(state);

    if (response) {
      if (!this.currentStateInfo) {
        this.dialog.open(StateMessageModalComponent, {
          panelClass: 'rise-modal-medium',
          data: response,
        });
      } else if (this.currentStateInfo.stateCode !== state) {
        this.dialog.open(StateMessageModalComponent, {
          panelClass: 'rise-modal-medium',
          data: response,
        });
      }
    }
  }

  public submit(): void {
    if (this.maintenance.checkMaintenance()) {
      return;
    }
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    if (!this.currentStateInfo?.isLoanServiced) {
      this.showMonetization();
      return;
    }

    this.dialogRef = this.dialog.open(SpinnerModalComponent, {
      panelClass: 'rise-saving-modal',
      disableClose: true,
      closeOnNavigation: false,
    });

    if (!this.applicationIsBeingEdited || this.ssnOrEmailChanged) {
      const startOptionsRequest: ApplicationSearchModel = {
        socialSecurityNumber: this.form.value.ssn,
        email: this.form.value.email,
        applicationFlow: 'OrganicPrequal',
        stateCode: this.form.value.state,
      };

      if (
        this.abTestingFromRedirect != null &&
        this.stateFromRedirect == this.form.value.state
      ) {
        startOptionsRequest.abTesting = this.abTestingFromRedirect;
      }

      this.otpResumeApplicationService.lookup(startOptionsRequest).subscribe(
        (startOptionsResponse: StartOptionsResponse) => {
          if (
            startOptionsResponse.startOptions === StartOptions.RedirectToBau ||
            startOptionsResponse.startOptions ===
            StartOptions.RedirectFormerRefinanceSignIn
          ) {
            this.dialogRef.close();
            console.log(
              'Redirect to BAU forced by api; reason: ' +
              startOptionsResponse.reason
            );
            this.dialog.open(NlsModalComponent, {
              panelClass: 'rise-modal-small',
              disableClose: true,
              data: {
                email: this.form.value.email,
              },
            });
          } else if (
            startOptionsResponse.startOptions === StartOptions.ResumeOnly ||
            startOptionsResponse.startOptions === StartOptions.ResumeOrStartNew
          ) {
            this.validateRequiredFields(this.lowInterestState);
            this.dialogRef.close();
            this.tealium.popUpEvent('Welcome-back');
            const otpResumeApplicationModal = this.dialog.open(
              ResumeApplicationModalComponent,
              {
                panelClass: 'rise-modal-small',
                disableClose: true,
                data: Object.assign(startOptionsResponse, startOptionsRequest),
              }
            );
            this.resumeApplicationModalSubscription = otpResumeApplicationModal
              .afterClosed()
              .subscribe((data: ApplicantResumeApplicationData) => {
                if (data) {
                  if (!data.resumeApplication) {
                    this.resolveSubmitApplication();
                  }
                }
                this.resumeApplicationModalSubscription.unsubscribe();
              });
          } else if (
            startOptionsResponse.startOptions === StartOptions.NoOptions
          ) {
            this.validateRequiredFields(this.lowInterestState);
            if (
              this.otpResumeApplicationService.getEmailAttemptCount(
                startOptionsRequest.email
              ) >= 4
            ) {
              this.dialogRef.close();
              this.dialog.open(IPThresholdModalComponent, {
                panelClass: 'rise-modal-small',
                disableClose: true,
              });
              this.otpResumeApplicationService.lockUser();
            } else {
              this.otpResumeApplicationService.logEmailOnlyAttempt(
                startOptionsRequest.email
              );
              this.dialogRef.close();
              this.dialog.open(EmailOnlyMatchModalComponent, {
                panelClass: 'rise-modal-small',
              });
            }
          } else if (
            startOptionsResponse.startOptions ===
            StartOptions.RedirectToBlueprint
          ) {
            this.dialogRef.close();
            console.log(
              'Redirect to Blueprint Platform forced by api; reason: ' +
              startOptionsResponse.reason
            );
            let data = this.form?.value;
            data.abTesting = startOptionsResponse?.abTesting;

            // add logrocket ab testing data 
            data.abTesting?.forEach(abdata => {
              this.logRocketService.addAbTestingData(abdata.testName, abdata.segmentName);
            })


            this.dialog.open(RedirectToBpModalComponent, {
              panelClass: 'rise-modal-small',
              disableClose: true,
              data: data,
            });
          } else {
            this.resolveSubmitApplication();
          }
        },
        (error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError.error.message.includes('is not Serviced')) {
            this.dialog.closeAll();
            this.showMonetization();
          } else {
            this.dialog.closeAll();
            this.router.navigate(['../error']);
          }
        }
      );
    } else {
      try {
        this.resolveSubmitApplication();
      } catch (error) {
        const httpError = error as HttpErrorResponse;
        if (httpError.error.message.includes('is not Serviced')) {
          this.dialog.closeAll();
          this.showMonetization();
        } else {
          this.router.navigate(['../error']);
        }
      }
    }
  }

  public resolveSubmitApplication(): void {
    if (!this.validateRequiredFields(this.lowInterestState)) {
      const applicationFormData: ApplicationForm =
        this.createApplicationRequest(this.form.value);

      if (this.applicationIsBeingEdited && this.authorizationToken !== null) {
        if (this.applicationHasChanges) {
          this.callToApendApplication(applicationFormData);
          return;
        }
        this.navigateToMoreInfo();
        return;
      }

      this.recaptchaChallenge.subscribe((token: string) => {
        this.verifyRecaptchaToken = token;
        this.dialogRef = this.dialog.open(SpinnerModalComponent, {
          panelClass: 'rise-saving-modal',
          disableClose: true,
          closeOnNavigation: false,
        });
        this.callToCreateApplication(applicationFormData);
      });
    }
    this.dialogRef.close();
  }

  public resetEc(): void {
    this.updateRequiredFields(this.lowInterestState);
  }

  private navigateToMoreInfo(): void {
    this.tealium.trackPageSuccessEvent('Continue-Getting-Started-Success');
    this.router.navigate(['more-info']);
    this.dialogRef.close();
  }

  private get ssnOrEmailChanged(): boolean {
    const currentFormValue = JSON.stringify([
      this.form.value.ssn,
      this.form.value.email,
    ]);
    const initialFormValue = JSON.stringify([
      this.initialFormValue.ssn,
      this.initialFormValue.email,
    ]);
    return initialFormValue !== currentFormValue;
  }

  private get applicationHasChanges(): boolean {
    const currentFormValue = JSON.stringify(this.form.value);
    const initialFormValue = JSON.stringify(this.initialFormValue);
    return initialFormValue !== currentFormValue;
  }

  private callToApendApplication(
    applicationCreateRequest: ApplicationForm
  ): void {
    applicationCreateRequest.reEvaluateABTesting = true;
    this.applicationApi.append(applicationCreateRequest).subscribe(
      (response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.applicationDataService.merge(
            {
              form: { ...applicationCreateRequest },
            },
            false
          );

          this.logRocketService.addSearchableFields();
          this.updateTealiumVariables();
          this.navigateToMoreInfo();
        } else if (response.status === 200) {
          if (
            response.body?.value?.startOptions ===
            StartOptions.RedirectToBlueprint
          ) {
            this.handleRedirectToBlueprint(response);
          }
        }
      },
      (error) => {
        if (error.error.message.includes('is not Serviced')) {
          var stateResDataFromError =
            error.error.message.split('StateResourceData:');
          var stateResData = JSON.parse(stateResDataFromError[1]);

          this.currentStateInfo.monetizationUrlDecline =
            stateResData.value.monetizationUrlDecline;
          this.currentStateInfo.stateName = stateResData.value.stateName;
          this.dialog.closeAll();
          this.showMonetization();
        } else {
          throw error;
        }
      }
    );
  }

  private handleRedirectToBlueprint(response: HttpResponse<any>): void {
    this.dialogRef.close();
    console.log(
      'Redirect to Blueprint Platform forced by api; reason: ' +
      response.body?.value?.reason
    );
    let data = this.form?.value;
    data.abTesting = response.body?.value?.abTesting;
    // add logrocket ab testing data 
    data.abTesting?.forEach(abdata => {
      this.logRocketService.addAbTestingData(abdata.testName, abdata.segmentName);
    })

    this.dialog.open(RedirectToBpModalComponent, {
      panelClass: 'rise-modal-small',
      disableClose: true,
      data: data,
    });
  }
  private callToCreateApplication(applicationFormData: ApplicationForm): void {
    const createApplicationRequest: CreateApplicationRequest = {
      brand: 'Rise',
      applicationFlow: 'OrganicPrequal',
      form: applicationFormData,
      recaptchaToken: this.verifyRecaptchaToken,
    };

    this.applicationApi
      .create(createApplicationRequest)
      .pipe(take(1))
      .subscribe(
        (applicationResponse: ApplicationCreateResponse) => {
          this.applicationDataService.replaceApplicationForm({
            form: { ...applicationFormData },
          });

          this.sessionStorageService.setSessionStorageItem(
            SessionStorageKeys.authorizationToken,
            applicationResponse.token
          );

          this.logRocketService.addSearchableFields();

          this.userAppStarted.setUserApplicationStatus(true);
          this.updateTealiumVariables();
          this.navigateToMoreInfo();
        },
        (error) => {
          if (error.error.message.includes('is not Serviced')) {
            var stateResDataFromError =
              error.error.message.split('StateResourceData:');
            var stateResData = JSON.parse(stateResDataFromError[1]);

            this.currentStateInfo.monetizationUrlDecline =
              stateResData.value.monetizationUrlDecline;
            this.currentStateInfo.stateName = stateResData.value.stateName;
            this.dialog.closeAll();
            this.showMonetization();
          } else {
            throw error;
          }
        }
      );
  }

  private showMonetization(): void {
    const monetizationData: MonetizationData = {
      monetizationUrl: this.currentStateInfo?.monetizationUrlDecline,
      stateName: this.currentStateInfo?.stateName,
      formElements: {
        zipcodeInput: this.zipcodeInput,
        getStartedform: this.form,
      },
    };

    this.monetizationModalSubscription = this.dialog
      .open(MonetizationModalComponent, {
        panelClass: 'rise-modal-medium',
        data: monetizationData,
        restoreFocus: false,
      })
      .backdropClick()
      .subscribe(() => {
        this.monetizationModalSubscription.unsubscribe();
      });
  }

  private prepopulateForm(currentApplication: ApplicationForm): void {
    const ssnToPopulate =
      currentApplication.applicant.identity.socialSecurityNumber.includes(
        'XXXXX'
      )
        ? null
        : currentApplication.applicant.identity.socialSecurityNumber;
    setTimeout(() => {
      this.form.patchValue({
        firstName: currentApplication.applicant.identity.firstName,
        lastName: currentApplication.applicant.identity.lastName,
        zipCode: currentApplication.applicant.residences[0].address.zipCode,
        ssn: ssnToPopulate,
        email: currentApplication.applicant.emails[0].address,
        mobilePhoneNumber: currentApplication.applicant.phones[0].number,
        directMailCode: currentApplication.enrollmentCode,
      });
      const currentStateOnApplication =
        this.applicationDataService.get().applicant.residences[0].address
          .stateCode;
      this.initialFormValue = this.form.value;
      this.initialFormValue.state = currentStateOnApplication;
    });
  }

  private updateTealiumVariables(): void {
    const variables: TealiumVariables = {
      customer_email: this.form.value.email,
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      state: this.form.value.state,
      application_start_date: moment.utc().format(),
      neuroId: this.neuroIdService.getNeuroSessionId(),
    };
    if (this.form.value.directMailCode) {
      variables.enrollment_code = this.form.value.directMailCode;
    }
    this.tealium.setTealiumVariables(variables);
  }

  private createApplicationRequest(formData: GettingStarted): ApplicationForm {
    let application: ApplicationForm;
    application = {
      applicant: {
        identity: {
          socialSecurityNumber: formData.ssn,
          firstName: formData.firstName,
          lastName: formData.lastName,
        },
        emails: [
          {
            key: '1',
            address: formData.email,
            type: 'Personal',
          },
        ],
        phones: [
          {
            key: '1',
            number: formData.mobilePhoneNumber,
            type: 'Mobile',
          },
        ],
        residences: [
          {
            key: '1',
            type: 'Current',
            address: {
              zipCode: formData.zipCode,
              stateCode: formData.state,
            },
          },
        ],
      },
    };

    if (formData.directMailCode) {
      if (formData.directMailCode.trim()) {
        application.enrollmentCode = formData.directMailCode.trim();
      }
    } else {
      application.enrollmentCode = ' ';
    }

    return application;
  }

  private checkShortFormDataAndPrepopulate(): void {
    const queryParams = this.sessionStorageService.getSessionStorageObject(
      SessionStorageObjects.queryParameters
    );

    if (queryParams &&
      queryParams?.['queryString'] &&
      queryParams?.['queryString']?.includes('?sf=')) {
      var shortAppDataString = queryParams?.['queryString']?.replace(
        '?sf=',
        ''
      );
      const shortformDataObject: ShortFormData = JSON.parse(
        atob(decodeURIComponent(shortAppDataString))
      ) as ShortFormData;
      this.form.patchValue(shortformDataObject);
    }
  }

  private checkRedirectDataAndPrepopulate(): boolean {
    const queryParams = this.sessionStorageService.getSessionStorageObject(
      SessionStorageObjects.queryParameters
    );
    if (
      queryParams &&
      queryParams?.['queryString'] &&
      queryParams?.['queryString']?.includes('?redirect=')
    ) {
      var redirectString = queryParams?.['queryString']?.replace(
        '?redirect=',
        ''
      );
      const redirectDataObject: any = JSON.parse(
        atob(decodeURIComponent(redirectString))
      ) as GettingStarted;

      const tealiumVars: TealiumVariables = {
        state: redirectDataObject?.state,
      };
      this.tealium.setTealiumVariables(tealiumVars);
      const redirectionEvent: GoogleTagManagerData = {
        event_name: 'BPToLegacyRiseRedirection',
      };

      this.tealium.gtmPushData(redirectionEvent);
      if (redirectDataObject?.abTesting) {
        this.abTestingFromRedirect = redirectDataObject?.abTesting;
        this.stateFromRedirect = redirectDataObject?.state;

      }
      this.form.patchValue(redirectDataObject);
      delete queryParams.params.redirect;
      this.sessionStorageService.setSessionStorageObject(
        SessionStorageObjects.queryParameters,
        queryParams
      );
      return true;
    }
    return false;
  }

  private updateRequiredFields(lowIntState: boolean): void {
    const reqValidator = requiredValidator(
      'Please enter Direct Mail / Invitation Code.'
    );
    const dmPatternValidator = patternValidator(
      /^[^\x80-\xFF]*$/,
      'Alphabetical or numerical characters only please.'
    );
    const validators = [];
    const directMailControl = this.form.get('directMailCode');
    if (lowIntState) {
      this.directMailToolTip = `You can find your invitation code on your offer letter.`;
      validators.push(reqValidator);
    } else {
      this.directMailToolTip = `You can find your invitation code on your offer letter. No code?
         No worries! Just leave this field blank and continue with your application.`;
      validators.push(dmPatternValidator);
    }
    directMailControl.clearValidators();
    // directMailControl.setValidators(validators);
    directMailControl.updateValueAndValidity();
  }

  private validateRequiredFields(lowIntState: boolean): boolean {
    let retValue = false;
    const reqValidator = requiredValidator(
      'Please enter Direct Mail / Invitation Code.'
    );
    const dmPatternValidator = patternValidator(
      /^[^\x80-\xFF]*$/,
      'Alphabetical or numerical characters only please.'
    );
    // match nothing
    const dmNoPatternValidator = patternValidator(/\b\B/, 'Invalid code.');
    const validators = [];
    const directMailControl = this.form.get('directMailCode');
    if (lowIntState) {
      this.directMailToolTip = `You can find your invitation code on your offer letter.`;
      validators.push(reqValidator);
      validators.push(dmNoPatternValidator);
      retValue = true;
    } else {
      this.directMailToolTip = `You can find your invitation code on your offer letter. No code?
         No worries! Just leave this field blank and continue with your application.`;
      validators.push(dmPatternValidator);
    }
    directMailControl.clearValidators();
    directMailControl.setValidators(validators);
    directMailControl.updateValueAndValidity();

    return retValue;
  }
}
