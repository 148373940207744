import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export function userNamePatternValidator(
  allowedPattern: RegExp,
  userNamevalidationName: string,
  text: string = '! The special characters you entered are invalid.',
  rules?: Rule[]
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors {
    if (!control.value || !allowedPattern.test(control.value)) {
      return new ValidationMessagesError(userNamevalidationName, rules, text);
    } else {
      return null;
    }
  };
}
