import { Injectable } from '@angular/core';
import {
  AppConfigService,
  AzureAppConfingFlags,
} from '@app/appconfig/appconfig.service';
import { Subscription } from 'rxjs';
import { StateOptionsApiResponse } from './state-options.api';
@Injectable()
export class StateOptionsDataService {
  public routeV11States = [
    'AK',
    'AZ',
    'FL',
    'HI',
    'IN',
    'KY',
    'LA',
    'MI',
    'MN',
    'MT',
    'NE',
    'NV',
    'OH',
    'OK',
    'OR',
    'SD',
    'WA',
    'WY',
  ];

  private stateOptions: StateOptionsApiResponse;
  private azureAppFlags: Map<string, boolean>;
  constructor(private appConfigService: AppConfigService) {
    this.appConfigService.azureAppFlagSubject.subscribe(
      (flagset: Map<string, boolean>) => {
        this.azureAppFlags = flagset;
      }
    );
  }

  public set(options: StateOptionsApiResponse): void {
    this.stateOptions = options;
  }

  public get(): StateOptionsApiResponse {
    return this.stateOptions;
  }
}
