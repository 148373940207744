import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-thirdparty-disclaimer-modal',
  templateUrl: './thirdparty-disclaimer-modal.component.html',
  styleUrls: ['./thirdparty-disclaimer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThirdPartyDisclaimerModalComponent {
  @Output() public continueButtonClicked = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ThirdPartyDisclaimerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public hyperlink?: string
  ) {}

  public close(): void {
    this.dialogRef.close(false);
    this.continueButtonClicked.emit(false);
  }

  public continue(): void {
    this.dialogRef.close(true);
    if (this.hyperlink != null && this.hyperlink != '') {
      window.open(this.hyperlink, '_blank');
    }

    this.continueButtonClicked.emit(true);
  }
}
