import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnvironmentModule } from '@environment/environment.module';
import { TealiumModule } from '@tealium/tealium.module';

import { MatDialogModule } from '@angular/material/dialog';
import { AppInsightService } from '@app/acquisition/services/app-insights/app-insights.service';
import { GpcService } from '@app/gpc/services/gpc.service';
import { CookieService } from '@cookie/cookie-service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NeuroIdService } from './acquisition/services/neuro-id/neuro-id.service';
import { SharedModule } from './acquisition/shared-module/shared-module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiErrorHandlerInterceptor } from './interceptor/api-error-handler-interceptor/api-error-handler.interceptor';
import { ApiVersionInterceptor } from './interceptor/api-version-interceptor/api-version.interceptor';
import { AuthTokenInterceptor } from './interceptor/authorization-token-interceptor/auth-token.interceptor';
import { IovationService } from './iovation/iovation.service';
import { ScriptLoader } from './script-loader.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    EnvironmentModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    MatDialogModule,
    SharedModule,
    TealiumModule.forRoot(),
  ],
  declarations: [AppComponent],
  providers: [
    ScriptLoader,
    IovationService,
    AppInsightService,
    CookieService,
    GpcService,
    NeuroIdService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorHandlerInterceptor,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-PBM5JNFL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('rise-theme');
  }
}
