import { Component, ElementRef } from '@angular/core';
import { Environment } from '@environment/environment.module';

export const linkTagComponentSelector = 'rise-link-tag';

@Component({
  selector: linkTagComponentSelector,
  templateUrl: './link-tag.component.html',
  styleUrls: ['./link-tag.component.scss'],
})
export class LinkTagComponent {
  public target: string;
  public id: string;
  public hrefTarget: string;
  public label: string;
  public tabindex: '-1';

  constructor(private el: ElementRef, private environment: Environment) {
    this.target = this.el.nativeElement.getAttribute('target');
    this.label = this.el.nativeElement.getAttribute('fieldName');
    this.id = this.el.nativeElement.getAttribute('id');

    const url = this.el.nativeElement.getAttribute('hrefTarget');

    this.hrefTarget = `${this.environment.url.home}${url}`;
  }
}
