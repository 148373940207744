import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  LoanAmountMinMax,
  LoanPaymentDataService,
} from '@app/acquisition/accept/services/loan-payment-data.service';
import { MaintenanceService } from '@app/acquisition/maintenance/services/maintenance.service';
import { maxAmountValidator } from '@app/validation/validators/max-amount.validator';
import { minAmountValidator } from '@app/validation/validators/min-amount.validator';
import { requiredValidator } from '@app/validation/validators/required.validator';

@Component({
  selector: 'rise-loan-amount-modal',
  templateUrl: './loan-amount-modal.component.html',
  styleUrls: ['./loan-amount-modal.component.scss'],
})
export class LoanAmountModalComponent {
  public form: FormGroup;
  public loanMinMax: LoanAmountMinMax;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public loanData: { loanAmount: number },
    private dialogRef: MatDialogRef<LoanAmountModalComponent>,
    private loanPaymentDataService: LoanPaymentDataService,
    private maintenance: MaintenanceService
  ) {
    this.loanMinMax = this.loanPaymentDataService.getLoanAmountMinMax();
    this.form = new FormGroup({
      loanAmount: new FormControl(this.loanData.loanAmount, [
        requiredValidator('Please enter a loan amount'),
        minAmountValidator(
          this.loanMinMax.minimum,
          'Amount entered is less than minimum amount available.'
        ),
        maxAmountValidator(
          this.loanMinMax.maximum,
          'Amount entered is greater than maximum amount available.'
        ),
      ]),
    });
  }

  public submit(): void {
    if (this.maintenance.checkMaintenance()) {
      return;
    }
    if (this.form.valid) {
      if (+this.form.get('loanAmount').value !== this.loanData.loanAmount) {
        this.dialogRef.close(+this.form.get('loanAmount').value);
      } else {
        this.dialogRef.close();
      }
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
