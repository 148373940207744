<div mat-dialog-title class="dialog-header">
  <p class="modal-title-text">Welcome to our updated application</p>
</div>
<mat-dialog-content class="mat-typography">
  <div>
    <p>
      Our application process is getting an upgrade. You might notice the pages look a bit different as we update its
      design, but rest assured your application progress has been saved.
    </p>
  </div>
</mat-dialog-content>