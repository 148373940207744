import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentOptionItem } from '@app/acquisition/accept/models/payment-option-item.interface';
import { LoanPaymentDataService } from '@app/acquisition/accept/services/loan-payment-data.service';
import { UnderwritingOfferModel } from '@app/acquisition/application/application.models';
import { Subscription } from 'rxjs';
import { LoanAmountModalComponent } from '../loan-amount-modal/loan-amount-modal.component';

export interface OriginationFeeConfiguration {
  originationFeePercentage?: number;
  originationFee?: number;
  isloanOriginationFeeApplicable?: boolean;
  originationFeeCapDollarAmount?: number;
}
@Component({
  selector: 'rise-loan-amount-card',
  templateUrl: './loan-amount-card.component.html',
  styleUrls: ['../choose-your-terms-installment.component.scss'],
})
export class LoanAmountCardComponent implements OnDestroy {
  public loanAmount: number;
  public apr: number;
  @Input() public payoffAmount: number;
  @Input() public originationFeeConfig: OriginationFeeConfiguration;
  public originationFeePercentageString = '';
  private loanAmountSubscription: Subscription;
  private paymentScheduleSubscription: Subscription;
  private loanChangeModalSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private loanPaymentDataService: LoanPaymentDataService
  ) {
    this.loanAmountSubscription = this.loanPaymentDataService
      .getLoanAmountSubject()
      .subscribe((offer: UnderwritingOfferModel) => {
        this.loanAmount = offer.line;
      });
    this.paymentScheduleSubscription = this.loanPaymentDataService
      .getPaymentScheduleSubject()
      .subscribe((schedule: PaymentOptionItem) => {
        this.apr = schedule.paymentSchedule.length > 0 ? schedule.tilaApr : 0;
      });
  }

  public openLoanAmountModal(): void {
    this.loanChangeModalSubscription = this.dialog
      .open(LoanAmountModalComponent, {
        panelClass: 'rise-modal-small',
        data: { loanAmount: this.loanAmount },
      })
      .afterClosed()
      .subscribe((loanAmount: number) => {
        if (loanAmount) {
          this.updateLoanAmount(loanAmount);
        }
      });
  }

  public ngOnDestroy(): void {
    this.loanAmountSubscription.unsubscribe();
    this.paymentScheduleSubscription.unsubscribe();
    if (this.loanChangeModalSubscription) {
      this.loanChangeModalSubscription.unsubscribe();
    }
  }

  private updateLoanAmount(loanAmount: number): void {
    this.loanPaymentDataService.updateLoanAmount(
      loanAmount,
      this.calculateOriginationFee(
        this.originationFeeConfig.originationFeeCapDollarAmount,
        this.originationFeeConfig.originationFeePercentage,
        loanAmount
      )
    );
  }

  private calculateOriginationFee(
    originationFeeCap,
    originationFeePercentage,
    loanAmount
  ): number {
    // Orgination Fee Calculation
    let originationFee = (loanAmount / 100) * originationFeePercentage;
    if (originationFee > originationFeeCap) {
      originationFee = originationFeeCap;
    }
    return originationFee;
  }
}
