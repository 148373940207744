<div class="modal-body" id="thirdpartyDisclaimerModalWindow">
  <h1 class="thirdparty-disclaimer-modal-header"><b>Third Party Site Disclaimer</b></h1>

  <div class="thirdparty-disclaimer-modal-body">
    <p>
      By accessing the noted link you will be leaving <i>RISE</i>credit.com and
      entering a website hosted by another party. Please be advised that you
      will no longer be subject to, or under the protection of the privacy and
      security policies of <i>RISE</i>credit.com. We encourage you to read and evaluate
      the privacy and security policies of the site you are entering, which may
      be different than those of <i>RISE</i>credit.com.
    </p>
  </div>

  <div class="thirdparty-disclaimer-modal-button-container">
    <button
      ngbAutofocus
      id="thirdpartyDisclaimerModalContinueButton"
      class="ecl-button-primary"
      (click)="continue()"
    >
      CONTINUE
    </button>
    <button 
      id="thirdpartyDisclaimerModalCancelButton"
      class="ecl-button-secondary"
      (click)="close()"
    >
      GO BACK
    </button>
  </div>
</div>
