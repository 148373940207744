import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const minAmountValidatorName = 'minAmount';

export function minAmountValidator(
  minimum: number,
  text: string = 'Less than minimum',
  rules?: Rule[]
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors {
    if ([undefined, null, ''].includes(control.value)) {
      return null;
    }

    const num = Number(control.value);

    if (num >= minimum) {
      return null;
    }

    return new ValidationMessagesError(minAmountValidatorName, rules, text);
  };
}
