import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rise-browser-navigation-restriction',
  templateUrl: './browser-navigation-restriction.modal.component.html',
  styleUrls: ['./browser-navigation-restriction.modal.component.scss'],
})
export class BrowserNavigationRestrictionModalComponent {
  public message: String;
  constructor(
    public dialogRef: MatDialogRef<BrowserNavigationRestrictionModalComponent>
  ) {}

  public accept(): void {
    this.dialogRef.close(true);
  }

  public decline(): void {
    this.dialogRef.close(false);
  }
}
