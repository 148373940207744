<div id="loadingPageWaitTextContainer" *ngIf="!isAnimating">
  <div id="loadingPageWaitText1">Please wait.</div>
  <div id="loadingPageWaitText2">Thanks for your patience.</div>
</div>
<div id="loadingAnimationContainer" *ngIf="isAnimating">
  <div id="loadingAnimationDiv">
    <img id="loadingCheckmark" src="assets/images/loading_checkmark.svg" />
    <svg height="100" width="100" id="animatedArc" [style.strokeDashoffset]="loadingAnimationOffset">
      <circle cx="50" cy="50" r="47" />
    </svg>
    <svg height="100" width="100" id="backgroundCircle">
      <circle cx="50" cy="50" r="47" />
    </svg>
  </div>
  <div id="gettingYourDataText">Processing...</div>
</div>