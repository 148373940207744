<div class="dialog-header">
  <img
    id="closeImage"
    class="close-img"
    src="assets/images/button_close.svg"
    (click)="closeModal()"
  />
</div>
<div mat-dialog-title id="bankInfoMiTitle" class="dialog-header">
  <p>Change your payment options</p>
</div>
<mat-dialog-content id="bankInfoMiUsageContent" class="mat-typography">
  <p>Select one of the available payment options below:</p>
  <div id="radioGroupContainerDiv" [formGroup]="form">
    <mat-radio-group
      id="paymentOptionsRadioGroup"
      formControlName="selectedOption"
    >
      <mat-radio-button
        *ngFor="let option of optionsArray"
        [value]="option.indexRef"
        [id]="'PaymentOptionsModalOption' + option.indexRef"
      >
        <div class="payment-radio-label">
          {{ option.numPayments }} payments of
          {{ option.paymentAmount | currency }}
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div id="paymentOptionReminder">As a reminder: You can always log into your account to increase the amount of your payments in order to pay off your loan faster. There are no fees or penalties for early loan payments!</div>

  <button
    mat-flat-button
    id="paymentOptionsSubmitButton"
    type="submit"
    class="rise-submit-button"
    (click)="saveOption()"
    tealiumClick="Change-Payment-Options"
  >
    Save
  </button>
</mat-dialog-content>
