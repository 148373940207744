import { Injectable } from '@angular/core';

export interface StateMessageResponseApi {
  stateAbbreviation: string;
  stateMessage: string;
}

export const stateMessages: StateMessageResponseApi[] = [
  {
    stateAbbreviation: 'CA',
    stateMessage: 'Married applicants may apply for a separate account.',
  },
  {
    stateAbbreviation: 'WI',
    stateMessage: `A creditor shall include in every written application for an extension
     of credit that is governed by chs. 421 to 427 a notice that "No provision of a marital
     property agreement, a unilateral statement under s. 766.69 or a court decree under s. 766.70
     adversely affects the interest of the creditor unless the creditor, prior to the time
     the credit is granted, is furnished a copy of the agreement, statement or decree or has
     actual knowledge of the adverse provision when the obligation to the creditor is incurred."`,
  },
  {
    stateAbbreviation: 'OH',
    stateMessage: `The Ohio laws against discrimination require that all creditors make credit
    equally available to all credit worthy customers, and that credit reporting agencies maintain
    separate credit histories on each individual upon request. The Ohio civil rights commission administers
    compliance with this law.`,
  },
];

@Injectable()
export class StateMessageApi {
  public getStateMessage(state: string): StateMessageResponseApi {
    const message = stateMessages.find(
      (messageArray: StateMessageResponseApi) => {
        return messageArray.stateAbbreviation === state;
      }
    );
    return message ? message : null;
  }
}
