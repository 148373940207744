import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {} from 'jasmine';
import { AppModule } from './app/app.module';

fetch('environment.json')
  .then((response: Response) => response.json())
  .then((json: JSON) => {
    window['environment'] = json;

    if (window['environment'].productionMode) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err: Error) => console.error(err));
  });
