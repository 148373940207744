import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rise-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss'],
})
export class SpinnerModalComponent {
  public message: String;
  constructor(@Inject(MAT_DIALOG_DATA) private data?: any) {
    this.message = this.data;
  }
}
