import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import {
  ApplicantBankAccountModel,
  ApplicationData,
  ApplicationFlows,
  ApplicationForm,
  ApplicationStates,
  FundMethodType,
  UnderwritingDecisionEnum,
} from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import {
  TrackedRequests,
  UnderwritingStepModel,
  UnderwritingSteps,
  UnderwritingStepStatus,
} from '@app/acquisition/application/underwriting.model';
import { ResumeUnderwritingLogicService } from './resume-underwriting-logic.service';

export interface NextPath {
  url: String;
  navigationExtras?: NavigationExtras;
}

@Injectable({
  providedIn: 'root',
})
export class ResumeApplicationLogicService {
  constructor(
    private applicationDataService: ApplicationDataService,
    private resumeUnderwritingLogicService: ResumeUnderwritingLogicService
  ) {}

  public getNextPath(applicationData: ApplicationData): NextPath {
    if (applicationData.state === ApplicationStates.Booked) {
      return { url: 'accept/finalize-loan' };
    }

    if (applicationData.state === ApplicationStates.Accepted) {
      return { url: '/accept/booking-loan' };
    }

    const applicationSubmitStep = applicationData.underwriting.steps.filter(
      (step: UnderwritingStepModel) => {
        return step.name === UnderwritingSteps.ApplicationSubmit;
      }
    )[0] as UnderwritingStepModel;

    const prequalSubmitStep = applicationData.underwriting.steps.filter(
      (step: UnderwritingStepModel) => {
        return step.name === UnderwritingSteps.Prequal;
      }
    )[0] as UnderwritingStepModel;

    const appSubmitStatus = applicationData.trackedRequests.find(
      (request: TrackedRequests) => request.name === '199'
    );

    const income = applicationData.form.applicant.income;

    if (applicationSubmitStep.status) {
      if (
        applicationSubmitStep.status !== UnderwritingStepStatus.Complete &&
        income &&
        applicationData.submitDate
      ) {
        return { url: '/apply/verify-income' };
      }
      if (
        applicationSubmitStep.status !== UnderwritingStepStatus.NotStarted &&
        income &&
        income.sources[0].confirmedPayDates
      ) {
        const emailStatus = applicationData.form.applicant.emails.find(
          (email: any) => email.type === 'Personal'
        ).status;

        if (
          (emailStatus && emailStatus === 'Verified') ||
          applicationData.state === ApplicationStates.Declined
        ) {
          return this.resumeUnderwritingLogicService.getUnderWritingDecision(
            applicationData,
            appSubmitStatus
          );
        } else {
          return { url: '/apply/email-confirmation' };
        }
      }
    }

    if (income) {
      if (income.sources) {
        return { url: '/apply/verify-income' };
      }
    }

    if (
      !!applicationData.form.fundMethod &&
      !!applicationData.form.paymentMethod
    ) {
      return { url: '/apply/income' };
    }

    if (this.isValidBankInfo(applicationData.form)) {
      return { url: '/apply/fund-payment-selection' };
    }

    if (applicationData.applicationFlow === ApplicationFlows.PartnerPrequal) {
      return { url: '/apply/connect-bank' };
    }

    if (
      prequalSubmitStep.disposition.type === UnderwritingDecisionEnum.Decline
    ) {
      return { url: '/apply/pre-qualification-decline' };
    }

    if (
      prequalSubmitStep.disposition.type === UnderwritingDecisionEnum.Offer ||
      prequalSubmitStep.disposition.type ===
        UnderwritingDecisionEnum.CounterOffer ||
      prequalSubmitStep.disposition.type === UnderwritingDecisionEnum.Continue
    ) {
      return { url: '/apply/connect-bank' };
    }

    if (
      prequalSubmitStep.disposition.reason ===
      UnderwritingDecisionEnum.LenderChange
    ) {
      return { url: '/apply/connect-bank' };
    }

    if (
      prequalSubmitStep.disposition.type ===
        UnderwritingDecisionEnum.MoreInfo ||
      prequalSubmitStep.disposition.type === UnderwritingDecisionEnum.NoOffer
    ) {
      return { url: '/apply/connect-bank-more-info' };
    }

    return { url: '/apply/getting-started' };
  }

  private isValidBankInfo(formData: ApplicationForm): boolean {
    try {
      const draftFromKey = formData.applicant.bank.draftFromKey;
      const selectedAccount = formData.applicant.bank.accounts.filter(
        (account: ApplicantBankAccountModel) => {
          return account.key === draftFromKey;
        }
      )[0];
      if (selectedAccount.type === 'Checking') {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
}
