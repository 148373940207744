import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GpcInfo } from '../models/gpc-info.model';

@Injectable({
  providedIn: 'root',
})
export class EclGpcService {
  constructor(private http: HttpClient) {}

  public postGpcInfo(postGpcInfo: GpcInfo): Observable<any> {
    if (
      postGpcInfo.config.postUrl &&
      (postGpcInfo.customerId || postGpcInfo.clientId)
    ) {
      return this.http.post(postGpcInfo.config.postUrl, postGpcInfo, {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          'ocp-apim-subscription-key':
            postGpcInfo.config.apiManagerSubscriptionKey,
          version: postGpcInfo.config.apiVersion,
        }),
        observe: 'response',
      });
    } else {
      return of({
        error: 'Invalid or missing parameter',
      });
    }
  }
}
