<div mat-dialog-title class="dialog-header" id="accountNotPermitedModalHeader">
  <p>Account type not permitted!</p>
</div>
<mat-dialog-content class="mat-typography" id="accountNotPermittedModalBody">
  <div [formGroup]="form">
    <p class="not-permitted-text" id="accountNotPermittedMessage">
      In a previous step you selected an account that is not a checking account.
      You need to have a checking account in good standing to borrow from
      <rise-brand></rise-brand>.
    </p>
    <div *ngIf="checkingAccountsExist">
      <p id="selectRadioMessage">
        Please choose a checking account from the list below:
      </p>
      <div class="radio-buttons-container">
        <mat-radio-group
          formControlName="account"
          id="accountNotPermittedRadioGroup"
        >
          <mat-radio-button
            *ngFor="let account of accounts; let i = index"
            [value]="account.key"
            [id]="'accountOption' + i"
          >
            Checking Account - XXXXXXX{{ account.accountNumberLast4 }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-error>
          <rise-validation-messages
            controlName="account"
          ></rise-validation-messages>
        </mat-error>
      </div>
    </div>
    <button
      *ngIf="checkingAccountsExist"
      mat-flat-button
      [riseSubmitToggle]="form"
      id="accountNotPermittedContinueButton"
      type="submit"
      class="rise-submit-button"
      (click)="saveInformation()"
    >
      Select and Continue
    </button>
    <p
      class="not-permitted-text"
      *ngIf="!checkingAccountsExist"
      id="accountNotPermitedReturnMessage"
    >
      Click Return to select a different bank.
    </p>
    <button
      *ngIf="!checkingAccountsExist"
      mat-flat-button
      id="accountNotPermittedReturnButton"
      class="rise-submit-button"
      (click)="closeModal()"
    >
      Return
    </button>
  </div>
</mat-dialog-content>
