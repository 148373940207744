import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AccountSetupComponent } from '@app/acquisition/accept/account-setup/account-setup.component';
import { DisclosuresComponent } from '@app/acquisition/accept/disclosures/disclosures.component';
import { ChooseYourTermsInstallmentComponent } from '@app/acquisition/accept/underwriting-decisons/choose-your-terms/installment/choose-your-terms-installment.component';
import { ApplicationFlows } from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { UnderwritingService } from '@app/acquisition/apply/services/underwriting.service';
import { MaintenanceService } from '@app/acquisition/maintenance/services/maintenance.service';
import { SessionStorageService } from '@app/acquisition/services/session-storage-service/session-storage.service';
import { Environment } from '@environment/environment.module';
import { Observable, Subscription } from 'rxjs';
import { BookingLoanComponent } from '../../accept/booking-loan/booking-loan.component';
import { CheckingOptionsComponent } from '../../apply/checking-options/checking-options.component';
import { GettingStartedComponent } from '../../apply/getting-started/getting-started.component';
import { PendingUnderwritingPageComponent } from '../../apply/pending-underwriting-page/pending-underwriting-page.component';
import { BrowserNavigationModalService } from './browser-navigation-modal.service';
import { CookieKeys } from '@cookie/cookie-keys';
import { CookieService } from '@cookie/cookie-service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class NavigationRestrictionGuard
  implements CanDeactivate<CanComponentDeactivate> {
  public cytInstallmentUrl = 'accept/cyt-installment';
  public formerVerifyDetailsUrl = 'former/verify-details';
  public gettingStartedUrl = 'apply/getting-started';
  public maintenanceTitle = 'Maintenance';
  public refiVerifyDetailsUrl = 'refinance/verify-details';

  constructor(
    private router: Router,
    private location: Location,
    private titleService: Title,
    private maintenance: MaintenanceService,
    private environment: Environment,
    private browserNavigationModalService: BrowserNavigationModalService,
    private dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private underwritingService: UnderwritingService,
    private appDataService: ApplicationDataService,
    private cookieService: CookieService
  ) {}

  public canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const isPopState =
      this.router.getCurrentNavigation().trigger === 'popstate';

    if (!isPopState) {
      return true;
    }

    const notShowPopUpComponents =
      component instanceof GettingStartedComponent ||
      component instanceof CheckingOptionsComponent ||
      component instanceof PendingUnderwritingPageComponent ||
      component instanceof BookingLoanComponent;

    const navigateToCYTInstallmentsPage =
      component instanceof AccountSetupComponent ||
      component instanceof DisclosuresComponent;

    if (currentState?.url?.includes(this.formerVerifyDetailsUrl)) {
      window.location.assign(
        `${this.environment.url.wiq}/apps/account-overview`
      );
    }

    if (this.maintenance.checkMaintenance()) {
      this.titleService.setTitle(this.maintenanceTitle);
      return false;
    }

    if (notShowPopUpComponents && isPopState) {
      this.titleService.setTitle('');
      this.location.go(currentState.url);
      this.titleService.setTitle(currentRoute.data.title);
      return false;
    }

    if (navigateToCYTInstallmentsPage && isPopState) {
      this.router.navigate(['../accept/cyt-installment']);
      return false;
    }

    this.titleService.setTitle('');
    this.location.go(currentState.url);
    this.titleService.setTitle(currentRoute.data.title);
    const isFormerFlow = currentRoute.data.flow === ApplicationFlows.Former;
    const isRefiFlow =
      currentRoute.data.flow === ApplicationFlows.Refi ||
      (component instanceof ChooseYourTermsInstallmentComponent &&
        (component as ChooseYourTermsInstallmentComponent)
          .isRefinanceApplication);

    let backRoute: string;
    if (isFormerFlow) {
      backRoute = '../former/verify-details';
    } else if (isRefiFlow) {
      backRoute =
        component instanceof ChooseYourTermsInstallmentComponent
          ? `${this.environment.url.wiq}/apps/refinance`
          : '../refinance/verify-details';
    } else {
      backRoute = '../apply/getting-started';
    }

    this.showBrowserNavigationModal(backRoute);
    return false;
  }

  private showBrowserNavigationModal(backRoute: string): void {
    this.browserNavigationModalService
      .showModal()
      .subscribe((response: boolean) => {
        if (response) {
          this.dialog.closeAll();
          this.sessionStorageService.clearAllSessionStorage();
          this.cookieService.remove(CookieKeys.jwtToken);
          this.cookieService.remove(CookieKeys.riseToken);
          this.underwritingService.resetDecision();
          this.appDataService.resetApplication();
          if (backRoute.startsWith('http')) {
            window.location.assign(backRoute);
          } else {
            this.router.navigate([backRoute]);
          }
        }
      });
  }
}
