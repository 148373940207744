
<h1 id="nlsLoginTitle" mat-dialog-title>Hey there, welcome back!</h1>
<div id="nlsLoginContent" mat-dialog-content>
  <p>
    Looks like you already have an account with us.
    Click below to sign in and continue.
  </p>
</div>
<div mat-dialog-actions>
  <button id="nlsLoginBtn" mat-button (click)="navigateToSignIn()">Sign In & Continue</button>
</div>