<div id="sidebarWrapper">
  <div id="sideBarPlacement">
    <div id="sidebarContentsDiv">
      <div class="sidebar-content-block">
        <img
          id="greenLockImage"
          class="sidebar-image"
          src="assets/images/green_lock.png"
          alt=""
        />
        <div class="sidebar-content-title">Our commitment to security</div>
        <div class="sidebar-content-body" id="securityBodyText">
          We use 128-bit TLS encryption and perform continuous monitoring of our
          systems to ensure that your information is safe.
        </div>
      </div>

      <div class="sidebar-content-block">
        <img
          id="greenQuestionMarkImage"
          class="sidebar-image"
          src="assets/images/green_question_mark.png"
          alt=""
        />
        <div class="sidebar-content-title">Need assistance?</div>
        <div *ngIf="enableCoBrowse" class="cobrowse-with-icon">
          <div
            name="cobrowse-button"
            class="cobrowse-button"
            (click)="openCobrowseDialog()"
          >
            <img
              alt="cobrowse-icon"
              class="cobrowse-icon"
              src="/assets/images/cobrowse-icon.png"
            />
            <div class="cobrowse-text">Co-Browse</div>
          </div>
        </div>
        <div class="sidebar-content-body">Call us at (833) 684-0466</div>
        <div class="sidebar-content-body" id="supportTimes">
          <p><span class="bold">Mon - Fri:</span> 8am - 11pm ET</p>
          <p><span class="bold">Sat:</span> 9am - 6pm ET</p>
          <p><span class="bold">Sun:</span> 9am - 6pm ET</p>
        </div>
      </div>
    </div>
  </div>
</div>
