import { Component, Input, OnDestroy } from '@angular/core';
import { LoanPaymentDataService } from '@app/acquisition/accept/services/loan-payment-data.service';

@Component({
  selector: 'rise-refi-loan-summary',
  templateUrl: './refi-loan-summary.component.html',
  styleUrls: ['../choose-your-terms-installment.component.scss'],
})
export class RefiLoanSummaryComponent {
  @Input() public currentPayoff: number;

  @Input() public depositAmount: number;

  @Input() public loanAmount: number;
}
