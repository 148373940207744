import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StateMessageResponseApi } from '../state-message-api/state-message-api';

@Component({
  selector: 'rise-state-message-modal',
  templateUrl: './state-message-modal.component.html',
})
export class StateMessageModalComponent implements OnInit {
  public message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public messageInformation: StateMessageResponseApi,
    public dialogRef: MatDialogRef<StateMessageModalComponent>
  ) {}

  public ngOnInit(): void {
    this.message = this.messageInformation.stateMessage;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
