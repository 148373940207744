import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredCheckboxValidator } from '@app/validation/validators/required-checkbox.validator';
import { DisclosureFormService } from '../../services/disclosure-form.service';

export const checkboxAgreementTagName = 'rise-checkbox-agreement';

@Component({
  selector: checkboxAgreementTagName,
  templateUrl: './checkbox-agreement.component.html',
  styleUrls: ['./checkbox-agreement.component.scss'],
})
export class CheckboxAgreementComponent implements AfterViewInit {
  public fieldName: string;
  public fieldLabel: string;
  public showForm = false;
  private formControl: FormControl;

  constructor(
    private el: ElementRef,
    private disclosureFormService: DisclosureFormService
  ) {
    this.fieldName = this.el.nativeElement.getAttribute('fieldName');
    this.fieldLabel = this.el.nativeElement.getAttribute('fieldLabel');
    this.formControl = new FormControl(null, [
      requiredCheckboxValidator('Required'),
    ]);
  }

  public ngAfterViewInit(): void {
    this.disclosureFormService.addFormControl(this.fieldName, this.formControl);
    this.showForm = true;
  }

  public get form(): FormGroup {
    return this.disclosureFormService.form;
  }
}
