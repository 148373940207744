import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { BrowserNavigationRestrictionModalComponent } from './browawe-navigation-restriction-modal/browser-navigation-restriction.modal.component';

@Injectable()
export class BrowserNavigationModalService {
  constructor(private dialog: MatDialog) {}

  public showModal(): Observable<boolean> {
    return this.dialog
      .open(BrowserNavigationRestrictionModalComponent, {
        panelClass: 'rise-modal-small',
        disableClose: true,
        autoFocus: false,
      })
      .afterClosed();
  }
}
