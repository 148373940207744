import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicationApi } from '@app/acquisition/application/application.api';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { PlaidCheckingAccounts } from '@app/acquisition/apply/connect-bank/bank-connection-api/bank-connection-api.service';
import { Observable, Subject } from 'rxjs';
import { AccountNotPermittedModalComponent } from '../account-not-permitted-modal/account-not-permitted-modal.component';
import { BankConnectionErrorModalComponent } from '../bank-connection-error-modal/bank-connection-error-modal.component';
import { MultipleBankAccountModalComponent } from '../multiple-bank-account-modal/multiple-bank-account-modal.component';
import { SpinnerModalComponent } from '../spinner-modal/spinner-modal.component';

@Injectable()
export class BankConnectionErrorService {
  private multipleAccountDialog: MatDialogRef<AccountNotPermittedModalComponent>;
  private spinnerDialog: MatDialogRef<SpinnerModalComponent>;

  constructor(
    private dialog: MatDialog,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService
  ) {}

  public handleError(httpError: HttpErrorResponse): void {
    this.dialog.closeAll();
    if (
      httpError.status === 400 &&
      httpError.error['message'] === 'Invalid bank information.'
    ) {
      this.dialog.open(MultipleBankAccountModalComponent, {
        panelClass: 'rise-modal-small',
        disableClose: true,
      });
    } else {
      this.dialog.open(BankConnectionErrorModalComponent, {
        panelClass: 'rise-modal-small',
        disableClose: true,
      });
    }
  }

  public handleMultipleAccounts(
    accounts: PlaidCheckingAccounts[]
  ): Observable<boolean> {
    const nextSubject = new Subject<boolean>();
    this.multipleAccountDialog = this.dialog.open(
      AccountNotPermittedModalComponent,
      {
        panelClass: 'rise-modal-medium',
        autoFocus: false,
        disableClose: true,
        closeOnNavigation: false,
        data: accounts,
      }
    );

    this.multipleAccountDialog
      .afterClosed()
      .subscribe((selectedKey: string) => {
        if (selectedKey) {
          this.spinnerDialog = this.dialog.open(SpinnerModalComponent, {
            panelClass: 'rise-saving-modal',
            disableClose: true,
            closeOnNavigation: false,
          });
          const bankApplicationData = {
            applicant: {
              bank: {
                draftFromKey: selectedKey,
              },
            },
          };
          this.applicationApi.append(bankApplicationData).subscribe(
            (response: HttpResponse<any>) => {
              if (response.status === 204) {
                this.spinnerDialog.close();
                this.applicationDataService.merge({
                  form: bankApplicationData,
                });
                this.multipleAccountDialog.close();
                nextSubject.next(true);
              }
            },
            (error: any) => {
              const httpError = error as HttpErrorResponse;
              if (
                httpError.status === 400 &&
                httpError.error['message'] === 'Invalid bank information.'
              ) {
                this.spinnerDialog.close();
                this.dialog.open(MultipleBankAccountModalComponent, {
                  panelClass: 'rise-modal-small',
                  disableClose: true,
                });
                nextSubject.next(false);
              }
            }
          );
        } else {
          nextSubject.next(false);
        }
      });

    return nextSubject;
  }
}
