import { Injectable } from '@angular/core';
import * as Cookies from 'js-cookie';

@Injectable()
export class CookieService {
  public set(
    key: string,
    value: string,
    options: Cookies.CookieAttributes
  ): void {
    options.secure = options.domain !== 'localhost';

    if (options.domain === 'localhost') {
      delete options.domain;
    }

    Cookies.set(key, value, options);
  }

  public get(key: string): string {
    return Cookies.get(key);
  }

  public remove(key: string): void {
    Cookies.remove(key);
  }
}
