import { Injectable } from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { CookieKeys } from '@cookie/cookie-keys';
import { CookieService } from '@cookie/cookie-service';
import { Environment } from '@environment/environment.module';
import { GpcInfo } from '../models/gpc-info.model';
import { EclGpcService } from './ecl-gpc.service';

@Injectable({
  providedIn: 'root',
})

// should be called for every page
export class GpcService {
  constructor(
    private environment: Environment,
    private eclGpcService: EclGpcService,
    private cookieService: CookieService,
    private applicationDataService: ApplicationDataService
  ) {}
  public PostGpc() {
    const gpcInfo: GpcInfo = {
      brandId: this.environment.productId.toString(),
      customerId: this.getCustomerId(),
      clientId: this.getClientId(),
      gpcStatus: (navigator as any).globalPrivacyControl === true,
      sessionId: this.getSessionId(),
      config: {
        apiManagerSubscriptionKey:
          this.environment.gpc.apiManagerSubscriptionKey,
        apiVersion: this.environment.gpc.apiVersion,
        environment: this.environment.gpc.environment,
        postUrl: this.environment.gpc.postUrl,
      },
    };
    this.eclGpcService.postGpcInfo(gpcInfo).subscribe();
  }

  private getCustomerId() {
    const applicationData = this.applicationDataService.getApplication();
    return applicationData.sequenceApplicationId?.toString();
  }

  private getSessionId() {
    const sessionData = this.cookieService.get(CookieKeys.gpcSessionId);
    return sessionData ? sessionData.split('.')[2] : '';
  }

  private getClientId() {
    const clientId = this.cookieService.get(CookieKeys.ga);
    return clientId;
  }
}
