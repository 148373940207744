import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IPThresholdModalComponent } from '../../acquisition/shared-module/ip-threshold-modal/ip-threshold-modal.component';
@Injectable({
  providedIn: 'root',
})
export class ApiErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        const httpError = error as HttpErrorResponse;
        const url = httpError.url;
        if (this.isBrandApi(url)) {
          if (httpError.error.message.includes('is not Serviced')) {
            return throwError(error);
          } else {
            this.handleBrandApiError(
              this.getBrandPath(url),
              request,
              httpError
            );
          }
        }
        return throwError(error);
      })
    );
  }

  private isBrandApi(url: string): boolean {
    return url.match(/.+(brandapi|localhost:5001)(\/.+)/)?.length > 0;
  }

  private getBrandPath(url: string): string {
    return url.match(/.+(brandapi|localhost:5001)(\/.+)/)[1];
  }

  private handleBrandApiError(
    path: string,
    request: HttpRequest<any>,
    httpError: HttpErrorResponse
  ): void {
    if (httpError.status === 429) {
      this.showIpThreshold();
    }
  }

  private showIpThreshold(): void {
    this.dialog.closeAll();
    this.dialog.open(IPThresholdModalComponent, {
      panelClass: 'rise-modal-small',
      disableClose: true,
    });
  }
}
