import { Injectable } from '@angular/core';
import { mergeObjectInArrayByKey } from '@app/deep-merge/merge-object-in-array-by-key';
import * as deepMerge from 'deepmerge';
import { ApplicationData, ApplicationForm } from './application.models';

@Injectable({
  providedIn: 'root',
})
export class ApplicationDataService {
  private application: ApplicationData = {};

  public merge(
    application: ApplicationData,
    preserveSSN: boolean = true
  ): void {
    let originalSSN: string;
    if (Object.keys(this.application).length) {
      originalSSN =
        this.application.form.applicant.identity.socialSecurityNumber;
    }
    this.application = deepMerge(this.application, application, {
      customMerge: mergeObjectInArrayByKey,
    });
    if (originalSSN && preserveSSN) {
      this.application.form.applicant.identity.socialSecurityNumber =
        originalSSN;
    }
  }

  public mergeArray(application: ApplicationData): void {
    this.application = deepMerge(this.application, application);
  }

  public replaceFormApplicant(applicationData: ApplicationData): void {
    this.application.form.applicant = { ...applicationData.form.applicant };
  }

  public replaceApplicationForm(applicationData: ApplicationData): void {
    this.application.form = { ...applicationData.form };
  }

  public replaceApplication(applicationData: ApplicationData): void {
    this.application = { ...applicationData };
  }

  public getApplication(): ApplicationData {
    return this.application;
  }

  public get(): ApplicationForm {
    return this.application.form;
  }

  public get stateCode(): string {
    return this.getApplication().form.applicant.residences[0].address.stateCode;
  }

  public get lenderCode(): string {
    return this.application.product?.lenderCode;
  }

  public resetApplication(): void {
    this.application = {};
  }
}
