import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentOptionItem } from '@app/acquisition/accept/models/payment-option-item.interface';
import { LoanPaymentDataService } from '@app/acquisition/accept/services/loan-payment-data.service';
import { SpinnerModalComponent } from '@app/acquisition/shared-module/spinner-modal/spinner-modal.component';
import { Subscription } from 'rxjs';
import { PaymentOptionsModalComponent } from '../payment-options-modal/payment-options-modal.component';

@Component({
  selector: 'rise-payment-options-card',
  templateUrl: './payment-options-card.component.html',
  styleUrls: ['../choose-your-terms-installment.component.scss'],
})
export class PaymentOptionsCardComponent implements OnDestroy {
  @Input() public frequency: string;
  @Input() public isloanOriginationFeeApplicable: boolean;
  public numPayments: number;
  public paymentAmount: number;

  private spinnerDialogRef: MatDialogRef<SpinnerModalComponent>;
  private optionsLoading: boolean;
  private waitingOnLoading = false;
  private isLoadingSubscription: Subscription;
  private paymentScheduleSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private loanPaymentDataService: LoanPaymentDataService
  ) {
    this.isLoadingSubscription = this.loanPaymentDataService
      .getIsLoadingSubject()
      .subscribe((isLoading: boolean) => {
        this.optionsLoading = isLoading;
        if (this.waitingOnLoading) {
          this.openPaymentOptionsModal();
          this.spinnerDialogRef.close();
          this.waitingOnLoading = false;
        }
      });
    this.paymentScheduleSubscription = this.loanPaymentDataService
      .getPaymentScheduleSubject()
      .subscribe((schedule: PaymentOptionItem) => {
        this.numPayments = schedule.paymentSchedule.length;
        this.paymentAmount =
          schedule.paymentSchedule.length > 0
            ? schedule.paymentSchedule[0].totalPayment
            : 0;
      });
  }

  public changePaymentOptionsClick(): void {
    if (this.optionsLoading) {
      this.waitingOnLoading = true;
      this.spinnerDialogRef = this.dialog.open(SpinnerModalComponent, {
        panelClass: 'rise-saving-modal',
        disableClose: true,
        closeOnNavigation: false,
      });
    } else {
      this.openPaymentOptionsModal();
    }
  }

  public openPaymentOptionsModal(): void {
    this.dialog.open(PaymentOptionsModalComponent, {
      panelClass: 'rise-modal-small',
      autoFocus: false,
    });
  }

  public ngOnDestroy(): void {
    this.isLoadingSubscription.unsubscribe();
    this.paymentScheduleSubscription.unsubscribe();
  }
}
