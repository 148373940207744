import { ValidationErrors } from '@angular/forms';
import { Rule } from '../validation.configuration';

export class ValidationError {
  constructor(public name: string, public rules: Rule[], public text: string) {}
}

export class ValidationMessagesError implements ValidationErrors {
  constructor(validatorName: string, rules: Rule[], text: string) {
    this[validatorName] = new ValidationError(validatorName, rules, text);
  }
}
