<div [formGroup]="form">
  <mat-form-field
    color="accent"
    class="rise-form-field disclosure-form-spacing"
  >
    <input
      matInput
      maxlength="50"
      id="spouseFirstNameInput"
      formControlName="spouseFirstName"
    />

    <mat-label id="spouseFirstNameLabel">Spouse's first name</mat-label>

    <mat-error>
      <rise-validation-messages
        controlName="spouseFirstName"
      ></rise-validation-messages>
    </mat-error>
  </mat-form-field>

  <mat-form-field
    color="accent"
    class="rise-form-field disclosure-form-spacing"
  >
    <input
      matInput
      maxlength="50"
      id="spouseLastNameInput"
      formControlName="spouseLastName"
    />

    <mat-label id="spouseLastNameLabel">Spouse's last name</mat-label>

    <mat-error>
      <rise-validation-messages
        controlName="spouseLastName"
      ></rise-validation-messages>
    </mat-error>
  </mat-form-field>

    <mat-form-field color="accent" class="rise-form-field disclosure-form-spacing">
        <input matInput maxlength="50" autocomplete="off" id="spouseEmailInput" formControlName="spouseEmailAddress" />

    <mat-label id="spouseEmailLabel">Spouse's email address</mat-label>

        <mat-error>
            <rise-validation-messages controlName="spouseEmailAddress"></rise-validation-messages>
        </mat-error>
    </mat-form-field>

  <div class="spouse-email-check-box">
    <mat-checkbox
      (change)="checkboxChange($event.checked)"
      id="emailIsTheSameCheckBox"
    ></mat-checkbox>
    <span class="spouse-email-check-box-text"
      >Check here if your spouse's email address is the same as yours.</span
    >
  </div>
</div>
