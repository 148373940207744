import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Environment } from '@app/environment/environment.module';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface AppendDeviceRequestObject {
  iovationSignature?: string;
  queryString?: string;
  referrerUrl?: string;
  GCID?: string;
  neuroID?: string;
}

@Injectable()
export class DeviceApiService {
  constructor(
    private environment: Environment,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router
  ) {}

  public append(data: AppendDeviceRequestObject): Observable<any> {
    return this.http
      .patch<HttpResponse<any>>(
        `${this.environment.brandApi.url}/api/v1/append-device`,
        data,
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        }
      )
      .pipe(
        catchError((error: any) => {
          this.dialog.closeAll();
          this.router.navigate(['../error']);
          return throwError(error);
        })
      );
  }
}
