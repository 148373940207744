import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';
import { Rule } from '../validation.configuration';

export const phonenumberPrefixValidatorName = 'validatePrefix';

export function PhonenumberPrefixValidator(
  text: string = 'Invalid phone number',
  rules?: Rule[],
  needValidation: boolean = true
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors {
    const matchesFormat =
      (typeof control.value === 'number' ||
        typeof control.value === 'string') &&
      control.value.toString().charAt(0) != '1' &&
      control.value.toString().charAt(0) != '0';
    if (!needValidation) {
      return null;
    } else if (matchesFormat) {
      return null;
    } else {
      return new ValidationMessagesError(
        phonenumberPrefixValidatorName,
        rules,
        text
      );
    }
  };
}
