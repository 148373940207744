import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentOptionItem } from '@app/acquisition/accept/models/payment-option-item.interface';
import { LoanPaymentDataService } from '@app/acquisition/accept/services/loan-payment-data.service';

interface OptionsItem {
  indexRef: number;
  numPayments: number;
  paymentAmount: number;
}

@Component({
  selector: 'rise-payment-options-component',
  templateUrl: './payment-options-modal.component.html',
  styleUrls: ['./payment-options-modal.component.scss'],
})
export class PaymentOptionsModalComponent implements OnInit {
  public form: FormGroup;
  public optionsArray: OptionsItem[];
  public originalSelection: number;

  constructor(
    private dialogRef: MatDialogRef<PaymentOptionsModalComponent>,
    private loanPaymentDataService: LoanPaymentDataService
  ) {}

  public ngOnInit(): void {
    const paymentOptionsState =
      this.loanPaymentDataService.getPaymentOptionsState();
    this.originalSelection = paymentOptionsState.currentSelection;
    this.optionsArray = paymentOptionsState.paymentOptions
      .map((item: PaymentOptionItem, index: number) => {
        return {
          indexRef: index,
          numPayments: item.paymentSchedule.length,
          paymentAmount: item.paymentSchedule[0].totalPayment,
        };
      })
      .sort((x: OptionsItem, y: OptionsItem) => {
        return x.numPayments - y.numPayments;
      });
    this.form = new FormGroup({
      selectedOption: new FormControl(this.originalSelection),
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public saveOption(): void {
    if (this.form.get('selectedOption').value !== this.originalSelection) {
      this.loanPaymentDataService.updatePaymentSchedule(
        +this.form.get('selectedOption').value
      );
    }
    this.dialogRef.close();
  }
}
