import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@app/forms/page-form-group/page-form-group';
import { requiredCheckboxValidator } from '@app/validation/validators/required-checkbox.validator';

export interface CABrokerAgreement {
  caBrokerChkbx?: boolean;
}

@Injectable()
export class CAbrokerFormGroup extends PageFormGroup {
  public value: CABrokerAgreement;

  constructor() {
    super({
      caBrokerChkbx: new FormControl(null, [
        requiredCheckboxValidator('Required.'),
      ]),
    });
  }
}
