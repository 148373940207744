import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const inputNotContainValidatorName = 'notContainEmail';

export function inputNotContainValidator(
  targetControlPath: string,
  text: string = `Input contains`,
  rules?: Rule[]
): ValidatorFn {
  let initializing = true;

  return function (control: AbstractControl): ValidationErrors {
    const controlTreeStructureNotInitialized = control === control.root;
    let resultCheck = [];

    if (controlTreeStructureNotInitialized) {
      return null;
    }

    const targetControl = control.root.get(targetControlPath);

    if (!targetControl) {
      throw new Error(
        `Input Not contain Validator -- no control found for control path ${targetControlPath}`
      );
    }

    if (initializing) {
      setValidatorToTargetControl(control, targetControl);
      initializing = false;
    }

    if (control.value) {
      const fullEmailAdress =
        targetControl.value != null ? targetControl.value.toString() : '';
      const emailStrings = fullEmailAdress.split('@');

      const stringsArray = emailStrings[0]?.replace(/[#._-]/g, '|').split('|');
      const emailDomain = emailStrings[1]?.split('.')[0];
      if (emailDomain != undefined) {
        stringsArray.push(emailDomain);
      }

      const wordsWhichLenghtIsGreaterThan4 = stringsArray.filter(
        (word: string) => word.length > 3
      );

      resultCheck = wordsWhichLenghtIsGreaterThan4.filter(
        (toCheckString: string) =>
          control.value
            .toString()
            .toLowerCase()
            .includes(toCheckString.toLowerCase())
      );
    }

    if (resultCheck.length > 0) {
      return new ValidationMessagesError(
        inputNotContainValidatorName,
        rules,
        text
      );
    } else {
      return null;
    }
  };

  function setValidatorToTargetControl(
    control: AbstractControl,
    target: AbstractControl
  ): void {
    const validator = target.validator
      ? [target.validator, targetValidator.bind(null, control)]
      : targetValidator.bind(null, control);

    target.setValidators(validator);
  }

  function targetValidator(control: AbstractControl): ValidationErrors {
    control.updateValueAndValidity();
    return null;
  }
}
