import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rise-email-only-match-modal',
  templateUrl: './email-only-match-modal.component.html',
  styleUrls: ['./email-only-match-modal.component.scss'],
})
export class EmailOnlyMatchModalComponent {
  constructor(private dialogRef: MatDialogRef<EmailOnlyMatchModalComponent>) {}

  public closeModal(): void {
    this.dialogRef.close();
  }
}
