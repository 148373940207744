<div class="dialog-header">
  <img
    id="closeImage"
    class="close-img"
    src="assets/images/button_close.svg"
    (click)="closeModal()"
  />
</div>
<div *ngIf="invalidAddressInfo || invalidMobileNoInfo">
  <div mat-dialog-title class="dialog-header" id="invalidAddressTitle">
    <p>Attention Needed</p>
  </div>
  <mat-dialog-content class="mat-typography" id="invalidAddressContent">
    <p *ngIf="invalidAddressInfo" id="invalidAddressMessage">
      We are unable to accept PO Boxes. Please click the Edit link to update
      your address to a physical address.
    </p>
    <p *ngIf="invalidMobileNoInfo" id="invalidMobileNoMessage">
      Mobile phone number is mandatory. Please click the Edit link to update
      your mobile phone number.
    </p>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button
      class="rise-submit-button"
      mat-flat-button
      id="invalidAddressBtn"
      mat-button
      (click)="closeModal()"
    >
      OK
    </button>
  </div>
</div>
<div *ngIf="!invalidAddressInfo && !invalidMobileNoInfo">
  <div mat-dialog-title class="dialog-header" id="contactInfoTitle">
    <p>Your Contact Information</p>
  </div>
  <mat-dialog-content class="mat-typography" id="contactInfoContent">
    <div id="accountSettingsLinkMessage">
      To modify your Contact Information please,
      <span id="accountSettingsLink" (click)="navigateToAccountSettings()"
        >click here</span
      >
      to return to your My Account Settings.
    </div>
  </mat-dialog-content>
</div>
