import { Injectable } from '@angular/core';
import { AppInsightService } from '@app/acquisition/services/app-insights/app-insights.service';
import {
  SessionStorageObjects,
  SessionStorageService,
} from '@app/acquisition/services/session-storage-service/session-storage.service';
import { LocationProxy } from '@app/location-proxy/location-proxy.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as deepMerge from 'deepmerge';

export enum TealiumEvent {
  Click = 'click',
  View = 'view',
  Checkbox = 'check-box',
  Popup = 'pop-up',
  FormField = 'form-field',
  Notification = 'notification',
  API = 'api',
  PageSubmission = 'page-submission',
  Initialization = 'initialization',
  Rendering = 'rendering',
  TrackedData = 'tracked-data',
  SequenceIdCreation = 'sequence-id-creation',
  PlaidEvent = 'plaid-event',
}

export enum GoogleTagEvent {
  Click = 'click',
  View = 'view',
  Checkbox = 'check-box',
  Popup = 'pop-up',
  FormField = 'form-field',
  Notification = 'notification',
  API = 'api',
  PageSubmission = 'page-submission',
  Initialization = 'initialization',
  Rendering = 'rendering',
  TrackedData = 'tracked-data',
  SequenceIdCreation = 'sequence-id-creation',
  PlaidEvent = 'plaid-event',
}

export interface TealiumVariables {
  page_name?: string;
  page_type?: string;
  version?: string;
  full_referral_url?: string;
  visitorid?: string;
  gcid?: string;
  devicetype?: string;
  browser?: string;
  logrocket_id?: string;
  customer_email?: string;
  first_name?: string;
  last_name?: string;
  enrollment_code?: string;
  product_code?: string;
  loan_requested_amount?: number;
  pre_qual_amount?: number;
  counter_offer_amount?: number;
  state?: string;
  channel?: string;
  dm_ec_type?: string;
  sequence_id?: number;
  resumed_app?: boolean;
  application_start_date?: string;
  application_complete_date?: string;
  application_id?: string;
  approved_amount?: number;
  loan_id?: string;
  loan_amount?: number;
  loan_d?: string;
  loan_first_payment_date?: string;
  loan_apr?: number;
  loan_type?: string;
  loan_date?: string;
  neuroId?: string;
  login_customer_flag?: string;
  login_customer_email?: string;
  sub_product_code?: string;
  app_number?: number;
  application_completion_date?: string;
  guid?: string;
  'dom.url'?: string;
  'dom.pathname'?: string;
  pageurl_querystring?: string;
}

export interface GoogleTagManagerVariables {
  page_name?: string;
  page_type?: string;
  version?: string;
  full_referral_url?: string;
  visitorid?: string;
  gcid?: string;
  devicetype?: string;
  browser?: string;
  logrocket_id?: string;
  customer_email?: string;
  first_name?: string;
  last_name?: string;
  enrollment_code?: string;
  product_code?: string;
  loan_requested_amount?: number;
  pre_qual_amount?: number;
  counter_offer_amount?: number;
  state?: string;
  channel?: string;
  dm_ec_type?: string;
  sequence_id?: number;
  resumed_app?: boolean;
  application_start_date?: string;
  application_complete_date?: string;
  application_id?: string;
  approved_amount?: number;
  loan_id?: string;
  loan_amount?: number;
  loan_d?: string;
  loan_first_payment_date?: string;
  loan_apr?: number;
  loan_type?: string;
  loan_date?: string;
  neuroId?: string;
  login_customer_flag?: string;
  login_customer_email?: string;
  sub_product_code?: string;
  app_number?: number;
  application_completion_date?: string;
  guid?: string;
  'dom.url'?: string;
  'dom.pathname'?: string;
  pageurl_querystring?: string;
}

export interface TealiumData {
  [property: string]: number | string | string[];
  event_category?: string;
  event_label?: string;
  event_action?: string;
  event_type?: string;
}
export interface GoogleTagManagerData {
  [property: string]: number | string | string[];
  event_category?: string;
  event_label?: string;
  event_action?: string;
  event_type?: string;
}

export interface UTag {
  data: {
    [property: string]: number | string;
  };
  view(data?: TealiumData, callback?: Function, uidArray?: string[]): void;
  link(data?: TealiumData, callback?: Function, uidArray?: string[]): void;
}

@Injectable()
export class Tealium {

  constructor(
    private locationProxy: LocationProxy,
    private sessionStorageService: SessionStorageService,
    private gtmService: GoogleTagManagerService,
    private appInsightsService: AppInsightService
  ) {}

  public gtmPushData(data: GoogleTagManagerData) {
    // Get the Google Tag manager variables that set via the calls from multiple pages

    const storageTealiumVariables =
      this.sessionStorageService.getSessionStorageObject(
        SessionStorageObjects.tealiumVariables
      );

    // Create a copy without PII data to be sent to GTM (Adding this based on Nimish's suggestion)
    // Gtm is more restrictive on PII
    const gTagVariableCopy =
      storageTealiumVariables as GoogleTagManagerVariables;
      
    try {
      delete gTagVariableCopy.first_name;
      delete gTagVariableCopy.last_name;
      delete gTagVariableCopy.customer_email;
      delete gTagVariableCopy.login_customer_email;
    } catch (error) {
      this.appInsightsService.trackException(error);
    }

    // merge the variable data to the data coming from each separate track call

    Object.assign(data, gTagVariableCopy);

    data.event_label = this.locationProxy.href;
    data.event = 'rise_gtm_event';

    // Setting some constants like these were set in Tealium, these could change if

    data.version = 'EOS';
    data.page_type = 'application';

    // This product_code looks like Tealium specific variable, we can remove if it is not needed later, but for now making apples to apples
    data.product_code = '2371';
    data.browse = navigator.userAgent;
    // loan_type ideally should change but right now in rise it is set as constant , when rise started we only supported new loans
    data.loan_type = 'new';

    // this will push data to the dataLayer variable
    this.gtmService.pushTag(data);
  }

  public trackPageSuccessEvent(category: string): void {
    const successEventGtag: GoogleTagManagerData = {
      new_var: 'hello',
      event_action: GoogleTagEvent.Click,
      event_category: category,
      event_name: category,
    };

    this.gtmPushData(successEventGtag);
    this.gtmPushData(this.setGpcFlagForGoogleTagManager(category));
  }

  public trackClickEventAction(category: string, eventName: string): void {
    const successEventGtag: GoogleTagManagerData = {
      new_var: 'hello',
      event_action: GoogleTagEvent.Click,
      event_category: category,
      event_name: eventName,
    };
    this.gtmPushData(successEventGtag);
  }

  public async trackClickEventActionObj(data: TealiumData): Promise<void> {
    const successEventGtag: GoogleTagManagerData = {
      new_var: 'hello',
      event_action: GoogleTagEvent.Click,
      ...data,
    };
    this.gtmPushData(successEventGtag);
  }

  public clickOptionEvent(category: string): void {
    const clickOptionEventGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.Click,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(clickOptionEventGtag);
  }

  public popUpEvent(category: string): void {
    const popUpEventGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.Popup,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(popUpEventGtag);
  }

  public notificationEvent(category: string): void {
    const notificationEventGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.Notification,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(notificationEventGtag);
  }

  public trackPageInitialization(category: string): void {
    const initializationEventGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.Initialization,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(initializationEventGtag);
  }

  public trackPageRendered(category: string): void {
    const renderedEventGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.Rendering,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(renderedEventGtag);
    this.gtmPushData(this.setGpcFlagForGoogleTagManager(category));
  }

  public trackDataLoaded(category: string): void {
    const renderedEventGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.TrackedData,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(renderedEventGtag);
  }

  public trackPlaidEvent(category: string): void {
    const renderedEventGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.PlaidEvent,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(renderedEventGtag);
  }

  public trackSequenceIdCreation(category: string): void {
    const sequenceIdCreationGtag: GoogleTagManagerData = {
      event_action: GoogleTagEvent.SequenceIdCreation,
      event_category: category,
      event_name: category,
    };
    this.gtmPushData(sequenceIdCreationGtag);
  }

  public setTealiumVariables(tealiumVariables: TealiumVariables): void {
    const storageTealiumVariables =
      this.sessionStorageService.getSessionStorageObject(
        SessionStorageObjects.tealiumVariables
      );

    const tealiumVariablesMerge = deepMerge(
      storageTealiumVariables,
      tealiumVariables
    );

    this.sessionStorageService.setSessionStorageObject(
      SessionStorageObjects.tealiumVariables,
      tealiumVariablesMerge
    );

  }

  private setGpcFlag(category: string): TealiumData {
    let isGpcFlag = false;
    if ((<any>navigator).globalPrivacyControl) {
      isGpcFlag = true;
    }

    return {
      event_category: `${category}-${
        isGpcFlag ? 'GPC-Flag-Yes' : 'GPC-Flag-No'
      }`,
      event_action: TealiumEvent.Click,
      event_type: 'link',
      event_name: category,
    } as TealiumData;
  }

  private setGpcFlagForGoogleTagManager(
    category: string
  ): GoogleTagManagerData {
    let isGpcFlag = false;
    if ((<any>navigator).globalPrivacyControl) {
      isGpcFlag = true;
    }

    return {
      event_category: `${category}-${
        isGpcFlag ? 'GPC-Flag-Yes' : 'GPC-Flag-No'
      }`,
      event_action: GoogleTagEvent.Click,
      event_type: 'link',
      event_name: category,
    } as GoogleTagManagerData;
  }
}
