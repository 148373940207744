import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Environment } from '@app/environment/environment.module';
import { LocationProxy } from '@app/location-proxy/location-proxy.service';
import { AppInsightService } from '../../../services/app-insights/app-insights.service';

export interface NlsModalApplicantData {
  email: string;
}

@Component({
  selector: 'rise-nls-modal',
  templateUrl: './nls-modal.component.html',
  styleUrls: ['./nls-modal.component.scss'],
})
export class NlsModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public applicant: NlsModalApplicantData,
    public dialogRef: MatDialogRef<NlsModalComponent>,
    private locationProxy: LocationProxy,
    private environment: Environment,
    private appInsights: AppInsightService
  ) {}

  public navigateToSignIn(): void {
    this.appInsights.trackEvent('Redirect-BAU', {
      user: this.applicant.email,
    });
    this.locationProxy.assign(`${this.environment.url.wiq}/login`);
    this.dialogRef.close();
  }
}
