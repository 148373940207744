<div mat-dialog-content id="navigationRestriction">
  <p>Are you sure?</p>
  <p>If you choose to continue, your information will not be saved.</p>
  <div class="navigation-restriction-btns-container">
    <button
      mat-flat-button
      id="browserNavRestrictionCancel"
      type="submit"
      (click)="decline()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      id="browserNavRestrictionContinue"
      type="submit"
      (click)="accept()"
    >
      Continue
    </button>
  </div>
</div>
