export enum PaymentFrequencyType {
  Weekly = 'Weekly',
  EveryTwoWeeks = 'BiWeekly',
  TwicePerMonth = 'SemiMonthly',
  Monthly = 'Monthly',
}

export interface PaymentFrequencyOption {
  value: string;
  text: string;
  monthlyFactor: number;
}

export const paymentFrequencyOptions: PaymentFrequencyOption[] = [
  {
    value: PaymentFrequencyType.Weekly,
    text: 'Weekly',
    monthlyFactor: 52 / 12,
  },
  {
    value: PaymentFrequencyType.EveryTwoWeeks,
    text: 'Every 2 weeks',
    monthlyFactor: 26 / 12,
  },
  {
    value: PaymentFrequencyType.TwicePerMonth,
    text: 'Twice a month',
    monthlyFactor: 2,
  },
  {
    value: PaymentFrequencyType.Monthly,
    text: 'Monthly',
    monthlyFactor: 1,
  },
];
