<mat-card class="loan-option-card">
  <div class="card-title">PAYMENT AMOUNT</div>
  <div class="card-amount" id="paymentCardAmountText">
    {{ paymentAmount | currency }}
  </div>
  <div class="card-subtext" id="paymentCardTermsText">
    {{ numPayments }} Payments / {{ frequency }}
  </div>

  <div id="orginationExtraText" *ngIf="isloanOriginationFeeApplicable">
    <br />
    <br />
  </div>

  <button
    class="card-button"
    (click)="changePaymentOptionsClick()"
    tealiumPopup="Change-Payment-Options-Pop-Up"
    id="paymentCardChangeButton"
  >
    CHANGE PAYMENT OPTIONS
  </button>
</mat-card>
