import { Injectable } from '@angular/core';
import { ApplicationData } from '@app/acquisition/application/application.models';
import { TrackedRequests } from '@app/acquisition/application/underwriting.model';
import { NextPath } from './resume-application-logic.service';

@Injectable({
  providedIn: 'root',
})
export class ResumeUnderwritingLogicService {
  public getUnderWritingDecision(
    application: ApplicationData,
    appSubmitStatus: TrackedRequests
  ): NextPath {
    let target: NextPath;

    if (
      appSubmitStatus &&
      appSubmitStatus.status === 'Incomplete' &&
      'connectionResourceUrl' in application.form.applicant.bank
    ) {
      target = {
        url: '/apply/pending',
        navigationExtras: {
          state: { bankConnected: true },
        },
      };
    } else {
      target = { url: '/apply/pending' };
    }

    return target;
  }
}
