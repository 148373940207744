import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule as AngularForms,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { AppInsightService } from '@app/acquisition/services/app-insights/app-insights.service';
import { BrowserNavigationModalService } from '@app/acquisition/shared-module/browser-navigation-restriction/browser-navigation-modal.service';
import { BrandModule } from '@app/brand/brand.module';
import { TealiumModule } from '@app/tealium/tealium.module';
import { Tealium } from '@app/tealium/tealium.service';
import { ValidationModule } from '@app/validation/validation.module';
import { FormsModule } from '@forms/forms.module';
import { StateLicensesModule } from '../state-license/state-license.module';
import { AccountNotPermittedModalComponent } from './account-not-permitted-modal/account-not-permitted-modal.component';
import { BankConnectionErrorModalComponent } from './bank-connection-error-modal/bank-connection-error-modal.component';
// tslint:disable-next-line: max-line-length
import { BrowserNavigationRestrictionModalComponent } from './browser-navigation-restriction/browawe-navigation-restriction-modal/browser-navigation-restriction.modal.component';
import { NavigationRestrictionGuard } from './browser-navigation-restriction/navigation-restriction-guard.service';
import { CoBrowseModalComponent } from './cobrowse-modal/cobrowse-modal.component';
import { DeclineRedirectModalComponent } from './decline-redirect-modal/decline-redirect-modal.component';
import { SubmitToggleDirective } from './directives/submit-toggle.directive';
import { DisclosureModule } from './disclosure-module/disclosure-module/disclosure.module';
import { DocumentRetrievalErrorComponent } from './document-retrieval-error/document-retrieval-error.component';
import { InactivityModalComponent } from './inactivity-module/inactivity-modal/inactivity-modal.component';
import { IPThresholdModalComponent } from './ip-threshold-modal/ip-threshold-modal.component';
import { MultipleBankAccountModalComponent } from './multiple-bank-account-modal/multiple-bank-account-modal.component';
import { BankConnectionErrorService } from './services/bank-connection-error.service';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SpinnerModalComponent } from './spinner-modal/spinner-modal.component';
import { EditContactInfoComponent } from './verify-customer-details/edit-contact-info/edit-contact-info.component';
import { VerifyCustomerDetailsComponent } from './verify-customer-details/verify-customer-details.component';

const sharedEntryComponents = [
  SpinnerModalComponent,
  InactivityModalComponent,
  IPThresholdModalComponent,
  BankConnectionErrorModalComponent,
  MultipleBankAccountModalComponent,
  BrowserNavigationRestrictionModalComponent,
  AccountNotPermittedModalComponent,
  EditContactInfoComponent,
  CoBrowseModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    DisclosureModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationModule,
    BrandModule,
    AngularForms,
    StateLicensesModule,
    MatDividerModule,
    MatCheckboxModule,
    TealiumModule.forRoot(),
  ],
  declarations: [
    SideBarComponent,
    SubmitToggleDirective,
    DocumentRetrievalErrorComponent,
    VerifyCustomerDetailsComponent,
    DeclineRedirectModalComponent,
    ...sharedEntryComponents,
  ],
  exports: [
    SideBarComponent,
    SubmitToggleDirective,
    DocumentRetrievalErrorComponent,
  ],
  providers: [
    BankConnectionErrorService,
    NavigationRestrictionGuard,
    BrowserNavigationModalService,
    Tealium,
    AppInsightService,
  ],
  entryComponents: [...sharedEntryComponents],
})
export class SharedModule {}
