import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ValidationMessagesError } from '@validation/validation-messages/validation-error';

import { Rule } from '../validation.configuration';

export const patternValidatorName = 'patternValidator';

export function patternValidator(
  allowedPattern: RegExp,
  text: string = '! The special characters you entered are invalid.',
  rules?: Rule[]
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (Validators.pattern(allowedPattern)(control)) {
      return new ValidationMessagesError(patternValidatorName, rules, text);
    } else {
      return null;
    }
  };
}
